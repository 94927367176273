import { Stack } from '@mui/material';
import { FunctionComponent, useMemo } from 'react';
import { UsageIndicator } from '../UsageIndicator';
import { BillableHoursOrder, BillableHoursType } from '../../../../@types/order';

interface TotalHoursUsageIndicatorProps {
  activeOrders: BillableHoursOrder[];
  isLoading: boolean;
}
export const TotalHoursUsageIndicator: FunctionComponent<TotalHoursUsageIndicatorProps> = ({
  activeOrders,
  isLoading
}) => {
  const supportOrders = useMemo(() => {
    return activeOrders.filter((o) => o.billableHoursType === BillableHoursType.Support);
  }, [activeOrders]);

  const onboardingOrders = useMemo(() => {
    return activeOrders.filter((o) => o.billableHoursType === BillableHoursType.Onboarding);
  }, [activeOrders]);

  const projectOrders = useMemo(() => {
    return activeOrders.filter((o) => o.billableHoursType === BillableHoursType.Project);
  }, [activeOrders]);

  const totalSupportUsage = useMemo(
    () =>
      supportOrders.reduce((a, b) => {
        const orderB = b;
        return a + orderB.hoursUsage;
      }, 0),
    [supportOrders]
  );

  const totalOnboardingUsage = useMemo(
    () =>
      onboardingOrders.reduce((a, b) => {
        const orderB = b;
        return a + orderB.hoursUsage;
      }, 0),
    [onboardingOrders]
  );

  const totalprojectUsage = useMemo(
    () =>
      projectOrders.reduce((a, b) => {
        const orderB = b;
        return a + orderB.hoursUsage;
      }, 0),
    [projectOrders]
  );

  const totalSupportQuota = useMemo(
    () =>
      supportOrders.reduce((a, b) => {
        const orderB = b;
        return a + orderB.hoursQuota;
      }, 0),
    [supportOrders]
  );

  const totalOnboardingQuota = useMemo(
    () =>
      onboardingOrders.reduce((a, b) => {
        const orderB = b;
        return a + orderB.hoursQuota;
      }, 0),
    [onboardingOrders]
  );

  const totalProjectQuota = useMemo(
    () =>
      projectOrders.reduce((a, b) => {
        const orderB = b;
        return a + orderB.hoursQuota;
      }, 0),
    [projectOrders]
  );
  return (
    <Stack direction={'row'} gap={4} marginBottom={4}>
      <UsageIndicator
        usageType="Total support hours"
        quota={totalSupportQuota}
        usage={totalSupportUsage}
        loading={isLoading}
      />
      <UsageIndicator
        usageType="Total onboarding hours"
        quota={totalOnboardingQuota}
        usage={totalOnboardingUsage}
        loading={isLoading}
      />
      <UsageIndicator
        usageType="Total project hours"
        quota={totalProjectQuota}
        usage={totalprojectUsage}
        loading={isLoading}
      />
    </Stack>
  );
};
