import { Typography } from '@mui/material';
import { alpha, styled } from '@mui/system';
import palette from 'theme/palette';
import { useEffect, useState } from 'react';
import { RootState, useSelector } from 'redux/store';
import { DataGridKey } from 'redux/slices/datagrid';

function getNpsColor(npsScore: number | null): string {
  if (typeof npsScore !== 'number') {
    return palette.common.npsScore.none;
  }
  if (npsScore < 7) return palette.common.npsScore.detractor;
  if (npsScore > 8) return palette.common.npsScore.promoter;
  else return palette.common.npsScore.passive;
}

const Rating = ({
  rating,
  gridDensity,
  height,
  dataGridKey
}: {
  rating: number | null;
  gridDensity?: string;
  height?: number;
  dataGridKey?: DataGridKey;
}) => {
  const { density } = useSelector((state: RootState) => state.datagrid);
  const [thumbHeight, setThumbHeight] = useState(0);

  const circleDiameter = `${height || thumbHeight}px`;

  useEffect(() => {
    switch (gridDensity || (dataGridKey && density[dataGridKey])) {
      case 'compact':
        setThumbHeight(28);
        break;
      case 'comfortable':
        setThumbHeight(59);
        break;
      default:
      case 'standard':
        setThumbHeight(43);
        break;
    }
  }, [gridDensity, density, dataGridKey]);

  const IconWrapperStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    height: circleDiameter,
    width: circleDiameter,
    justifyContent: 'center',
    color: theme.palette.text.primary,
    margin: 'auto',
    marginBlock: '.5rem'
  }));

  return (
    <IconWrapperStyle
      sx={{
        backgroundImage: `linear-gradient(135deg, ${alpha(getNpsColor(rating), 0.3)} 0%, ${alpha(
          getNpsColor(rating),
          0.7
        )} 100%)`,
        height: circleDiameter,
        width: circleDiameter,
        aspectRatio: '1 / 1'
      }}
    >
      <Typography
        noWrap
        style={{
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: `calc(${circleDiameter} * 0.4)`
        }}
      >
        {rating ?? '-'}
      </Typography>
    </IconWrapperStyle>
  );
};

export default Rating;
