import releaseConfig from 'releaseConfig';

export const getFunctionsUrl = () => {
  return releaseConfig.functionsUrl.startsWith('#{')
    ? process.env.REACT_APP_FUNCTIONS_URL || 'https://npsworkers-development.azurewebsites.net'
    : releaseConfig.functionsUrl;
};

export const getApiUrl = () => {
  return releaseConfig.apiUrl.startsWith('#{')
    ? process.env.REACT_APP_API_URL || 'https://localhost:5001'
    : releaseConfig.apiUrl;
};
