import { useEffect, useState } from 'react';
import { Card, FormControl, Grid, InputLabel, LinearProgress, MenuItem } from '@mui/material';
import { Stack } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import useCustomDispatch from 'redux/dispatch';
import { RootState, useSelector } from 'redux/store';
import { getCurrentEmailProvider, getCurrentSmsProvider } from 'redux/slices/providers';
import { Select } from '@mui/material';
import ChangeProviderDialog, { ProviderChange } from './ChangeProviderDialog';
import { ProviderType } from '../../../@types/providers';

type Provider = {
  name: string;
  region: string;
};
const EMAIL_PROVIDERS: Provider[] = [
  { name: 'SendGrid', region: 'US' },
  { name: 'FlowMailer', region: 'EU' }
];
const SMS_PROVIDERS: Provider[] = [
  { name: 'LinkMobility', region: 'EU' },
  { name: 'Twilio', region: 'US' }
];

type ProviderForm = {
  emailProviderName: string;
  smsProviderName: string;
};

type ProvidersProps = {
  editProviders: boolean;
};
const Providers = ({ editProviders }: ProvidersProps) => {
  const customDispatch = useCustomDispatch();
  const { isLoading, currentEmailProvider, currentSmsProvider } = useSelector(
    (state: RootState) => state.providers
  );
  const [displayChangeProviderDialog, setDisplayChangeProviderDialog] = useState(false);
  const [providerChange, setProviderChange] = useState<ProviderChange | undefined>();

  useEffect(() => {
    customDispatch({
      action: getCurrentEmailProvider,
      disableSuccessMessage: true
    });
    customDispatch({
      action: getCurrentSmsProvider,
      disableSuccessMessage: true
    });
  }, [customDispatch]);

  const isFormLoading = isLoading.getCurrentEmailProvider || isLoading.getCurrentSmsProvider;

  const formik = useFormik<ProviderForm>({
    enableReinitialize: true,
    initialValues: {
      emailProviderName: currentEmailProvider?.provider ?? '',
      smsProviderName: currentSmsProvider?.provider ?? ''
    },
    onSubmit: async (values) => {
      const changedProviderType =
        values.emailProviderName && values.emailProviderName !== currentEmailProvider?.provider
          ? ProviderType.EMAIL
          : ProviderType.SMS;
      let currentProviderName =
        changedProviderType === ProviderType.EMAIL
          ? currentEmailProvider?.provider
          : currentSmsProvider?.provider;
      let newProviderName =
        changedProviderType === ProviderType.EMAIL
          ? values.emailProviderName
          : values.smsProviderName;
      setProviderChange({
        currentName: currentProviderName,
        newName: newProviderName,
        providerType: changedProviderType
      });
      setDisplayChangeProviderDialog(true);
    }
  });

  const { handleSubmit, getFieldProps, dirty } = formik;

  useEffect(() => {
    if (dirty) {
      handleSubmit();
    }
  }, [dirty, handleSubmit]);

  const formFields = [
    <FormControl key="emailProvider" fullWidth disabled={!editProviders}>
      <InputLabel id="emailProvider">Email Provider</InputLabel>
      <Select
        {...getFieldProps('emailProviderName')}
        fullWidth
        labelId="emailProvider"
        label="Email Provider"
      >
        {EMAIL_PROVIDERS?.map((provider: Provider, index: number) => (
          <MenuItem key={index} value={provider.name}>
            {`${provider.name} (${provider.region})`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>,
    <FormControl key="smsProvider" fullWidth disabled={!editProviders}>
      <InputLabel id="smsProvider">SMS Provider</InputLabel>
      <Select {...getFieldProps('smsProviderName')} key="2" fullWidth label="SMS Provider">
        {SMS_PROVIDERS?.map((provider: Provider, index: number) => (
          <MenuItem key={index} value={provider.name}>
            {`${provider.name} (${provider.region})`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ];

  const onCloseDialog = () => {
    setDisplayChangeProviderDialog(false);
    formik.resetForm();
  };

  return (
    <>
      <Card sx={{ p: 3 }} style={{ maxWidth: '30rem' }}>
        {isFormLoading && <LinearProgress />}
        <FormikProvider value={formik}>
          <Form id="security" noValidate onSubmit={handleSubmit} onChange={handleSubmit}>
            <Stack spacing={3} alignItems="flex-end">
              <Grid container spacing={3}>
                {formFields.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    {item}
                  </Grid>
                ))}
              </Grid>
            </Stack>
          </Form>
        </FormikProvider>
      </Card>
      <ChangeProviderDialog
        providerChange={providerChange}
        isOpen={displayChangeProviderDialog}
        closeDialog={onCloseDialog}
      />
    </>
  );
};

export default Providers;
