import { ReactComponent as Account } from './account.svg';
import { ReactComponent as Add } from './add.svg';
import { ReactComponent as Beta } from './beta.svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as CampaignActive } from './campaign-active.svg';
import { ReactComponent as CampaignClosed } from './campaign-closed.svg';
import { ReactComponent as Campaigns } from './campaigns.svg';
import { ReactComponent as Clear } from './clear.svg';
import { ReactComponent as Clock } from './clock.svg';
import { ReactComponent as Copy } from './copy.svg';
import { ReactComponent as Companies } from './companies.svg';
import { ReactComponent as Customize } from './customize.svg';
import { ReactComponent as DarkMode } from './dark-mode.svg';
import { ReactComponent as Dashboard } from './dashboard.svg';
import { ReactComponent as DataRetention } from './data-retention.svg';
import { ReactComponent as Delete } from './delete.svg';
import { ReactComponent as Disable } from './disable.svg';
import { ReactComponent as Disabled } from './disabled.svg';
import { ReactComponent as Display } from './display.svg';
import { ReactComponent as Download } from './download.svg';
import { ReactComponent as Edit } from './edit.svg';
import { ReactComponent as Email } from './email.svg';
import { ReactComponent as Employees } from './employees.svg';
import { ReactComponent as Enable } from './enable.svg';
import { ReactComponent as Enabled } from './enabled.svg';
import { ReactComponent as Equal } from './equal.svg';
import { ReactComponent as ExpandDown } from './expand-down.svg';
import { ReactComponent as EyeOff } from './eye-off.svg';
import { ReactComponent as EyeOn } from './eye-on.svg';
import { ReactComponent as Flag } from './flag.svg';
import { ReactComponent as ForwardEmail } from './forward-email.svg';
import { ReactComponent as FullScreen } from './full-screen.svg';
import { ReactComponent as General } from './general.svg';
import { ReactComponent as Information } from './information.svg';
import { ReactComponent as IntegrationStore } from './integration-store.svg';
import { ReactComponent as IVR } from './ivr.svg';
import { ReactComponent as Key } from './key.svg';
import { ReactComponent as LightMode } from './light-mode.svg';
import { ReactComponent as Link } from './link.svg';
import { ReactComponent as License } from './license.svg';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as Manual } from './manual.svg';
import { ReactComponent as MoreMenu } from './more-menu.svg';
import { ReactComponent as NavigationNext } from './navigation-next.svg';
import { ReactComponent as NavigationPrevious } from './navigation-previous.svg';
import { ReactComponent as NotReload } from './not-reload.svg';
import { ReactComponent as Note } from './note.svg';
import { ReactComponent as OrderAndUsage } from './order-and-usage.svg';
import { ReactComponent as Organizations } from './organizations.svg';
import { ReactComponent as Overview } from './overview.svg';
import { ReactComponent as PGap } from './p-gap.svg';
import { ReactComponent as Password } from './password.svg';
import { ReactComponent as ParagraphCenterAlign } from './paragraph-center-align.svg';
import { ReactComponent as ParagraphLeftAlign } from './paragraph-left-align.svg';
import { ReactComponent as ParagraphRightAlign } from './paragraph-right-align.svg';
import { ReactComponent as Person } from './person.svg';
import { ReactComponent as Phone } from './phone.svg';
import { ReactComponent as Providers } from './providers.svg';
import { ReactComponent as Question } from './question.svg';
import { ReactComponent as QuestionWithoutCircle } from './question-without-circle.svg';
import { ReactComponent as Reload } from './reload.svg';
import { ReactComponent as Reports } from './reports.svg';
import { ReactComponent as ResendSurvey } from './resend-survey.svg';
import { ReactComponent as Reset } from './reset.svg';
import { ReactComponent as Respondents } from './respondents.svg';
import { ReactComponent as Reviews } from './reviews.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as Security } from './security.svg';
import { ReactComponent as Senders } from './senders.svg';
import { ReactComponent as Settings } from './settings.svg';
import { ReactComponent as SMS } from './sms.svg';
import { ReactComponent as SuperAdmin } from './super-admin.svg';
import { ReactComponent as Swap } from './swap.svg';
import { ReactComponent as Template } from './template.svg';
import { ReactComponent as ThrottlingGroup } from './throttling-group.svg';
import { ReactComponent as Unsubscribe } from './unsubscribe.svg';
import { ReactComponent as User } from './user.svg';
import { ReactComponent as Users } from './users.svg';
import { ReactComponent as Connection } from './connection.svg';
import { ReactComponent as CurlyBrackets } from './curly-brackets.svg';
import { ReactComponent as Success } from './success.svg';
import { ReactComponent as Warning } from './warning.svg';
import { ReactComponent as Error } from './error.svg';
import { ReactComponent as Menu } from './menu.svg';
import { ReactComponent as Pin } from './pin.svg';
import { ReactComponent as NpsNotification } from './nps-notification.svg';
import { ReactComponent as ArrowRight } from './arrow-right.svg';
import { ReactComponent as ProductConfigurations } from './product-configurations.svg';
import { ReactComponent as QrCode } from './qr-code.svg';
import { ReactComponent as Robot } from './robot.svg';

import { IconType } from '../../@types/icons';

const Icons: {
  [key in IconType]: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
} = {
  account: Account,
  add: Add,
  arrowright: ArrowRight,
  beta: Beta,
  calendar: Calendar,
  campaignactive: CampaignActive,
  campaignclosed: CampaignClosed,
  campaigns: Campaigns,
  clear: Clear,
  clock: Clock,
  copy: Copy,
  companies: Companies,
  customize: Customize,
  darkmode: DarkMode,
  dashboard: Dashboard,
  dataretention: DataRetention,
  delete: Delete,
  disable: Disable,
  disabled: Disabled,
  display: Display,
  download: Download,
  edit: Edit,
  email: Email,
  employees: Employees,
  enable: Enable,
  enabled: Enabled,
  expanddown: ExpandDown,
  eyeoff: EyeOff,
  eyeon: EyeOn,
  flag: Flag,
  forwardemail: ForwardEmail,
  fullscreen: FullScreen,
  general: General,
  information: Information,
  integrationstore: IntegrationStore,
  ivr: IVR,
  key: Key,
  license: License,
  lightmode: LightMode,
  link: Link,
  logo: Logo,
  manual: Manual,
  moremenu: MoreMenu,
  notreload: NotReload,
  note: Note,
  npsnotification: NpsNotification,
  orderandusage: OrderAndUsage,
  organizations: Organizations,
  overview: Overview,
  pgap: PGap,
  paragraphCenterAlign: ParagraphCenterAlign,
  paragraphLeftAlign: ParagraphLeftAlign,
  paragraphRightAlign: ParagraphRightAlign,
  password: Password,
  person: Person,
  phone: Phone,
  providers: Providers,
  question: Question,
  questionwithoutcircle: QuestionWithoutCircle,
  reload: Reload,
  reports: Reports,
  resendsurvey: ResendSurvey,
  reset: Reset,
  respondents: Respondents,
  reviews: Reviews,
  search: Search,
  security: Security,
  senders: Senders,
  settings: Settings,
  sms: SMS,
  superadmin: SuperAdmin,
  template: Template,
  throttlinggroup: ThrottlingGroup,
  unsubscribe: Unsubscribe,
  user: User,
  users: Users,
  equal: Equal,
  navigationnext: NavigationNext,
  navigationprevious: NavigationPrevious,
  swap: Swap,
  connection: Connection,
  curlybrackets: CurlyBrackets,
  success: Success,
  warning: Warning,
  error: Error,
  menu: Menu,
  pin: Pin,
  productconfigurations: ProductConfigurations,
  qrcode: QrCode,
  robot: Robot
};

export default Icons;
