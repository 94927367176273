import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory, BrowserHistoryOptions } from 'history';
import releaseConfig from 'releaseConfig';

export const reactPlugin = new ReactPlugin();
export const setupAppInsights = () => {
  const connectionString = releaseConfig.connectionString.startsWith('#{')
    ? ''
    : releaseConfig.connectionString;

  if (!connectionString) return;
  const browserHistory = createBrowserHistory({ basename: '' } as BrowserHistoryOptions);
  const appInsights = new ApplicationInsights({
    config: {
      connectionString,
      autoTrackPageVisitTime: true,
      enableAjaxErrorStatusText: true,
      enableAjaxPerfTracking: true,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory }
      }
    }
  });
  appInsights.loadAppInsights();
};
