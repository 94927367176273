import { useEffect, useRef } from 'react';
import '../../styles/util.css';

type HighlightOnFirstRenderProps = {
  enabled: boolean;
  children: JSX.Element;
};

const HighlightOnFirstRender = ({ enabled, children }: HighlightOnFirstRenderProps) => {
  const initialEnabledRef = useRef(enabled);
  useEffect(() => {
    if (!enabled) {
      initialEnabledRef.current = false;
    }
  }, [enabled]);
  return <div className={initialEnabledRef.current ? 'flash' : ''}>{children}</div>;
};

export default HighlightOnFirstRender;
