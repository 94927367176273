import { useEffect, useState, useCallback, Dispatch, SetStateAction, useRef } from 'react';
import { RootState, useSelector } from 'redux/store';
import { Box } from '@mui/material';
import {
  CustomDataGrid,
  CustomGridToolbar,
  CustomLoadingOverlay,
  CustomPagination
} from 'components/datagrid/Custom';
import { GridColDef } from '@mui/x-data-grid';
import AddSenderDialog from './AddSenderDialog';
import { Sender } from '../../../@types/organization';
import useCustomDispatch from 'redux/dispatch';
import { deleteSender, getSenders } from 'redux/slices/organization';
import { DataGridKey } from 'redux/slices/datagrid';
import MoreMenuButton from 'components/util/MoreMenuButton';

const DATA_GRID_KEY = DataGridKey.SendersList;

const OrganizationSenders = ({
  displayAddSender,
  setDisplayAddSender
}: {
  displayAddSender: boolean;
  setDisplayAddSender: Dispatch<SetStateAction<boolean>>;
}) => {
  const customDispatch = useCustomDispatch();
  const { senders, loading } = useSelector((state: RootState) => state.organization);
  const [senderToEdit, setSenderToEdit] = useState<Sender | undefined>();
  const senderToDeleteRef = useRef<undefined | number>();
  const [take] = useState(25);

  const fetchSenders = useCallback(() => {
    customDispatch({ action: getSenders, disableSuccessMessage: true });
  }, [customDispatch]);

  useEffect(() => {
    fetchSenders();
  }, [fetchSenders]);

  const columns: GridColDef[] = [
    {
      field: 'id'
    },
    {
      field: 'sender',
      headerName: 'Sender',
      flex: 1
    },
    {
      field: 'senderType',
      headerName: 'Type',
      flex: 1
    },
    {
      field: 'changedBy',
      headerName: 'Changed By',
      flex: 1
    },
    {
      field: 'edit',
      headerName: '',
      editable: false,
      disableColumnMenu: true,
      hideable: false,
      align: 'center',
      hideSortIcons: true,
      filterable: false,
      sortable: false,
      renderCell: (cellValues) => {
        const res = cellValues.row;
        return (
          <MoreMenuButton
            key={res.id}
            itemType="sender"
            onEdit={() => {
              setDisplayAddSender(true);
              setSenderToEdit(res);
            }}
            onDelete={() => {
              customDispatch({
                action: deleteSender,
                actionParameters: senderToDeleteRef.current,
                onSuccess: () => {
                  fetchSenders();
                }
              });
            }}
            onMenuClick={(opened) => {
              if (!opened) return;
              senderToDeleteRef.current = res.id;
            }}
          />
        );
      }
    }
  ];

  return (
    <Box>
      <CustomDataGrid
        dataGridKey={DATA_GRID_KEY}
        columns={columns}
        rows={senders}
        loading={loading.senders}
        disableSelectionOnClick
        pagination
        rowCount={senders.length}
        pageSize={take}
        components={{
          Toolbar: CustomGridToolbar,
          Pagination: CustomPagination,
          LoadingOverlay: CustomLoadingOverlay
        }}
      />

      <AddSenderDialog
        display={displayAddSender}
        close={() => {
          setDisplayAddSender(false);
          setSenderToEdit(undefined);
        }}
        senderToEdit={senderToEdit}
        fetchSenders={fetchSenders}
      />
    </Box>
  );
};

export default OrganizationSenders;
