import { createContext, ReactNode, useEffect, useReducer, useState } from 'react';
import useCustomDispatch from 'redux/dispatch';
import { RootReducerTypes } from 'redux/rootReducer';
import { User } from '../@types/account';
// @types
import {
  ActionMap,
  AuthState,
  AuthUser,
  JWTContextType,
  LogoutParams,
  NewPassword,
  Password,
  UserSetup
} from '../@types/authentication';
import { RootState, useSelector } from '../redux/store';
// utils
import axios from '../utils/axios';
import { getApplicationClaims, getUserRole, isValidToken, setSession } from '../utils/jwt';
import { useNavigate } from 'react-router';
import { Organisation } from '../@types/organization';
import useDisplayMessage from 'components/messages/useMessage';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [RootReducerTypes.RESET_STATES]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const customDispatch = useCustomDispatch();
  const displayMessage = useDisplayMessage();
  const [state, reducerDispatch] = useReducer(JWTReducer, initialState);
  const { organizationProfile } = useSelector((state: RootState) => state.organization);
  const [rerenderOnOrgChange, setRerenderOnOrgChange] = useState<number>(1);
  const [selectedOrg, setSelectedOrg] = useState<Organisation | undefined>();
  const navigate = useNavigate();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          let claims = getApplicationClaims(accessToken);
          setSession(accessToken);

          let user = await getUserProfile();
          user!.claims = claims;
          user!.role = getUserRole(accessToken);
          reducerDispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          reducerDispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        reducerDispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const getUserProfile = async (): Promise<AuthUser> => {
    const response = await axios.get('/user');
    return response.data;
  };

  const login = async (
    username?: string,
    password?: string,
    organizationId?: string,
    twoFactorCode?: string,
    rerenderOnOrgChange?: boolean
  ) => {
    let response;
    if (username && password) {
      response = await axios.post('/user/login', {
        username: username,
        password,
        application: 'basic',
        organizationId: organizationId,
        twofactorcode: twoFactorCode
      });
    } else if (organizationId) {
      response = await axios.get(`/user/changeOrganization?orgId=${organizationId}`);
    } else {
      response = await axios.get(`/user/changeOrganization`);
    }

    let accessToken = response.data.access_token;
    setSession(accessToken);

    if (response.data.organizations && response.data.organizations.length >= 1) {
      return response.data.organizations;
    } else {
      // Set auth token to call api
      let accessToken = response.data.access_token;
      setSession(accessToken);

      //if user is changing organization, reset all states, and refresh page
      const isNewOrg = organizationProfile?.id && organizationProfile?.id !== organizationId;
      if (isNewOrg) {
        await customDispatch({
          actionParameters: { type: RootReducerTypes.RESET_STATES },
          disableAllMessages: true
        });
      }

      //if there was no previous registered used organization, or
      //if the selected organization is another than the one last used, remove any id's from url path, as to not navigate to items that do not exist on current org
      if (!organizationProfile?.id || organizationProfile.id !== organizationId) {
        const currentPath = window.location.pathname;
        const paths = currentPath?.split('/');
        let newPath = '';

        for (const path of paths ?? []) {
          //if the path does not contain a number, add it. Otherwise break loop, and use newPath
          if (isNaN(parseFloat(path))) {
            newPath += `/${path}`;
            continue;
          }
          break;
        }
        navigate(newPath);
      }

      let claims = getApplicationClaims(accessToken);

      getUserProfile().then((user) => {
        user!.claims = claims;
        user!.role = getUserRole(accessToken);
        // Get user profile
        reducerDispatch({
          type: Types.Login,
          payload: {
            user
          }
        });

        customDispatch({ actionParameters: { type: Types.Login }, disableAllMessages: true });
      });

      //if org changed from header, trigger a rerender of entire application
      if (rerenderOnOrgChange) {
        setRerenderOnOrgChange((prevState) => prevState + 1);
      }
    }
  };

  const signUp = (newUser: UserSetup) => {
    return async () => {
      try {
        const response = await axios.post('/user/setup', newUser);
        return await Promise.resolve({
          result: response,
          defaultSuccessMessage: 'New account created'
        });
      } catch (error: any) {
        return Promise.reject({
          error: error,
          defaultErrorMessage: 'Could not sign up'
        });
      }
    };
  };

  const logout = async (params?: LogoutParams) => {
    reducerDispatch({ type: Types.Logout });
    customDispatch({ actionParameters: { type: Types.Logout }, disableAllMessages: true });
    setSession(null);

    if (params?.logoutInfoMessage) {
      displayMessage.info({
        message: params.logoutInfoMessage,
        autoHideDuration: 10000
      });
    }
  };

  const forgotPassword = (email: string) => {
    return async () => {
      try {
        const response = await axios.post('/user/forgotpassword', {
          forgotEmail: email
        });
        return await Promise.resolve({
          result: response,
          defaultSuccessMessage: 'If the email is registered, you will receive the recovery options'
        });
      } catch (error: any) {
        return Promise.reject({
          error: error,
          defaultErrorMessage: 'Could not send link to reset password'
        });
      }
    };
  };

  const resetPassword = (password: NewPassword) => {
    return async () => {
      try {
        const response = await axios.put('/user/resetpassword', password);
        return await Promise.resolve({
          result: response,
          defaultSuccessMessage: 'Password updated'
        });
      } catch (error: any) {
        return Promise.reject({
          error: error,
          defaultErrorMessage: 'Could not update password'
        });
      }
    };
  };

  const updateProfile = async (updatedUser: User) => {
    if (updatedUser.phoneNumber?.length === 0) {
      updatedUser.phoneNumberConfirmed = false;
    }

    const response = await axios.put('/user', updatedUser);

    const user = response.data;
    const accessToken = window.localStorage.getItem('accessToken');
    if (accessToken && isValidToken(accessToken)) {
      user!.claims = getApplicationClaims(accessToken);
      user!.role = getUserRole(accessToken);
    }

    reducerDispatch({
      type: Types.Login,
      payload: {
        user
      }
    });
  };

  const updatePassword = async (passwords: Password) => {
    await axios.put('/updatepassword', passwords);
  };

  const updateTwoFactorEnabled = async (enable: boolean) => {
    if (enable) {
      await axios.post('/user/enabletfa');
    } else {
      await axios.post('/user/disabletfa');
    }

    //update user state, with new value for two factor auth
    reducerDispatch({
      type: Types.Login,
      payload: {
        user: { ...state.user, twoFactorEnabled: enable }
      }
    });
  };

  const updatePhoneNumber = async (phoneNumber: string, authCode?: string) => {
    //define params
    const params = {
      phoneNumber,
      code: authCode
    };

    try {
      //if no authCode, send one - else vildate
      if (!authCode) {
        axios.post('/user/AddPhoneNumber', params);
      } else {
        await axios.post('/user/VerifyPhoneNumber', params);
      }
    } catch (error: any) {
      return Promise.reject(error.errors);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        rerenderOnOrgChange: rerenderOnOrgChange,
        method: 'jwt',
        login,
        logout,
        signUp,
        forgotPassword,
        resetPassword,
        updateProfile,
        updatePassword,
        updateTwoFactorEnabled,
        updatePhoneNumber,
        selectedOrg,
        setSelectedOrg,
        isDataReflectingCurrentOrg: !(
          state.isAuthenticated &&
          (!Boolean(organizationProfile?.id) ||
            Boolean(
              selectedOrg?.organizationId && organizationProfile?.id !== selectedOrg?.organizationId
            ))
        )
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
