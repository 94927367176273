import { Icon } from '@iconify/react';
// material
import { Alert, IconButton, Link, Stack, Tooltip } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { PATH_AUTH } from '../../../routes/paths';
import { NavLink as RouterLink } from 'react-router-dom';
import useDisplayMessage from 'components/messages/useMessage';
import { CustomLoadingButton } from 'components/util/CustomButton';
import CustomTextField from 'components/util/CustomTextField';

// ----------------------------------------------------------------------
type InitialValues = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
  twoFactorCode?: string;
  isTwoFactorAuthEnabled: boolean;
};

export default function LoginForm(props: any) {
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const displayMessage = useDisplayMessage();
  const [isTwoFactorAuthEnabled, setIsTwoFactorAuthEnabled] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
    isTwoFactorAuthEnabled: Yup.boolean(),
    twoFactorCode: Yup.string().when('isTwoFactorAuthEnabled', {
      is: true,
      then: Yup.string()
        .required('Authentication code is required')
        .length(6, 'The code must be 6 characters')
    })
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: '',
      password: '',
      remember: true,
      isTwoFactorAuthEnabled: false,
      twoFactorCode: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, setFieldValue }) => {
      try {
        let organisations = await login(
          values.email,
          values.password,
          undefined,
          values.twoFactorCode
        );
        if (organisations?.length >= 1) {
          // User has multiple orgs
          props.setUserOrganisations(organisations);
        }
      } catch (error: any) {
        if (error.status === '2FACodeRequired') {
          setIsTwoFactorAuthEnabled(true);
          setFieldValue('isTwoFactorAuthEnabled', true);
        } else {
          displayMessage.error({ message: { title: 'Could not login' } });
        }

        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.message });
        }
      }
    }
  });

  const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const onSendAuthCodeAgain = () => {
    login(values.email, values.password);
  };

  return (
    <div>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate autoSave="off" onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

            <CustomTextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <CustomTextField
              enableShowHide
              fullWidth
              autoComplete="current-password"
              label="Password"
              {...getFieldProps('password')}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            {isTwoFactorAuthEnabled && (
              <CustomTextField
                autoFocus
                fullWidth
                enableShowHide
                label="Two factor authentication"
                {...getFieldProps('twoFactorCode')}
                error={Boolean(touched.twoFactorCode && errors.twoFactorCode)}
                helperText={touched.twoFactorCode && errors.twoFactorCode}
                endAdornments={[
                  <Tooltip key="resendCode" title="Send code again">
                    <IconButton onClick={onSendAuthCodeAgain} edge="end">
                      <Icon icon="dashicons:update" />
                    </IconButton>
                  </Tooltip>
                ]}
              />
            )}
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
            {/*<FormControlLabel*/}
            {/*  control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}*/}
            {/*  label="Remember me"*/}
            {/*/>*/}

            <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.forgotPassword}>
              Forgot password?
            </Link>
          </Stack>
          <CustomLoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Sign in
          </CustomLoadingButton>
        </Form>
      </FormikProvider>
    </div>
  );
}
