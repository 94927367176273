import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from 'redux/store';
import {
  GroupedMergeFields,
  MergeField,
  MergeFieldCategory,
  MergeFieldCategoryName,
  MergeFieldsState
} from '../../@types/mergeFields';

// ----------------------------------------------------------------------

const initialState: MergeFieldsState = {
  isLoading: {},
  mergeFields: [],
  groupedMergeFields: undefined
};

const slice = createSlice({
  name: 'mergeFields',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    // END LOADING
    endLoading(state, action) {
      state.isLoading[action.payload] = false;
    },
    setMergeFields(state, action) {
      const mergeFields = action.payload as MergeField[];
      state.mergeFields = mergeFields;
      state.groupedMergeFields = mergeFields.reduce((acc: GroupedMergeFields, field) => {
        const categoryKey = MergeFieldCategory[field.category] as MergeFieldCategoryName;

        // Initialize the array if it does not exist
        if (!acc[categoryKey]) {
          acc[categoryKey] = [];
        }

        // Push the item to the corresponding category array
        acc[categoryKey]!.push({ name: field.name, value: field.value });

        return acc;
      }, {} as GroupedMergeFields);
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
//export const {} = slice.actions;

// ----------------------------------------------------------------------

export const getMergeFields = () => {
  return async () => {
    dispatch(slice.actions.startLoading('getMergeFields'));
    try {
      const response = await axios.get(`/merge-fields`);
      dispatch(slice.actions.setMergeFields(response.data));
      dispatch(slice.actions.endLoading('getMergeFields'));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Fetched merge fields'
      });
    } catch (error: any) {
      dispatch(slice.actions.endLoading('getMergeFields'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not fetch merge fields`
      });
    }
  };
};
