import { Stack, Typography } from '@mui/material';
import DisabledIcon from 'components/util/Status/DisabledIcon';
import EnabledIcon from 'components/util/Status/EnabledIcon';
import { FunctionComponent } from 'react';

interface SummaryListProps {
  title: string;
  items: string[];
  active: boolean;
}
export const SummaryList: FunctionComponent<SummaryListProps> = ({ title, items, active }) => {
  return items.length > 0 ? (
    <>
      <Typography>{title}</Typography>
      {items.map((i) => (
        <Stack key={i} flexDirection={'row'} gap={2}>
          {active ? <EnabledIcon /> : <DisabledIcon />}
          <Typography>{i}</Typography>
        </Stack>
      ))}
    </>
  ) : null;
};
