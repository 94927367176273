export const nullToEmptyString = (obj: any): any => {
  if (obj === null || obj === undefined) return '';
  if (Array.isArray(obj)) return obj.map(nullToEmptyString);
  if (typeof obj === 'object') {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, nullToEmptyString(value)])
    );
  }
  return obj;
};
