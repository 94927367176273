import { DialogActions } from '@material-ui/core';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import CustomButton from './CustomButton';

type UnsavedChangesModalProps = {
  isOpen: boolean;
  onCancel: any;
  onDisregard: any;
  onSave: any;
};

const UnsavedChangesModal = ({
  isOpen,
  onCancel,
  onDisregard,
  onSave
}: UnsavedChangesModalProps) => {
  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <DialogTitle>Unsaved changes</DialogTitle>
      <DialogContent>
        <Typography>You have made unsaved changes.</Typography>
        <Typography>Do you want to save or disregard them?</Typography>
      </DialogContent>
      <DialogActions>
        <CustomButton color="primary" onClick={onCancel}>
          Cancel
        </CustomButton>
        <CustomButton variant="contained" onClick={onSave}>
          Save
        </CustomButton>
        <CustomButton variant="outlined" onClick={onDisregard}>
          Disregard
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default UnsavedChangesModal;
