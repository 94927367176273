import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from 'redux/store';
import { AuthenticationType, Relay, RelayState, RelayType } from '../../@types/relay';

// ----------------------------------------------------------------------
const initialRelaySettings: Relay = {
  relayType: RelayType.None,
  externalWebHookUrl: '',
  apiAuthentication: {
    authenticationType: AuthenticationType.None,
    basicAuthUser: '',
    basicAuthPassword: '',
    bearerToken: '',
    oAuthClientId: '',
    oAuthClientSecret: '',
    oAuthTokenEndpoint: '',
    oAuthResource: ''
  }
};

const initialState: RelayState = {
  isLoading: {},
  isInitialyFetched: false,
  relaySettings: initialRelaySettings
};

const slice = createSlice({
  name: 'relay',
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    stopLoading(state, action) {
      state.isLoading[action.payload] = false;
    },
    setRelaySettings(state, action) {
      state.relaySettings = action.payload?.relayType ? action.payload : initialRelaySettings;
      state.isInitialyFetched = true;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
//export const {} = slice.actions;

// ----------------------------------------------------------------------

export const getRelaySettings = () => {
  return async () => {
    dispatch(slice.actions.startLoading('getRelaySettings'));
    try {
      const response = await axios.get(`/relaySettings`);
      const relaySettings = response.data ?? initialState.relaySettings;
      dispatch(slice.actions.setRelaySettings(relaySettings));
      dispatch(slice.actions.stopLoading('getRelaySettings'));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Fetched relay settings'
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading('getRelaySettings'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not fetch relay settings`
      });
    }
  };
};

type SetRelaySettingsParams = {
  relaySettings: Relay;
};
export const setRelaySettings = ({ relaySettings }: SetRelaySettingsParams) => {
  return async () => {
    dispatch(slice.actions.startLoading('setRelaySettings'));
    try {
      const sanitizedRelaySettings = {
        ...relaySettings,
        id: undefined
      };
      const response = relaySettings.id
        ? await axios.put(`/relaySettings`, sanitizedRelaySettings)
        : await axios.post(`/relaySettings`, sanitizedRelaySettings);

      dispatch(slice.actions.setRelaySettings(response.data));
      dispatch(slice.actions.stopLoading('setRelaySettings'));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Updated relay settings'
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading('setRelaySettings'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not update relay settings`
      });
    }
  };
};
