import { combineReducers } from 'redux';
import { createMigrate, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import campaignReducer, {
  campaignPersistWhitelist,
  initialState as campaignInitialState
} from './slices/campaign';
import companyReducer from './slices/company';
import dashboardReducer, {
  dashboardPersistWhitelist,
  initialState as dashboardInitialState
} from './slices/dashboard';
import dataReducer from './slices/data';
import datagridReducer, {
  migrations as datagridMigrations,
  persistsVersion as datagridPersistVersion
} from './slices/datagrid';
import employeeReducer from './slices/employee';
import organizationReducer from './slices/organization';
import pgapReducer from './slices/pgap';
import reportsReducer, {
  reportsPersistWhitelist,
  initialState as reportsInitialState
} from './slices/reports';
import respondentReducer from './slices/respondent';
import responseReducer from './slices/responses';
import reviewsReducer from './slices/reviews';
import ivrReducer from './slices/ivr';
import outlookReducer from './slices/outlook';
import etrack1Reducer from './slices/etrack1';
import profileReducer from './slices/profile';
import seatsReducer from './slices/seats';
import integrationsReducer from './slices/integrations';
import versioningReducer from './slices/versioning';
import releaseConfig from 'releaseConfig';
import adminReducer from './slices/admin';
import providersReducer from './slices/providers';
import permissionsReducer from './slices/permissions';
import campaignMemberReducer from './slices/campaignMember';
import throttlingGroupReducer from './slices/throttlingGroup';
import floatingActionButtonsReducer from './slices/floatingActionButtons';
import editingReducer from './slices/editing';
import relayReducer from './slices/relay';
import orderReducer from './slices/orders';
import npsNotificationsReducer from './slices/npsNotifications';
import mergeFieldsReducer from './slices/mergeFields';
import productConfigurationsReducer from './slices/productConfigurations';
import { getPersistConfig } from 'redux-deep-persist';
import { isDev } from 'authConfig';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const campaignPersistConfig = getPersistConfig({
  key: 'campaign',
  storage,
  keyPrefix: 'redux-',
  whitelist: campaignPersistWhitelist,
  rootReducer: campaignReducer
});

const dashboardPersistConfig = getPersistConfig({
  key: 'dashboard',
  storage,
  keyPrefix: 'redux-',
  whitelist: dashboardPersistWhitelist,
  rootReducer: dashboardReducer
});

const datagridPersistConfig = getPersistConfig({
  key: 'datagrid',
  storage,
  keyPrefix: 'redux-',
  rootReducer: datagridReducer,
  version: datagridPersistVersion,
  migrate: createMigrate(datagridMigrations, { debug: isDev })
});

const reportsPersistConfig = getPersistConfig({
  key: 'reports',
  storage,
  keyPrefix: 'redux-',
  whitelist: reportsPersistWhitelist,
  rootReducer: reportsReducer
});

const versioningPersistConfig = {
  key: 'versioning',
  storage,
  keyPrefix: 'redux-',
  rootReducer: versioningReducer
};

const adminPersistConfig = {
  key: 'admin',
  storage,
  keyPrefix: 'redux-'
};

const appReducer = combineReducers({
  campaign: persistReducer(campaignPersistConfig, campaignReducer),
  response: responseReducer,
  pgap: pgapReducer,
  employee: employeeReducer,
  organization: organizationReducer,
  company: companyReducer,
  datagrid: persistReducer(datagridPersistConfig, datagridReducer),
  respondent: respondentReducer,
  dashboard: persistReducer(dashboardPersistConfig, dashboardReducer),
  data: dataReducer,
  reports: persistReducer(reportsPersistConfig, reportsReducer),
  reviews: reviewsReducer,
  ivr: ivrReducer,
  outlook: outlookReducer,
  etrack1: etrack1Reducer,
  profile: profileReducer,
  seats: seatsReducer,
  integrations: integrationsReducer,
  versioning: persistReducer(versioningPersistConfig, versioningReducer),
  admin: persistReducer(adminPersistConfig, adminReducer),
  providers: providersReducer,
  permissions: permissionsReducer,
  campaignMember: campaignMemberReducer,
  throttlingGroup: throttlingGroupReducer,
  floatingActionButtons: floatingActionButtonsReducer,
  editing: editingReducer,
  relay: relayReducer,
  order: orderReducer,
  npsNotifications: npsNotificationsReducer,
  mergeFields: mergeFieldsReducer,
  productConfigurations: productConfigurationsReducer
});

export enum RootReducerTypes {
  RESET_STATES = 'RESET_STATES',
  REFRESH_ON_NEW_VERSION = 'REFRESH_ON_NEW_VERSION'
}

const rootReducer = (state: any, action: any) => {
  if (action.type === RootReducerTypes.RESET_STATES) {
    state = resetAndGetStates(state);
  }
  if (action.type === RootReducerTypes.REFRESH_ON_NEW_VERSION) {
    state = resetAndGetStates(state);

    console.info(
      '%cAutomatically refreshing page due to new version of build',
      'font-weight: bold; font-size: 1.5rem;color: red; text-shadow: 1px 1px 0 black'
    );

    //save buildNumber being used on refresh in session storage, as redux states may not be updated correctly/yet in all instances
    sessionStorage.setItem('latestBuildNumber', releaseConfig.frontendBuildNumber);

    //do a hard refresh of page
    const currentHref = window.location.href;
    window.location.href = currentHref;
  }

  return appReducer(state, action);
};

export { rootPersistConfig, rootReducer };

type GetResetPartlyPersistedStateProps = {
  currentState: Record<string, any>;
  initialState: Record<string, any>;
  persistedAttributes: string[];
};
const getResetPartlyPersistedState = ({
  currentState,
  initialState,
  persistedAttributes
}: GetResetPartlyPersistedStateProps) => {
  let newState = { ...initialState }; // Start from initialState

  // Only overwrite properties that are in persistedAttributes
  for (let attr of persistedAttributes) {
    if (currentState.hasOwnProperty(attr)) {
      newState[attr] = currentState[attr];
    }
  }
  return newState;
};

const resetAndGetStates = (currentState: any) => {
  // Reset partly persisted states, only keeping the whitelisted attributes
  const partlyPersistedInitialStates: {
    [key: string]: Record<string, any>;
  } = {
    dashboard: getResetPartlyPersistedState({
      currentState: currentState.dashboard,
      initialState: dashboardInitialState,
      persistedAttributes: dashboardPersistWhitelist
    }),
    campaign: getResetPartlyPersistedState({
      currentState: currentState.campaign,
      initialState: campaignInitialState,
      persistedAttributes: campaignPersistWhitelist
    }),
    reports: getResetPartlyPersistedState({
      currentState: currentState.reports,
      initialState: reportsInitialState,
      persistedAttributes: reportsPersistWhitelist
    })
  };

  const { versioning, admin, datagrid } = currentState; // Everything in these states should be kept
  return { versioning, admin, datagrid, ...partlyPersistedInitialStates };
};
