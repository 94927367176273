import { Form, FormikProvider, useFormik } from 'formik';
import useCustomDispatch from 'redux/dispatch';
import { RootState, useSelector } from 'redux/store';
import { updateOrganizationProfile } from 'redux/slices/organization';
import { OrganizationProfile } from '../../../@types/organization';
import { useTheme } from '@mui/material';
import SettingsCard from 'components/settings/SettingsCard';
import { useTranslator } from 'translation/useTranslator';
import { TranslationWithHtml } from 'components/text/TranslationWithHtml';

const HumanVerification = () => {
  const translator = useTranslator();
  const theme = useTheme();
  const customDispatch = useCustomDispatch();
  const { organizationProfile, loading } = useSelector((state: RootState) => state.organization);

  const formik = useFormik<Partial<OrganizationProfile>>({
    enableReinitialize: true,
    initialValues: {
      enableHumanVerification: Boolean(organizationProfile?.enableHumanVerification)
    },
    onSubmit: async (values, { setSubmitting }) => {
      customDispatch({
        action: updateOrganizationProfile,
        actionParameters: { ...organizationProfile, ...values },
        onFinally: () => {
          setSubmitting(false);
          formik.resetForm();
        }
      });
    }
  });

  const { values, setFieldValue, handleSubmit } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form id="relay" noValidate onSubmit={handleSubmit}>
          <SettingsCard
            formik={formik}
            isGetLoading={loading.getOrganizationProfile}
            isUpdateLoading={loading.organizationProfile}
            switchLabel={translator.humanVerification()}
            switchChecked={Boolean(values.enableHumanVerification)}
            switchOnChange={(e) => setFieldValue('enableHumanVerification', e.target.checked)}
            descriptionParagraphs={[
              translator.humanVerificationDescription1(),
              translator.humanVerificationDescription2(),
              <TranslationWithHtml
                rawText
                key={'coudflare-turnstile-documentatioin'}
                translation={translator.turnstileOverview(
                  'https://www.cloudflare.com/products/turnstile/',
                  `"color: ${theme.palette.primary.main};"`
                )}
              />
            ]}
            access="ManagementEdit"
          />
        </Form>
      </FormikProvider>
    </>
  );
};

export default HumanVerification;
