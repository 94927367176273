import { ReactNode } from 'react';
import { Container, Alert, AlertTitle, styled } from '@mui/material';
import useAuth from '../hooks/useAuth';
import { RootState, useSelector } from 'redux/store';
import { Access, accessMap, CLAIMS } from '../@types/claims';
import SuperAdminIcon from 'pages/admin/components/SuperAdminIcon';
import BetaIcon from 'pages/admin/components/BetaIcon';

// ----------------------------------------------------------------------

export enum ClaimTypePosition {
  RIGHT_INLINE = 'right_inline',
  TOP_RIGHT = 'top_right'
}

type ClaimBasedGuardProp = {
  accessType?: Access;
  accessibleClaims?: string[];
  children: ReactNode | string;
  displayDeniedMessage?: boolean;
  deniedMessage?: JSX.Element;
  ignoreToggledClaims?: boolean;
  claimTypePosition?: ClaimTypePosition;
  display?: boolean;
};

const removeToggledClaims = (claimsToIgnore: string[], allClaims: string[]) => {
  let newClaims = [...allClaims];
  claimsToIgnore.forEach((claim) => {
    const indexToRemove = newClaims.indexOf(claim);
    if (indexToRemove >= 0) {
      newClaims.splice(indexToRemove, 1);
    }
  });
  return newClaims;
};

export const useCurrentRole = (ignoreToggledClaims?: boolean) => {
  const { claimsToIgnore, extraClaims } = useSelector((state: RootState) => state.admin);
  // Logic here to get current user role
  const { user } = useAuth();
  const userClaims: string[] = user?.claims ? user.claims : [];
  const allClaims = userClaims.concat(extraClaims);
  return ignoreToggledClaims
    ? allClaims
    : removeToggledClaims(claimsToIgnore || [], allClaims || []);
};

const doesClaimsGiveAccess = (claims: string[] | undefined, currentClaims: string[]) => {
  if (claims === undefined) return true;

  return currentClaims === undefined || claims.find((claim) => currentClaims.includes(claim));
};

export function isAccessible(claims: string[] | undefined, currentClaims: string[]) {
  const access = doesClaimsGiveAccess(claims, currentClaims);
  return access;
}

export const useHasAccess = (claims: string[], ignoreToggledClaims?: boolean) => {
  const currentClaims = useCurrentRole(ignoreToggledClaims);
  return doesClaimsGiveAccess(claims, currentClaims);
};

export const getClaimsFromAccessType = (accessType?: Access) => {
  return (accessType && accessMap.get(accessType)) || [];
};

export default function ClaimBasedGuard({
  accessType,
  accessibleClaims,
  children,
  displayDeniedMessage = true,
  deniedMessage,
  ignoreToggledClaims,
  claimTypePosition,
  display = true
}: ClaimBasedGuardProp) {
  if (!accessibleClaims && accessType) {
    accessibleClaims = getClaimsFromAccessType(accessType);
  }
  const currentClaims = useCurrentRole(ignoreToggledClaims);
  const access = doesClaimsGiveAccess(accessibleClaims, currentClaims);

  if (!access) {
    return (
      <>
        {displayDeniedMessage && (
          <>
            {deniedMessage || (
              <Container>
                <Alert severity="error">
                  <AlertTitle>Permission Denied</AlertTitle>
                  You do not have permission to access this page
                </Alert>
              </Container>
            )}
          </>
        )}
      </>
    );
  }

  const displaySuperAdminIcon =
    accessibleClaims?.includes(CLAIMS.management.superadmin) &&
    currentClaims?.includes(CLAIMS.management.superadmin);
  const displayBetaIcon =
    accessibleClaims?.includes(CLAIMS.beta) && currentClaims?.includes(CLAIMS.beta);

  return (
    <>
      {display && (
        <Wrapper fullwidth={claimTypePosition ? 'false' : 'true'}>
          {children}
          {claimTypePosition && (
            <ClaimTypeWrapper position={claimTypePosition}>
              {displaySuperAdminIcon && <SuperAdminIcon />}
              {displayBetaIcon && <BetaIcon />}
            </ClaimTypeWrapper>
          )}
        </Wrapper>
      )}
    </>
  );
}

const Wrapper = styled('div')(({ fullwidth }: { fullwidth: string }) => ({
  position: 'relative',
  width: fullwidth === 'true' ? '100%' : 'max-content'
}));

const ClaimTypeWrapper = styled('div')(({ position }: { position?: ClaimTypePosition }) => ({
  visibility: position ? 'visible' : 'hidden',
  position: 'absolute',
  top: position === ClaimTypePosition.TOP_RIGHT ? 0 : '50%',
  right: 0,
  transform:
    position === ClaimTypePosition.TOP_RIGHT ? 'translate(50%, -50%)' : 'translate(100%, -50%)',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: position === ClaimTypePosition.RIGHT_INLINE ? '.2rem' : 0
}));
