import { Box, Stack, Typography } from '@mui/material';
import OrganisationTableSelect from './OrganisationTableSelect';
import { Organisation } from '../../../@types/organization';

type SelectOrganizationProps = {
  organisations: Organisation[];
};
const SelectOrganization = ({ organisations }: SelectOrganizationProps) => {
  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            Select organisation
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            You are associated with multiple organizations. Select the desired one to log in.
          </Typography>
        </Box>
      </Stack>

      <OrganisationTableSelect organisations={organisations} />
    </>
  );
};

export default SelectOrganization;
