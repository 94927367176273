import { Integration, IntegrationType } from './integrations';

export type OrderDataState = {
  isLoading: boolean;
  isDeleteLoading: boolean;
  orderData: OrderData;
  isOrderLoading: boolean;
  order?: BaseOrder;
  isIntegrationEnabled: {
    [key in IntegrationType]?: boolean;
  };
};

export type OrderData = {
  count: number;
  orders: BaseOrder[];
};

export type BaseOrder = {
  active: boolean;
  renewable: boolean;
  description: string;
  notes: string;
  id: string;
  licenseId: string;
  paymentIntervalMonths: number;
  usageIntervalMonths: number;
  startedAt: string;
  finishedAt: string | null;
};

export type SurveyOrder = BaseOrder & {
  emailQuota: number;
  smsQuota: number;
  surveyQuota: number;
  emailUsage: number;
  smsUsage: number;
  surveyOrderType: SurveyOrderType;
  tier1Usage: number;
  tier2Usage: number;
  tier3Usage: number;
  tier4Usage: number;
};

export enum SurveyOrderType {
  BaseOrder = 0,
  CreditOrder = 1,
  OneOffOrder = 2
}

export type ProductOrder = BaseOrder & {
  integration: Integration | null;
  integrationId: number;
  amount: number;
};

export enum BillableHoursType {
  Support = 0,
  Onboarding = 1,
  Project = 2
}

export type BillableHoursOrder = BaseOrder & {
  hoursQuota: number;
  hoursUsage: number;
  billableHoursType: BillableHoursType;
};
