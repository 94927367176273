import React, { ForwardRefRenderFunction, forwardRef } from 'react';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Button, ButtonProps } from '@mui/material';
import usePreventSpamming from './hooks/usePreventSpamming';

type CustomButtonProps = {
  msDelay?: number;
};
const CustomButton = forwardRef(
  <C extends React.ElementType>(
    { msDelay = 500, ...rest }: ButtonProps<C, { component?: C }> & CustomButtonProps,
    ref: React.Ref<any>
  ) => {
    const { isClickAllowed, handleDisableOnClick } = usePreventSpamming({ msDelay: msDelay });

    const onClick = (event: any) => {
      if (!isClickAllowed) {
        return;
      }
      //handle disable on click
      handleDisableOnClick();
      //continue with prop onClick logic
      rest.onClick?.(event);
    };

    return (
      <Button
        {...rest}
        ref={ref}
        disabled={rest.disabled || !isClickAllowed}
        onClick={rest.onClick ? onClick : undefined}
      />
    );
  }
);

export default CustomButton;

type CustomLoadingButtonComponentProps = CustomButtonProps & LoadingButtonProps;
const CustomLoadingButtonComponent: ForwardRefRenderFunction<
  HTMLButtonElement,
  CustomLoadingButtonComponentProps
> = ({ msDelay = 500, ...rest }, ref) => {
  const { isClickAllowed, handleDisableOnClick } = usePreventSpamming({ msDelay: msDelay });

  const onClick = (event: any) => {
    if (!isClickAllowed) {
      return;
    }

    //handle disable on click
    handleDisableOnClick();
    //continue with prop onClick logic
    rest.onClick?.(event);
  };

  return (
    <LoadingButton
      ref={ref}
      {...rest}
      disabled={rest.disabled || !isClickAllowed}
      onClick={rest.onClick ? onClick : undefined}
    />
  );
};

export const CustomLoadingButton = React.forwardRef(CustomLoadingButtonComponent);
