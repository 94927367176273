import { FormControl, InputLabel, MenuItem } from '@mui/material';
import { Select } from '@mui/material';
import { AuthenticationType, Relay as RelayObject, RelayType } from '../../../@types/relay';
import CustomTextField from 'components/util/CustomTextField';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import useCustomDispatch from 'redux/dispatch';
import { useEffect } from 'react';
import { getRelaySettings, setRelaySettings } from 'redux/slices/relay';
import { RootState, useSelector } from 'redux/store';
import { nullToEmptyString } from 'utils/nullToEmptyString';
import SettingsCard from 'components/settings/SettingsCard';
import { useTranslator } from 'translation/useTranslator';

const Relay = () => {
  const translator = useTranslator();
  const customDispatch = useCustomDispatch();
  const { relaySettings, isLoading } = useSelector((state: RootState) => state.relay);

  useEffect(() => {
    customDispatch({
      action: getRelaySettings,
      disableSuccessMessage: true
    });
  }, [customDispatch]);

  const DataRetentionSchema = Yup.object().shape({
    relayType: Yup.mixed<RelayType>()
      .oneOf(Object.values(RelayType) as RelayType[])
      .required(),
    externalWebHookUrl: Yup.string().when('relayType', {
      is: RelayType.External,
      then: Yup.string()
        .url(translator.mustBeValidUrl())
        .required(translator.externalWebHookUrlRequired()),
      otherwise: Yup.string().notRequired()
    }),
    apiAuthentication: Yup.object().shape({
      authenticationType: Yup.mixed<AuthenticationType>()
        .oneOf(Object.values(AuthenticationType) as AuthenticationType[])
        .when('$relayType', {
          // Using context to access parent fields
          is: RelayType.External,
          then: Yup.mixed().required(translator.authenticationTypeRequired())
        }),
      basicAuthUser: Yup.string().when('authenticationType', {
        is: AuthenticationType.Basic,
        then: Yup.string().required(translator.usernameRequired())
      }),
      basicAuthPassword: Yup.string().when('authenticationType', {
        is: AuthenticationType.Basic,
        then: Yup.string().required(translator.passwordRequired())
      }),
      bearerToken: Yup.string().when('authenticationType', {
        is: AuthenticationType.ApiKey,
        then: Yup.string().required(translator.apiKeyRequired())
      }),
      oAuthClientId: Yup.string().when('authenticationType', {
        is: AuthenticationType.OAuth,
        then: Yup.string().required(translator.clientIdRequired())
      }),
      oAuthClientSecret: Yup.string().when('authenticationType', {
        is: AuthenticationType.OAuth,
        then: Yup.string().required(translator.clientSecretRequired())
      }),
      oAuthTokenEndpoint: Yup.string().when('authenticationType', {
        is: AuthenticationType.OAuth,
        then: Yup.string().required(translator.tokenEndpointRequired())
      }),
      oAuthResource: Yup.string().when('authenticationType', {
        is: AuthenticationType.OAuth,
        then: Yup.string().required(translator.authenticationSourceRequired())
      })
    })
  });

  const formik = useFormik<RelayObject>({
    enableReinitialize: true,
    initialValues: {
      ...nullToEmptyString(relaySettings),
      apiAuthentication: { ...nullToEmptyString(relaySettings.apiAuthentication) }
    },
    validationSchema: DataRetentionSchema,
    onSubmit: async (values, { setSubmitting }) => {
      // Initialize all optional fields to null
      let sanitizedValues: RelayObject = {
        ...values,
        apiAuthentication: {
          authenticationType: values.apiAuthentication.authenticationType,
          basicAuthUser: null,
          basicAuthPassword: null,
          bearerToken: null,
          oAuthClientId: null,
          oAuthClientSecret: null,
          oAuthTokenEndpoint: null,
          oAuthResource: null
        }
      };

      switch (values.apiAuthentication.authenticationType) {
        case AuthenticationType.Basic:
          sanitizedValues.apiAuthentication.basicAuthUser = values.apiAuthentication.basicAuthUser;
          sanitizedValues.apiAuthentication.basicAuthPassword =
            values.apiAuthentication.basicAuthPassword;
          break;

        case AuthenticationType.Bearer:
        case AuthenticationType.ApiKey:
          sanitizedValues.apiAuthentication.bearerToken = values.apiAuthentication.bearerToken;
          break;

        case AuthenticationType.OAuth:
          sanitizedValues.apiAuthentication.oAuthClientId = values.apiAuthentication.oAuthClientId;
          sanitizedValues.apiAuthentication.oAuthClientSecret =
            values.apiAuthentication.oAuthClientSecret;
          sanitizedValues.apiAuthentication.oAuthTokenEndpoint =
            values.apiAuthentication.oAuthTokenEndpoint;
          sanitizedValues.apiAuthentication.oAuthResource = values.apiAuthentication.oAuthResource;
          break;

        // No need for AuthenticationType.None case since all optional fields are already null

        default:
          break;
      }

      customDispatch({
        action: setRelaySettings,
        actionParameters: { relaySettings: sanitizedValues },
        onFinally: () => {
          setSubmitting(false);
        }
      });
    }
  });

  const { errors, touched, values, getFieldProps, setFieldValue, handleSubmit } = formik;

  return (
    <FormikProvider value={formik}>
      <Form id="dataPrivacy" noValidate onSubmit={handleSubmit}>
        <SettingsCard
          formik={formik}
          isGetLoading={isLoading.getRelaySettings}
          isUpdateLoading={isLoading.setRelaySettings}
          switchLabel={translator.relaySwitchLabel()}
          switchChecked={Boolean(values.relayType !== RelayType.None)}
          switchOnChange={(e) => {
            if (e.target.checked) {
              setFieldValue('relayType', RelayType.External);
              setFieldValue('apiAuthentication.authenticationType', AuthenticationType.Basic);
            } else {
              setFieldValue('relayType', RelayType.None);
              setFieldValue('apiAuthentication.authenticationType', AuthenticationType.None);
            }
          }}
          descriptionParagraphs={[translator.relayDescription()]}
          additionalFormFieldsWhenSwitchIsOn={[
            <FormControl key="relayType" fullWidth>
              <InputLabel id="relayType">{translator.type()}</InputLabel>
              <Select
                fullWidth
                value={values.relayType}
                onChange={(event: any) => {
                  setFieldValue('relayType', event.target.value);
                }}
                labelId="relayType"
                label={translator.type()}
              >
                <MenuItem value={RelayType.External}>External</MenuItem>
              </Select>
            </FormControl>,
            <CustomTextField
              key="externalWebHookUrl"
              fullWidth
              label={translator.webhookUrl()}
              {...getFieldProps('externalWebHookUrl')}
              required
              error={Boolean(touched.externalWebHookUrl && errors.externalWebHookUrl)}
              helperText={touched.externalWebHookUrl && errors.externalWebHookUrl}
              // Use below when testing of relay setup is available
              /*
              endAdornments={[
                <Tooltip
                  title={dirty ? 'Please save your changes to test this webhook URL' : ''}
                  key="testExternalWebhookUrl"
                >
                  <div>
                    <TestEndpointButton
                      disabled={dirty}
                      title="Test external webhook URL"
                      dispatchProps={{ action: undefined }}
                    />
                  </div>
                </Tooltip>
              ]}
              */
            />,
            <FormControl fullWidth key="authenticationType">
              <InputLabel id="authenticationType">{translator.authentication()}</InputLabel>
              <Select
                fullWidth
                value={values.apiAuthentication.authenticationType}
                onChange={(event: any) => {
                  setFieldValue('apiAuthentication.authenticationType', event.target.value);
                }}
                labelId="authenticationType"
                label={translator.authentication()}
              >
                <MenuItem value={AuthenticationType.None}>{translator.none()}</MenuItem>
                <MenuItem value={AuthenticationType.Basic}>{translator.basic()}</MenuItem>
                <MenuItem value={AuthenticationType.ApiKey}>{translator.apiKey()}</MenuItem>
                <MenuItem value={AuthenticationType.OAuth}>{translator.oAuth()}</MenuItem>
              </Select>
            </FormControl>,

            values.apiAuthentication.authenticationType === AuthenticationType.Basic && (
              <CustomTextField
                fullWidth
                label={translator.user()}
                {...getFieldProps('apiAuthentication.basicAuthUser')}
                required
                error={Boolean(
                  touched.apiAuthentication?.basicAuthUser &&
                    errors.apiAuthentication?.basicAuthUser
                )}
                helperText={
                  touched.apiAuthentication?.basicAuthUser &&
                  errors.apiAuthentication?.basicAuthUser
                }
              />
            ),

            values.apiAuthentication.authenticationType === AuthenticationType.Basic && (
              <CustomTextField
                fullWidth
                enableShowHide
                label={translator.password()}
                {...getFieldProps('apiAuthentication.basicAuthPassword')}
                required
                error={Boolean(
                  touched.apiAuthentication?.basicAuthPassword &&
                    errors.apiAuthentication?.basicAuthPassword
                )}
                helperText={
                  touched.apiAuthentication?.basicAuthPassword &&
                  errors.apiAuthentication?.basicAuthPassword
                }
              />
            ),

            values.apiAuthentication.authenticationType === AuthenticationType.ApiKey && (
              <CustomTextField
                fullWidth
                label={translator.apiKey()}
                {...getFieldProps('apiAuthentication.bearerToken')}
                required
                error={Boolean(
                  touched.apiAuthentication?.bearerToken && errors.apiAuthentication?.bearerToken
                )}
                helperText={
                  touched.apiAuthentication?.bearerToken && errors.apiAuthentication?.bearerToken
                }
              />
            ),

            values.apiAuthentication.authenticationType === AuthenticationType.OAuth && (
              <CustomTextField
                fullWidth
                label={translator.clientId()}
                {...getFieldProps('apiAuthentication.oAuthClientId')}
                required
                error={Boolean(
                  touched.apiAuthentication?.oAuthClientId &&
                    errors.apiAuthentication?.oAuthClientId
                )}
                helperText={
                  touched.apiAuthentication?.oAuthClientId &&
                  errors.apiAuthentication?.oAuthClientId
                }
              />
            ),
            values.apiAuthentication.authenticationType === AuthenticationType.OAuth && (
              <CustomTextField
                fullWidth
                enableShowHide
                label={translator.clientSecret()}
                {...getFieldProps('apiAuthentication.oAuthClientSecret')}
                required
                error={Boolean(
                  touched.apiAuthentication?.oAuthClientSecret &&
                    errors.apiAuthentication?.oAuthClientSecret
                )}
                helperText={
                  touched.apiAuthentication?.oAuthClientSecret &&
                  errors.apiAuthentication?.oAuthClientSecret
                }
              />
            ),
            values.apiAuthentication.authenticationType === AuthenticationType.OAuth && (
              <CustomTextField
                fullWidth
                label={translator.tokenEndpointUrl()}
                {...getFieldProps('apiAuthentication.oAuthTokenEndpoint')}
                required
                error={Boolean(
                  touched.apiAuthentication?.oAuthTokenEndpoint &&
                    errors.apiAuthentication?.oAuthTokenEndpoint
                )}
                helperText={
                  touched.apiAuthentication?.oAuthTokenEndpoint &&
                  errors.apiAuthentication?.oAuthTokenEndpoint
                }
              />
            ),
            values.apiAuthentication.authenticationType === AuthenticationType.OAuth && (
              <CustomTextField
                fullWidth
                label={translator.authorizationSource()}
                {...getFieldProps('apiAuthentication.oAuthResource')}
                required
                error={Boolean(
                  touched.apiAuthentication?.oAuthResource &&
                    errors.apiAuthentication?.oAuthResource
                )}
                helperText={
                  touched.apiAuthentication?.oAuthResource &&
                  errors.apiAuthentication?.oAuthResource
                }
              />
            )
          ]}
          access="ManagementEdit"
        />
      </Form>
    </FormikProvider>
  );
};

export default Relay;
