import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useState } from 'react';
import { IFileWithMeta, StatusValue } from 'react-dropzone-uploader';
import { addLogo } from 'redux/slices/organization';
import { RootState, useSelector } from 'redux/store';
import 'react-dropzone-uploader/dist/styles.css';
import useCustomDispatch, { CustomDispachActionPromise } from 'redux/dispatch';
import CustomButton, { CustomLoadingButton } from 'components/util/CustomButton';
import CustomDropzone from 'components/CustomDropzone';

type AddLogoDialogProps = {
  display: boolean;
  hideModal: () => void;
  uploadLogo?: (file: any) => () => Promise<any | CustomDispachActionPromise>;
};
const AddLogoDialog = (props: AddLogoDialogProps) => {
  const customDispatch = useCustomDispatch();
  const [file, setFile] = useState<IFileWithMeta | null>(null);
  const [isFileTooBig, setIsFileTooBig] = useState(false);
  const { isLogoLoading } = useSelector((state: RootState) => state.organization);
  const onValidateDropzone = (file: IFileWithMeta | null) => {
    new Promise(async (resolve) => {
      setFile(file);
      resolve(true);
    });
  };

  const onChangeStatusDropzone = (_: IFileWithMeta, status: StatusValue) => {
    if (status === 'removed') {
      onValidateDropzone(null);
    }
    setIsFileTooBig(status === 'error_file_size');
  };

  const onUploadLogo = async () => {
    customDispatch({
      action: props.uploadLogo ? props.uploadLogo : addLogo,
      actionParameters: file?.file,
      onSuccess: () => {
        setFile(null);
        props.hideModal();
      }
    });
  };

  return (
    <Dialog open={props.display} onClose={props.hideModal}>
      <DialogTitle>Upload new logo</DialogTitle>
      <DialogContent sx={{ width: '30rem' }}>
        <Box p={1}>
          <CustomDropzone
            inputContent={
              <Typography key="dropzoneInputContent">Drag File or Click to Browse</Typography>
            }
            accept="image/*"
            onChangeStatus={onChangeStatusDropzone}
            validate={onValidateDropzone}
            maxFiles={1}
            maxSizeBytes={512000}
          />
          {isFileTooBig && (
            <Typography variant="caption" color="error">
              Max file size is 500KB
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <CustomButton color="primary" onClick={props.hideModal}>
          Cancel
        </CustomButton>
        <CustomLoadingButton
          disabled={!file}
          loading={isLogoLoading}
          color="primary"
          variant="contained"
          onClick={onUploadLogo}
        >
          Upload
        </CustomLoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddLogoDialog;
