import HoverLink from 'components/HoverLink';

type ContactNpsForHelpProps = {
  subject?: string;
};
const ContactNpsForHelp = ({ subject = '' }: ContactNpsForHelpProps) => {
  const subjectPrefix = subject ? 'Request: ' : '';
  const handleClickSendMail = () => {
    window.location.href = `mailto:cpk@npstoday.com?subject=${subjectPrefix}${subject}`;
  };

  return (
    <span>
      Please contact our Customer Success Manager with your request at{' '}
      <HoverLink onClick={handleClickSendMail}>cpk@npstoday.com</HoverLink>
    </span>
  );
};

export default ContactNpsForHelp;
