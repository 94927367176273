// material
import { Card, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import IVRDialog from './components/IVRDialog';
import IVRList from './components/IVRList';
// components
import Page from '../../components/util/Page';
// hooks
import { getCampaignsSummary } from '../../redux/slices/campaign';
import { deleteIvrMaps, getIvrMaps } from '../../redux/slices/organization';
// redux
import { RootState, useSelector } from '../../redux/store';
import { getIVRProviders } from 'redux/slices/ivr';
import AreYouSureModal from 'components/util/AreYouSureModal';
import TestIvrDialog from './components/TestIvrDialog';
import useCustomDispatch from 'redux/dispatch';
import CustomButton from 'components/util/CustomButton';
import Icon from 'components/icons/Icon';
import { capitalCase } from 'change-case';
import { Box } from '@mui/system';
import IVRSettings from './components/IVRSettings';
import { useNavigate, useParams } from 'react-router';
import { PATH_DASHBOARD } from 'routes/paths';
// routes

// ----------------------------------------------------------------------

export enum IVRTab {
  CONNECTIONS = 'connections',
  SETTINGS = 'settings'
}

export default function IVR() {
  const customDispatch = useCustomDispatch();
  const { ivrMaps, loading } = useSelector((state: RootState) => state.organization);
  const { campaignsSummary } = useSelector((state: RootState) => state.campaign);
  const [selectionModel, setSelectionModel] = useState<number[]>([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [ivrIdToEdit, setIvrIdToEdit] = useState<number | null>(null);

  const { tab = IVRTab.CONNECTIONS } = useParams();
  const navigate = useNavigate();

  const onTabChange = (newTab: IVRTab) => {
    navigate(PATH_DASHBOARD.ivr.tab.replace(':tab', newTab));
  };

  const TABS = [
    {
      value: IVRTab.CONNECTIONS,
      icon: <Icon type="connection" />,
      component: (
        <Card sx={{ p: 3 }}>
          <IVRList
            ivrData={ivrMaps}
            loading={loading.ivr}
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
            setIvrIdToEdit={setIvrIdToEdit}
          />
        </Card>
      )
    },
    {
      value: IVRTab.SETTINGS,
      icon: <Icon type="settings" />,
      component: <IVRSettings />
    }
  ];

  const selectedTab = TABS.find((aTab) => aTab.value === tab);

  useEffect(() => {
    customDispatch({ action: getIvrMaps, disableSuccessMessage: true });
    customDispatch({ action: getCampaignsSummary, disableSuccessMessage: true });
    customDispatch({ action: getIVRProviders, disableSuccessMessage: true });
  }, [customDispatch]);

  const [displayAreYouSureModal, setDisplayAreYouSureModal] = useState(false);
  const idsToDelete = ivrIdToEdit !== null ? [ivrIdToEdit] : selectionModel;

  const onDeleteIvrMaps = async () => {
    customDispatch({
      action: deleteIvrMaps,
      actionParameters: idsToDelete,
      onSuccess: () => {
        setDisplayAreYouSureModal(false);
        setIvrIdToEdit(null);
        customDispatch({ action: getIvrMaps, disableSuccessMessage: true });
      }
    });
  };

  return (
    <Page
      title="IVR"
      stickyHeaderContent={[
        [
          selectedTab?.value === IVRTab.CONNECTIONS && selectionModel.length >= 1 ? (
            <div key="deleteIvr">
              <CustomButton
                variant={'contained'}
                color={'error'}
                onClick={() => setDisplayAreYouSureModal(true)}
              >
                Delete {selectionModel.length >= 2 && selectionModel.length} IVR
                {selectionModel.length >= 2 && 's'}
              </CustomButton>
            </div>
          ) : undefined,
          selectedTab?.value === IVRTab.CONNECTIONS && (
            <CustomButton key="addIvr" variant={'contained'} onClick={() => setOpenAddDialog(true)}>
              Add IVR
            </CustomButton>
          )
        ]
      ]}
    >
      {!loading.getIvrMaps && (
        <>
          <div style={{ display: 'inline-grid' }}>
            <Tabs
              value={selectedTab?.value}
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
              onChange={(_, value) => onTabChange(value as IVRTab)}
            >
              {TABS.map((tab) => (
                <Tab
                  disableRipple
                  key={tab.value}
                  label={capitalCase(tab.value)}
                  icon={tab.icon}
                  value={tab.value}
                />
              ))}
            </Tabs>
          </div>

          <Box sx={{ mb: 1 }} />

          {TABS.map((tab) => {
            const isMatched = tab.value === selectedTab?.value;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          })}
        </>
      )}
      <IVRDialog
        ivrIdToEdit={ivrIdToEdit}
        campaigns={campaignsSummary}
        open={ivrIdToEdit !== null || openAddDialog}
        onClose={() => {
          setIvrIdToEdit(null);
          setOpenAddDialog(false);
        }}
        onDelete={() => setDisplayAreYouSureModal(true)}
      />

      <AreYouSureModal
        type="Delete"
        display={displayAreYouSureModal}
        hideModal={() => setDisplayAreYouSureModal(false)}
        title={`Delete IVR connection${idsToDelete?.length > 1 ? 's' : ''}`}
        description={`You are about to delete ${idsToDelete?.length} IVR connection${
          idsToDelete?.length > 1 ? 's' : ''
        }. This action cannot be undone`}
        onContinue={onDeleteIvrMaps}
      />

      <TestIvrDialog />
    </Page>
  );
}
