import { Typography, TypographyProps } from '@mui/material';
import { FunctionComponent } from 'react';

interface TranslationWithHtmlProps extends TypographyProps {
  translation: string;
  rawText?: boolean;
}

/**
 * Displays a translation string containing HTML by rendering a Typography component with a span tag as a child.
 * The span tag uses dangerouslySetInnerHtml to render the HTML contained within the translation string.
 * Any valid Typography props will be passed to the underlying Typography component.
 *
 * @component
 * @example
 * <TranslationWithHtml translation={translator.exampleTranslation()} />
 *
 * @param {string} translation - The string containing HTML to display.
 * @param {boolean} rawText - Display the HTML as raw text instead of using the Typography component.
 * @returns {JSX.Element} JSX element representing the TranslationWithHtml.
 */

export const TranslationWithHtml: FunctionComponent<TranslationWithHtmlProps> = ({
  translation,
  rawText,
  ...props
}) => {
  const span = (
    <span
      dangerouslySetInnerHTML={{
        __html: translation
      }}
    />
  );

  return rawText ? span : <Typography {...props}>{span}</Typography>;
};
