import { useMemo, useEffect } from 'react';
import { Avatar } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid';
import {
  CustomDataGrid,
  CustomGridToolbar,
  CustomLoadingOverlay,
  CustomPagination
} from 'components/datagrid/Custom';
import MoreMenuButton from 'components/util/MoreMenuButton';
import DisabledIcon from 'components/util/Status/DisabledIcon';
import EnabledIcon from 'components/util/Status/EnabledIcon';
import { DataGridKey } from 'redux/slices/datagrid';
import { RootState, useSelector } from 'redux/store';
import { PATH_DASHBOARD } from 'routes/paths';
import { useNavigate } from 'react-router';
import { NpsNotification } from '../../@types/npsNotification';
import useCustomDispatch from 'redux/dispatch';
import {
  cloneNpsNotiofication,
  deleteNpsNotification,
  getNpsNotifications
} from 'redux/slices/npsNotifications';

let thumbSize = 48;
const DATA_GRID_KEY = DataGridKey.NpsNotifications;

const NpsNotifications = () => {
  const customDispatch = useCustomDispatch();
  const { npsNotifications, isLoading } = useSelector((state: RootState) => state.npsNotifications);
  const navigate = useNavigate();
  const { density } = useSelector((state: RootState) => state.datagrid);

  useEffect(() => {
    customDispatch({
      action: getNpsNotifications,
      disableSuccessMessage: true
    });
  }, [customDispatch]);

  useEffect(() => {
    if (density[DATA_GRID_KEY] === 'compact') {
      thumbSize = 30;
    } else if (density[DATA_GRID_KEY] === 'standard') {
      thumbSize = 46;
    } else if (density[DATA_GRID_KEY] === 'comfortable') {
      thumbSize = 62;
    }
  }, [density]);

  const handleNaviagteToNpsNotification = useMemo(
    () => (notificationId: string, isDefault: boolean) => {
      navigate(
        `${PATH_DASHBOARD.npsNotifications.edit.replace(
          ':id',
          notificationId ?? ''
        )}?isDefault=${isDefault}`
      );
    },
    [navigate]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'id'
      },
      {
        field: 'logoUrl',
        headerName: 'Logo',
        headerAlign: 'center',
        width: 120,
        align: 'center',
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          const url = params.row.logoUrl as string;
          if (url) {
            return (
              <Avatar
                imgProps={{ sx: { objectFit: 'contain' } }}
                variant="square"
                src={url}
                sx={{ width: thumbSize, height: thumbSize }}
              />
            );
          }
        }
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 1
      },
      {
        field: 'subject',
        headerName: 'Subject',
        flex: 1
      },
      {
        field: 'previewText',
        headerName: 'Preview Text',
        flex: 1
      },
      {
        field: 'isDefault',
        headerName: 'Editable',
        type: 'boolean',
        flex: 1,
        renderCell: (params) => {
          const isForceAnonymous = params.row.isDefault as boolean;
          return !isForceAnonymous ? (
            <EnabledIcon tooltip="Custom notification" />
          ) : (
            <DisabledIcon tooltip="Managed by nps.today" />
          );
        }
      },
      {
        field: 'edit',
        headerName: '',
        editable: false,
        disableColumnMenu: true,
        hideable: false,
        align: 'center',
        hideSortIcons: true,
        filterable: false,
        sortable: false,
        renderCell: (cellValues) => {
          const isDefault = cellValues.row.isDefault as boolean;
          const npsNotification = cellValues.row as NpsNotification;
          const notificationId = cellValues.id as string;
          return (
            <MoreMenuButton
              itemType="NPS Notification"
              onDisplay={
                isDefault
                  ? () => handleNaviagteToNpsNotification(notificationId, isDefault)
                  : undefined
              }
              onEdit={
                !isDefault
                  ? () => handleNaviagteToNpsNotification(notificationId, isDefault)
                  : undefined
              }
              onDelete={
                !isDefault
                  ? async () =>
                      customDispatch({
                        action: deleteNpsNotification,
                        actionParameters: { npsNotificationId: notificationId },
                        onSuccess: () => {
                          customDispatch({
                            action: getNpsNotifications,
                            disableSuccessMessage: true
                          });
                        }
                      })
                  : undefined
              }
              onClone={() => {
                customDispatch({
                  action: cloneNpsNotiofication,
                  actionParameters: { npsNotification: npsNotification },
                  disableSuccessMessage: true
                });

                navigate(PATH_DASHBOARD.npsNotifications.new);
              }}
            />
          );
        }
      }
    ],
    [navigate, customDispatch, handleNaviagteToNpsNotification]
  );

  return (
    <Box>
      <CustomDataGrid
        dataGridKey={DATA_GRID_KEY}
        autoHeight
        loading={isLoading.getNpsNotifications}
        pageSize={25}
        pagination
        disableSelectionOnClick
        rows={npsNotifications}
        columns={columns}
        onCellClick={(params) => {
          const isDefault = params.row.isDefault as boolean;
          const notificationId = params.id as string;
          handleNaviagteToNpsNotification(notificationId, isDefault);
        }}
        components={{
          Toolbar: CustomGridToolbar,
          Pagination: CustomPagination,
          LoadingOverlay: CustomLoadingOverlay
        }}
      />
    </Box>
  );
};

export default NpsNotifications;
