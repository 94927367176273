import EditItemDialog from 'components/util/EditItemDialog';
import UnsavedChangesModal from 'components/util/UnsavedChangesModal';
import { Form, FormikProvider, useFormik } from 'formik';
import { useRef, useState } from 'react';
import { TextField, FormControlLabel, Checkbox, Chip, Typography, Divider } from '@mui/material';
import { NewOrganization } from '../../../@types/organization';
import * as Yup from 'yup';
import { TextFieldProps } from '@material-ui/core';
import { addOrganization, getOrganizations } from 'redux/slices/organization';
import useCustomDispatch from 'redux/dispatch';
import { RootState, useSelector } from 'redux/store';
import CustomTextField from 'components/util/CustomTextField';
import PhoneNumberInputField from 'components/util/PhoneNumberInputField';
import { useRegisterUnsavedFormChanges } from 'components/form/useRegisterUnsavedFormChanges';
import { IntegerInput } from 'components/util/input/IntegerInput';
import { useLicense } from 'hooks/useLicense';
import useDisplayMessage from 'components/messages/useMessage';
import getFormikValidateFunction from 'utils/getFormikValidateFunction';
import { getPhoneNumberYupTest } from 'utils/validation/isPhoneNumberValid';

type AddOrganizationDialogProps = {
  display: boolean;
  close: () => void;
  isLinkedOrg?: boolean;
};
const AddOrganizationDialog = ({ display, close, isLinkedOrg }: AddOrganizationDialogProps) => {
  const customDispatch = useCustomDispatch();
  const displayMessage = useDisplayMessage();
  const { license, createNewLicense, editLicense, licenseLoading } = useLicense();
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] = useState(false);
  const { loading, organizationProfile } = useSelector((state: RootState) => state.organization);

  const UpdateUserSchema = Yup.object().shape({
    contactEmail: Yup.string().email(),
    contactPassword: Yup.string()
      .min(8, 'Password must be at least 8 characters long')
      .required('Password is required'),
    contactPasswordConfirm: Yup.string()
      .min(8, 'Password must be at least 8 characters long')
      .required('Password is required'),
    organizationName: Yup.string().required('Organization name is required'),
    displayName: Yup.string().required('Display name is required'),
    organizationPhone: Yup.string().test(getPhoneNumberYupTest()),
    contactPhone: Yup.string().test(getPhoneNumberYupTest()),
    invoiceEmail: Yup.string().email(),
    smsEnabled: Yup.boolean(),
    smsSenders: Yup.array().when('smsEnabled', {
      is: true,
      then: Yup.array().min(1, 'At least one SMS sender is required')
    }),
    vat: Yup.string().required('VAT is required'),
    countryCode: Yup.string().required('Country code is required'),
    currency: Yup.string().required('Currency is required'),
    seats: Yup.number().required('Seats is required')
  });

  const formik = useFormik<NewOrganization>({
    enableReinitialize: true,
    initialValues: {
      contactEmail: '',
      contactPassword: '',
      contactPasswordConfirm: '',
      organizationName: '',
      displayName: '',
      organizationPhone: (isLinkedOrg && organizationProfile.organizationPhone) || '',
      contactPhone: (isLinkedOrg && organizationProfile.contactPhone) || '',
      contactName: (isLinkedOrg && organizationProfile.contactName) || '',
      invoiceEmail: (isLinkedOrg && organizationProfile.invoiceEmail) || '',
      website: (isLinkedOrg && organizationProfile.website) || '',
      cvrNumber: (isLinkedOrg && organizationProfile.cvr) || '',
      smsEnabled: false,
      smsSenders: [],
      seats: (isLinkedOrg && license?.seats) || 0,
      vat: (isLinkedOrg && license?.customerVat) || '',
      currency: (isLinkedOrg && license?.currency) || '',
      countryCode: (isLinkedOrg && license?.countryIsoCode) || '',
      customerName: (isLinkedOrg && license?.customerName) || '',
      licenseId: isLinkedOrg ? license?.id : undefined
    },
    validate: getFormikValidateFunction(UpdateUserSchema, {
      initialorganizationPhone: (isLinkedOrg && organizationProfile.organizationPhone) || '',
      initialcontactPhone: (isLinkedOrg && organizationProfile.contactPhone) || ''
    }),
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      setSubmitting(true);
      customDispatch({
        action: addOrganization,
        actionParameters: { ...values },
        onSuccess: async ({ result }) => {
          if (result?.data) {
            const newOrgId = result.data?.id;
            try {
              if (isLinkedOrg && license) {
                await editLicense({ license, orgId: newOrgId });
              } else {
                await createNewLicense({
                  license: {
                    customerName: values.customerName,
                    seats: values.seats,
                    currency: values.currency,
                    countryIsoCode: values.countryCode,
                    customerVat: values.vat
                  },
                  orgId: newOrgId
                });
              }
            } catch (error: any) {
              //if license fails, tell user to manually add a license to the new created org
              displayMessage.info({
                message: {
                  title: `The new organzation was created, but the license failed to be created`,
                  description: 'Please manually add a license to the new organization'
                },
                autoHideDuration: 20000
              });
            }
          }

          onClose();

          //Update orgs
          customDispatch({ action: getOrganizations, disableSuccessMessage: true });
        },
        onFinally: () => {
          setSubmitting(false);
        }
      });
    }
  });

  const {
    submitForm,
    resetForm,
    errors,
    touched,
    dirty,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    values,
    setFieldValue
  } = formik;

  useRegisterUnsavedFormChanges(dirty);

  const onClose = () => {
    close();
    resetForm();
  };

  const smsSendersTextfieldRef = useRef<TextFieldProps>(null);
  const addSmsSender = (newSmsSender: string) => {
    if (newSmsSender.trim()) {
      const isSmsSenderNew = values.smsSenders.includes(newSmsSender);
      if (!isSmsSenderNew) {
        setFieldValue('smsSenders', [...values.smsSenders, newSmsSender]);
      }
    }
    if (smsSendersTextfieldRef.current) {
      smsSendersTextfieldRef.current.value = '';
    }
  };

  const onBlurSmsSendersInput = (event: any) => {
    const newSmsSender = event.target.value;
    addSmsSender(newSmsSender);
  };
  const onDeleteSmsSender = (index: number) => {
    const newSmsSenders = [...values.smsSenders];
    newSmsSenders.splice(index, 1);
    setFieldValue('smsSenders', newSmsSenders);
  };

  const onKeyDownSmsSendersInput = (event: any) => {
    if (event.key === 'Enter') {
      const newSmsSender = event.target.value;
      addSmsSender(newSmsSender);
    }

    //if backspace, and no current values, delete most resent smsSender
    if (event.key === 'Backspace') {
      const newSmsSender = event.target.value;
      if (!newSmsSender) {
        onDeleteSmsSender(values.smsSenders.length - 1);
      }
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <EditItemDialog
            itemType="organization"
            editable
            isOpen={display}
            title={isLinkedOrg ? 'Add new linked organization' : 'Add new organization'}
            editContent={[
              <>
                <TextField
                  fullWidth
                  label="Contact Email"
                  {...getFieldProps('contactEmail')}
                  required
                  error={Boolean(touched.contactEmail && errors.contactEmail)}
                  helperText={touched.contactEmail && errors.contactEmail}
                />
              </>,
              <>
                <CustomTextField
                  enableShowHide
                  fullWidth
                  label="Contact Password"
                  {...getFieldProps('contactPassword')}
                  required
                  error={Boolean(touched.contactPassword && errors.contactPassword)}
                  helperText={touched.contactPassword && errors.contactPassword}
                />
              </>,
              <>
                <CustomTextField
                  enableShowHide
                  fullWidth
                  label="Confirm Contact Password"
                  {...getFieldProps('contactPasswordConfirm')}
                  required
                  error={Boolean(touched.contactPasswordConfirm && errors.contactPasswordConfirm)}
                  helperText={touched.contactPasswordConfirm && errors.contactPasswordConfirm}
                />
              </>,
              <>
                <TextField
                  fullWidth
                  label="Organization Name"
                  {...getFieldProps('organizationName')}
                  required
                  error={Boolean(touched.organizationName && errors.organizationName)}
                  helperText={touched.organizationName && errors.organizationName}
                />
              </>,
              <>
                <TextField
                  fullWidth
                  label="Display Name"
                  {...getFieldProps('displayName')}
                  required
                  error={Boolean(touched.displayName && errors.displayName)}
                  helperText={touched.displayName && errors.displayName}
                />
              </>,
              <>
                <PhoneNumberInputField
                  fieldName="organizationPhone"
                  label="Organization Phone"
                  formik={formik}
                  initialValue={''}
                />
              </>,
              <>
                <PhoneNumberInputField
                  fieldName="contactPhone"
                  label="Contact Phone"
                  formik={formik}
                  initialValue={''}
                />
              </>,
              <>
                <TextField
                  fullWidth
                  label="Contact Name"
                  {...getFieldProps('contactName')}
                  error={Boolean(touched.contactName && errors.contactName)}
                  helperText={touched.contactName && errors.contactName}
                />
              </>,
              <>
                <TextField
                  fullWidth
                  label="Invoice Email"
                  {...getFieldProps('invoiceEmail')}
                  error={Boolean(touched.invoiceEmail && errors.invoiceEmail)}
                  helperText={touched.invoiceEmail && errors.invoiceEmail}
                />
              </>,
              <>
                <TextField
                  fullWidth
                  label="Website"
                  {...getFieldProps('website')}
                  error={Boolean(touched.website && errors.website)}
                  helperText={touched.website && errors.website}
                />
              </>,

              <>
                <TextField
                  fullWidth
                  label="CVR Number"
                  {...getFieldProps('cvrNumber')}
                  error={Boolean(touched.cvrNumber && errors.cvrNumber)}
                  helperText={touched.cvrNumber && errors.cvrNumber}
                />
              </>,
              <>
                <FormControlLabel
                  sx={{ height: '100%' }}
                  label="SMS Enabled"
                  control={
                    <Checkbox
                      inputProps={{ 'aria-label': 'Checkbox demo' }}
                      {...getFieldProps('smsEnabled')}
                    />
                  }
                />
              </>,
              <>
                {values.smsEnabled && (
                  <TextField
                    inputRef={smsSendersTextfieldRef}
                    fullWidth
                    label="SMS Senders"
                    onKeyDown={onKeyDownSmsSendersInput}
                    onBlur={onBlurSmsSendersInput}
                    defaultValue=""
                    InputProps={{
                      startAdornment:
                        values.smsSenders.length > 0 &&
                        values.smsSenders.map((smsSender: string, index: number) => (
                          <Chip
                            sx={{ marginRight: '.5rem' }}
                            key={index}
                            tabIndex={-1}
                            label={smsSender}
                            onDelete={() => onDeleteSmsSender(index)}
                          />
                        ))
                    }}
                    error={Boolean(touched.smsSenders && errors.smsSenders)}
                    helperText={touched.smsSenders && errors.smsSenders}
                  />
                )}
              </>,
              <Divider key={'license-info'}>
                <Typography sx={{ width: '100%' }} variant="subtitle1">
                  License information
                </Typography>
              </Divider>,
              <>
                <TextField
                  required
                  fullWidth
                  label="Customer name"
                  {...getFieldProps('customerName')}
                  error={Boolean(touched.customerName && errors.customerName)}
                  helperText={touched.customerName && errors.customerName}
                  disabled={isLinkedOrg}
                />
              </>,
              <>
                <TextField
                  required
                  fullWidth
                  label="VAT Number"
                  {...getFieldProps('vat')}
                  error={Boolean(touched.vat && errors.vat)}
                  helperText={touched.vat && errors.vat}
                  disabled={isLinkedOrg}
                />
              </>,
              <>
                <TextField
                  required
                  fullWidth
                  label="Country Code"
                  {...getFieldProps('countryCode')}
                  error={Boolean(touched.countryCode && errors.countryCode)}
                  helperText={touched.countryCode && errors.countryCode}
                  disabled={isLinkedOrg}
                />
              </>,
              <>
                <TextField
                  required
                  fullWidth
                  label="Currency"
                  {...getFieldProps('currency')}
                  error={Boolean(touched.currency && errors.currency)}
                  helperText={touched.currency && errors.currency}
                  disabled={isLinkedOrg}
                />
              </>,
              <>
                <IntegerInput
                  required
                  label="Seats"
                  value={getFieldProps('seats').value}
                  onChange={(v) => setFieldValue('seats', v)}
                  error={Boolean(touched.seats && errors.seats)}
                  helperText={touched.seats ? errors.seats : ''}
                  disabled={isLinkedOrg}
                />
              </>
            ]}
            dirty={dirty}
            isSubmitting={isSubmitting || loading.addOrganization || licenseLoading}
            close={() => onClose()}
            resetForm={resetForm}
            submitForm={submitForm}
          />
        </Form>
      </FormikProvider>
      <UnsavedChangesModal
        isOpen={isUnsavedChangesModalOpen}
        onCancel={() => {
          setIsUnsavedChangesModalOpen(false);
        }}
        onDisregard={() => {
          resetForm();
          setIsUnsavedChangesModalOpen(false);
          onClose();
        }}
        onSave={() => {
          setIsUnsavedChangesModalOpen(false);
          submitForm();
        }}
      />
    </>
  );
};

export default AddOrganizationDialog;
