import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dropzone, { IDropzoneProps } from 'react-dropzone-uploader';

export const getDropzoneError = (error: string) => {
  return `${error} Please check that the file is in the right format and is not open in any other application.`;
};

type CustomDropzoneProps = Partial<IDropzoneProps>;
const CustomDropzone: React.FC<CustomDropzoneProps> = (props: CustomDropzoneProps) => {
  return (
    <DropzoneWrapper>
      <Dropzone
        {...props}
        inputContent={
          <Typography key="dropzoneInputContent">Drag Files or Click to Browse</Typography>
        }
      />
    </DropzoneWrapper>
  );
};

export default CustomDropzone;

const DropzoneWrapper = styled('div')(({ theme }) => {
  return {
    '.dzu-dropzone': {
      height: 'min-content',
      minHeight: '100px',
      overflow: 'unset'
    },
    '.dzu-inputLabel': {
      color: 'unset'
    },
    '.dzu-previewFileName': { color: theme.palette.primary.main },
    '.dzu-previewButton': {
      filter: `hue-rotate(0deg) saturate(150%) brightness(90%) sepia(30%) invert(100%)`
    },
    '.dzu-previewImage': {
      maxWidth: 'calc(100% - 24px)' //-24 for making space for the remove icon
    }
  };
});
