import { LicenseInfo as LicenseInfoMuiXProp } from '@mui/x-data-grid-pro';
import { setLicenseKey } from 'survey-core';

export const registerLicenses = () => {
  //Development use, support, and updates expiry date: Nov 30, 2024
  LicenseInfoMuiXProp.setLicenseKey(
    'c5d222ca8fd45346202bc9fd50a07ebfTz03OTcxOSxFPTE3MzI5NjgxOTgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
  );

  //SurveyJS, expiry date: Nov 30, 2024
  setLicenseKey('NjFiZjBmOGItYjZkMi00MGZjLWE4ZTMtN2NlMzU3ODA3YWY3OzE9MjAyNC0xMS0zMA==');
};
