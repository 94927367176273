import { createSlice } from '@reduxjs/toolkit';
import { convertFileToFormData } from 'components/util/convert';
import { DataState } from '../../@types/data';
// utils
import axios from '../../utils/axios';
import { dispatch } from '../store';
import { getDropzoneError } from 'components/CustomDropzone';

// ----------------------------------------------------------------------

const initialState: DataState = {
  isLoading: false,
  isUploadLoading: false,
  isUploadFinished: false,
  errorUpload: false,
  data: {
    total: 0,
    results: null
  }
};

const slice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    addDataSampleFromSpreadsheed(state, action) {
      state.data = action.payload;
      state.isLoading = false;
    },

    removeDataSampleFromSpreadsheed(state) {
      state.data = initialState.data;
      state.isLoading = false;
      state.isUploadFinished = false;
    },

    startUpload(state) {
      state.errorUpload = false;
      state.isUploadLoading = true;
      state.isUploadFinished = false;
    },

    hasUploadError(state, action) {
      state.errorUpload = action.payload;
      state.isUploadLoading = false;
    },

    uploadFinished(state) {
      state.errorUpload = false;
      state.isUploadLoading = false;
      state.isUploadFinished = true;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const { changeCampaign, dismissError } = slice.actions;

// ----------------------------------------------------------------------

export function samlpeDataFromSpreadsheet({ file }: { file: File | undefined }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      if (!file) {
        dispatch(slice.actions.addDataSampleFromSpreadsheed({}));
        return await Promise.resolve({
          defaultSuccessMessage: 'There is no file to process'
        });
      }

      const response = await axios.post(
        `/data/fromspreadsheet/sample`,
        convertFileToFormData(file)
      );
      dispatch(slice.actions.addDataSampleFromSpreadsheed(response.data));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Retrived sample from spreadsheet'
      });
    } catch (error: any) {
      return await Promise.reject({
        error: error,
        defaultErrorMessage: getDropzoneError('Uploaded file could not be processed.')
      });
    }
  };
}

export function resetDataFromSpreadsheet() {
  return async () => {
    dispatch(slice.actions.removeDataSampleFromSpreadsheed());
    return Promise.resolve({
      defaultSuccessMessage: 'Data from spreadsheet reset'
    });
  };
}

export function uploadDataFromSpreadsheet({ file }: { file: File | undefined }) {
  return async () => {
    dispatch(slice.actions.startUpload());
    try {
      if (!file) {
        return await Promise.reject({
          defaultErrorMessage: 'There is no file to upload'
        });
      }

      await axios.post(`/data/fromspreadsheet`, convertFileToFormData(file));
      dispatch(slice.actions.uploadFinished());

      return await Promise.resolve({
        defaultSuccessMessage: 'Import of respondents, companies and/or employees started.'
      });
    } catch (error: any) {
      dispatch(slice.actions.hasUploadError(error));

      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Import of respondents, companies and/or employees failed.'
      });
    }
  };
}
