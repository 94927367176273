import { createSlice } from '@reduxjs/toolkit';
import { TimeSpan } from '../../@types/dashboard';
import axios from 'utils/axios';
import { ReportsState } from '../../@types/reports';
// utils
import { dispatch, store } from '../store';
import { getFromToFilterFromTimespan } from 'components/util/datePicker/FromToDatePicker';
const FileSaver = require('file-saver');

// ----------------------------------------------------------------------
const { from: initialFrom, to: initialTo } = getFromToFilterFromTimespan(TimeSpan.LAST_30_DAYS);
export const initialState: ReportsState = {
  isDownloadLoading: false,
  selectedCampaigns: [],
  timespan: TimeSpan.LAST_30_DAYS,
  fromDateFilter: initialFrom,
  toDateFilter: initialTo
};

export const reportsPersistWhitelist = []; //Removed the following, as the persistent state of the timespan causes confusion 'timespan', 'fromDateFilter', 'toDateFilter'

const slice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setSelectedCampaigns(state, action) {
      state.selectedCampaigns = action.payload;
    },
    setDateFilter(state, action) {
      state.fromDateFilter = action.payload.from;
      state.toDateFilter = action.payload.to;
      state.timespan = action.payload.timespan;
    },
    startDownloadLoading(state) {
      state.isDownloadLoading = true;
    },
    stopDownloadLoading(state) {
      state.isDownloadLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { stopDownloadLoading, setSelectedCampaigns } = slice.actions;

// ----------------------------------------------------------------------

export const updateDateFilter = ({
  from,
  to,
  timespan
}: {
  from: any;
  to: any;
  timespan: TimeSpan;
}) => {
  return () => {
    dispatch(
      slice.actions.setDateFilter({
        from:
          typeof from === 'number'
            ? from
            : from?.getFullYear?.()?.toString?.().length === 4
            ? from?.getTime?.()
            : undefined,
        to:
          typeof to === 'number'
            ? to
            : to?.getFullYear?.()?.toString?.().length === 4
            ? to?.getTime?.()
            : undefined,
        timespan: timespan
      })
    );

    return Promise.resolve({
      defaultSuccessMessage: 'Report date filter updated'
    });
  };
};

export const downloadReport = ({
  reportId,
  reportName
}: {
  reportId: number;
  reportName: string;
}) => {
  return async () => {
    dispatch(slice.actions.startDownloadLoading());
    try {
      //setup parameters
      const currentState = store.getState().reports;
      const from = currentState.fromDateFilter
        ? new Date(currentState.fromDateFilter).toISOString()
        : undefined;
      const to = currentState.toDateFilter
        ? new Date(currentState.toDateFilter).toISOString()
        : undefined;

      //get report data
      const response = await axios.post(
        `/campaigns/report${reportId}?from=${from || ''}&to=${to || ''}`,
        currentState.selectedCampaigns,
        {
          responseType: 'blob'
        }
      );

      //set file type
      let fileType = '';
      switch (reportId) {
        case 1:
          fileType = 'xlsx';
          break;
        case 2:
          fileType = 'xlsx';
          break;
        case 3:
          fileType = 'xlsx';
          break;
        case 4:
          fileType = 'xlsm';
          break;
        case 5:
          fileType = 'xlsm';
          break;
        case 6:
          fileType = 'xlsm';
          break;
        case 7:
          fileType = 'xlsm';
          break;
        case 8:
          fileType = 'xlsx';
          break;
        case 9:
          fileType = 'xlsm';
          break;
        case 51:
          fileType = 'xlsx';
          break;
        default:
          fileType = 'xlsx';
      }
      //create and download file
      const blob = new Blob([response.data]);
      FileSaver.saveAs(blob, `${reportName}.${fileType}`);

      dispatch(slice.actions.stopDownloadLoading());

      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: `Report is downloading (${reportName})`
      });
    } catch (error: any) {
      dispatch(slice.actions.stopDownloadLoading());
      return Promise.reject({
        error: error,
        defaultErrorMessage: `Could not download report (${reportName})`
      });
    }
  };
};
