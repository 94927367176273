import useSettings from 'hooks/useSettings';
import Snowfall from 'react-snowfall';

const CustomSnowfall = () => {
  const { enableChristmas, snowflakeValues } = useSettings();

  return (
    <>
      {enableChristmas && (
        <Snowfall
          style={{
            position: 'fixed',
            width: '100vw',
            height: '100vh'
          }}
          {...snowflakeValues}
        />
      )}
    </>
  );
};

export default CustomSnowfall;
