export const getCountryCodeFromLanguageName = (countryName: string) => {
  switch (countryName) {
    case 'Dansk':
    case 'Danish':
      return 'DK';
    case 'English':
      return 'GB';
    case 'Norsk':
      return 'NO';
    case 'Svenska':
      return 'SE';
    case 'Deutsch':
      return 'DE';
    case 'русский':
      return 'RU';
    case 'Latviešu':
      return 'LV';
    case 'Lietuvos':
      return 'LT';
    case 'Eesti':
      return 'EE';
    case 'Føroyskt':
      return 'FO';
    case 'Kalaallisut':
      return 'GL';
    case 'Dansk-Kalaallisut':
      return 'GL';
    case 'Nederlands':
      return 'NL';
    case 'Italiano':
      return 'IT';
    case 'Français':
      return 'FR';
    case 'Suomi':
      return 'FI';
    case 'Czech':
      return 'CZ';
    case 'Spanish':
      return 'ES';
    case 'Slovenian':
      return 'SI';
    case 'Polish':
      return 'PL';
    case 'Turkish':
      return 'TR';
    case 'Hungarian':
      return 'HU';
    case 'Portuguese':
      return 'PT';
    case 'Catalan':
      return 'ES'; // Catalan is officially spoken in Spain, though also in Andorra
    case 'Romanian':
      return 'RO';
    case 'Slovakian':
      return 'SK';
    case 'Chinese':
      return 'CN';
    case 'Bulgarian':
      return 'BG';
    case 'Korean':
      return 'KR';
    case 'Ελληνικά': // Greek language
    case 'Greek':
      return 'GR';
    case 'Icelandic':
      return 'IS';
    default:
      return countryName;
  }
};
