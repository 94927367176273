import { ReactNode, createContext } from 'react';
// hooks
import useLocalStorage from '../hooks/useLocalStorage';
// theme
import palette from '../theme/palette';
// @type
import { ThemeMode, ThemeDirection, ThemeColor, SettingsContextProps } from '../@types/settings';
import { SnowfallProps } from 'react-snowfall';

// ----------------------------------------------------------------------

export const PRIMARY_COLOR = [
  // DEFAULT
  {
    name: 'default',
    ...palette.light.primary
  },

  // YELLOW
  {
    name: 'yellow',
    lighter: '#FFF1B4',
    light: '#F8E6B0',
    main: '#F2CF63',
    dark: '#F6DE96',
    darker: '#C3AF76',
    contrastText: palette.light.grey[800]
  },
  // RED
  {
    name: 'red',
    lighter: '#FFCDC0',
    light: '#F2C2B5',
    main: '#DB6B59',
    dark: '#CD8F85',
    darker: '#9A6B63',
    contrastText: '#fff'
  }
];

function SetColor(themeColor: ThemeColor) {
  let color;
  const DEFAULT = PRIMARY_COLOR[0];
  const YELLOW = PRIMARY_COLOR[1];
  const RED = PRIMARY_COLOR[2];

  switch (themeColor) {
    case 'yellow':
      color = YELLOW;
      break;
    case 'red':
      color = RED;
      break;
    default:
      color = DEFAULT;
  }
  return color;
}

const isDecember = new Date().getMonth() === 11;
const initialState: SettingsContextProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColor: 'default',
  themeStretch: false,
  onChangeMode: () => {},
  onChangeDirection: () => {},
  onChangeColor: () => {},
  onToggleStretch: () => {},
  onChangeEnableChristmas: () => {},
  setColor: PRIMARY_COLOR[0],
  colorOption: [],
  enableChristmas: false,
  isDecember: isDecember,
  snowflakeValues: {
    wind: [-0.5, 2.0],
    radius: [0.5, 3.0],
    snowflakeCount: 150,
    speed: [1.0, 3.0],
    color: '#dee4fd'
  },
  onChangeSnowflakeValues: () => {}
};

const SettingsContext = createContext(initialState);

type SettingsProviderProps = {
  children: ReactNode;
};

function SettingsProvider({ children }: SettingsProviderProps) {
  const [settings, setSettings] = useLocalStorage('settings', {
    themeMode: initialState.themeMode,
    themeDirection: initialState.themeDirection,
    themeColor: initialState.themeColor,
    themeStretch: true,
    enableSnow: false
  });

  const onChangeMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      themeMode: (event.target as HTMLInputElement).value as ThemeMode
    });
  };

  const onChangeDirection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      themeDirection: (event.target as HTMLInputElement).value as ThemeDirection
    });
  };

  const onChangeColor = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      themeColor: (event.target as HTMLInputElement).value as ThemeColor
    });
  };

  const onToggleStretch = () => {
    setSettings({
      ...settings,
      themeStretch: !settings.themeStretch
    });
  };

  const onChangeEnableChristmas = (enableChristmas: boolean) => {
    setSettings({
      ...settings,
      enableChristmas: enableChristmas
    });
  };

  const onChangeSnowflakeValues = (snowflakeValuesToChange: Partial<SnowfallProps>) => {
    setSettings({
      ...settings,
      snowflakeValues: {
        ...settings.snowflakeValues,
        ...snowflakeValuesToChange
      }
    });
  };

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        // Mode
        onChangeMode,
        // Direction
        onChangeDirection,
        // Color
        onChangeColor,
        setColor: SetColor(settings.themeColor),
        colorOption: PRIMARY_COLOR.map((color) => ({
          name: color.name,
          value: color.main
        })),
        // Stretch
        onToggleStretch,
        // Snow
        onChangeEnableChristmas,
        onChangeSnowflakeValues,
        enableChristmas: settings.enableChristmas && isDecember,
        isDecember: isDecember
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsProvider, SettingsContext };
