const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
const PNF = require('google-libphonenumber').PhoneNumberFormat;

const formatPhoneNumber = (phoneNumber: string) => {
  try {
    const parsedNumber = phoneUtil.parseAndKeepRawInput(phoneNumber);
    return phoneUtil.format(parsedNumber, PNF.E164);
  } catch (e) {
    return phoneNumber;
  }
};

export default formatPhoneNumber;
