import { createSlice } from '@reduxjs/toolkit';
import { axiosEtrack1Instance } from '../../utils/axios';
import { dispatch } from 'redux/store';
import { Etrack1Credentials, Etrack1Map, Etrack1State } from '../../@types/etrack1';

// ----------------------------------------------------------------------
const initialState: Etrack1State = {
  isLoading: {},
  credentials: {
    username: '',
    password: '',
    CustomerUID: '',
    PrivateKey: '',
    ApplicationToken: '',
    delay: ''
  },
  maps: [],
  sites: []
};

const slice = createSlice({
  name: 'seats',
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isLoading[action.payload] = true;
    },
    endLoading(state, action) {
      state.isLoading[action.payload] = false;
    },
    setCredentials(state, action) {
      state.credentials = action.payload || initialState.credentials;
    },
    setMaps(state, action) {
      state.maps = action.payload || initialState.maps;
    },
    setSites(state, action) {
      state.sites = action.payload || initialState.sites;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
//export const {} = slice.actions;

// ----------------------------------------------------------------------

export const getCredentials = () => {
  return async () => {
    dispatch(slice.actions.startLoading('getCredentials'));
    try {
      const response = await axiosEtrack1Instance.get('/api/credentials');
      dispatch(slice.actions.setCredentials(response.data));
      dispatch(slice.actions.endLoading('getCredentials'));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Fetched etrack1 credentials'
      });
    } catch (error: any) {
      dispatch(slice.actions.endLoading('getCredentials'));

      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not fetch etrack1 credentials`
      });
    }
  };
};

type SetCredentialsProps = {
  credentials: Etrack1Credentials;
};
export const setCredentials = ({ credentials }: SetCredentialsProps) => {
  return async () => {
    dispatch(slice.actions.startLoading('setCredentials'));
    try {
      const response = await axiosEtrack1Instance.post('/api/credentials', credentials);
      dispatch(slice.actions.setCredentials(response.data));
      dispatch(slice.actions.endLoading('setCredentials'));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'etrack1 credentials updated'
      });
    } catch (error: any) {
      dispatch(slice.actions.endLoading('setCredentials'));

      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not update etrack1 credentials`
      });
    }
  };
};

export const deleteCredentials = () => {
  return async () => {
    dispatch(slice.actions.startLoading('deleteCredentials'));
    try {
      await axiosEtrack1Instance.delete('/api/credentials');
      dispatch(slice.actions.setCredentials({}));
      dispatch(slice.actions.endLoading('deleteCredentials'));
      return await Promise.resolve({
        defaultSuccessMessage: 'etrack1 credentials deleted'
      });
    } catch (error: any) {
      dispatch(slice.actions.endLoading('deleteCredentials'));

      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not delete etrack1 credentials`
      });
    }
  };
};

export const getSites = () => {
  return async () => {
    dispatch(slice.actions.startLoading('getSites'));
    try {
      const response = await axiosEtrack1Instance.get('/api/sites/categories');
      dispatch(slice.actions.setSites(response.data));
      dispatch(slice.actions.endLoading('getSites'));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Fetched etrack1 sites'
      });
    } catch (error: any) {
      dispatch(slice.actions.endLoading('getSites'));

      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not fetch etrack1 sites`
      });
    }
  };
};

export const getMaps = () => {
  return async () => {
    dispatch(slice.actions.startLoading('getMaps'));
    try {
      const response = await axiosEtrack1Instance.get('/api/maps');
      dispatch(slice.actions.setMaps(response.data));
      dispatch(slice.actions.endLoading('getMaps'));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Fetched etrack1 maps'
      });
    } catch (error: any) {
      dispatch(slice.actions.endLoading('getMaps'));

      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not fetch etrack1 maps`
      });
    }
  };
};

type AddMapProps = {
  map: Etrack1Map;
};
export const addMap = ({ map }: AddMapProps) => {
  return async () => {
    dispatch(slice.actions.startLoading('addMap'));
    try {
      await axiosEtrack1Instance.post(`/api/maps`, map);
      dispatch(slice.actions.endLoading('addMap'));
      return await Promise.resolve({
        defaultSuccessMessage: `etrack1 connection added`
      });
    } catch (error: any) {
      dispatch(slice.actions.endLoading('addMap'));

      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not add etrack1 map`
      });
    }
  };
};

type DeleteMapProps = {
  mapToDelete: Etrack1Map;
};
export const deleteMap = ({ mapToDelete }: DeleteMapProps) => {
  return async () => {
    dispatch(slice.actions.startLoading('deletetMap'));
    try {
      await axiosEtrack1Instance.delete(`/api/maps/${mapToDelete.categoryId}`);
      dispatch(slice.actions.endLoading('deletetMap'));
      return await Promise.resolve({
        defaultSuccessMessage: 'etrack1 connection deleted'
      });
    } catch (error: any) {
      dispatch(slice.actions.endLoading('deletetMap'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not delete etrack1 map`
      });
    }
  };
};
