import { createSlice } from '@reduxjs/toolkit';
import { capitalCase } from 'change-case';
import { Template } from '../../@types/campaign';
import {
  APIKey,
  Claim,
  IvrMap,
  NewOrganization,
  Organisation,
  OrganizationProfile,
  OrganizationState,
  Sender,
  User
} from '../../@types/organization';
// utils
import axios from '../../utils/axios';
import { dispatch } from '../store';
import { getDropzoneError } from 'components/CustomDropzone';
import { Role } from 'guards/AccessGuard';
import { ReviewConfigurationDto } from '../../@types/reviews';

// ----------------------------------------------------------------------

const initialState: OrganizationState = {
  loading: {},
  isLoading: false,
  isLogoLoading: false,
  organizationAnalytics: {
    campaignCount: 0,
    companyCount: 0,
    emailCount: 0,
    employeeCount: 0,
    orgId: '',
    respondentCount: 0,
    responseCount: 0,
    smsCount: 0,
    smsEnabled: false
  },
  organizationProfile: {
    id: '',
    displayName: '',
    organizationName: '',
    organizationPhone: '',
    contactName: '',
    contactPhone: '',
    contactEmail: '',
    invoiceEmail: '',
    cvr: '',
    agreementId: null,
    active: true,
    subscriptionType: 0,
    adminSeats: 0,
    userSeats: 0,
    outlookSeats: 0,
    emailLimit: null,
    smsEnabled: true,
    smsLimit: 0,
    logoLink: '',
    sid: '',
    reviewDescription: '',
    reviewName: '',
    created: null,
    website: '',
    enableHumanVerification: undefined
  },
  languages: [],
  templates: [],
  currentTemplate: null,
  copyTemplate: null,
  logos: [],
  apiKeys: [],
  users: [],
  user: undefined,
  ivrMaps: [],
  ivrMap: null,
  senders: [],
  organizations: [],
  callDurationLimit: undefined,
  anonymizationConfiguration: undefined
};

const slice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setCallDurationLimit(state, action) {
      state.callDurationLimit = action.payload;
    },

    setAnonymizationConfiguration(state, action) {
      state.anonymizationConfiguration = action.payload;
    },

    // START LOADING
    startNewLoading(state, action) {
      state.loading[action.payload] = true;
    },

    // END LOADING
    endNewLoading(state, action) {
      state.loading[action.payload] = false;
    },

    startLoading(state) {
      state.isLoading = true;
    },

    // START LOGO LOADING
    startLogoLoading(state) {
      state.isLogoLoading = true;
    },

    // START LOGO LOADING
    stopLogoLoading(state) {
      state.isLogoLoading = false;
    },

    // GET ORGANIZATION ANALYTICS
    getOrganizationAnalyticsSuccess(state, action) {
      state.organizationAnalytics = action.payload;
      state.loading.organizationAnalytics = false;
    },

    // GET ORGANIZATION PROFILE
    getOrganizationProfileSuccess(state, action) {
      state.organizationProfile = action.payload;
      state.isLoading = false;
    },

    // GET ORGANIZATION USERS
    getOrganizationUsersSuccess(state, action) {
      state.users = action.payload;
      state.loading.users = false;
    },

    // GET ORGANIZATION USER
    getOrganizationUserSuccess(state, action) {
      state.user = action.payload;
      state.loading.user = false;
    },

    updateOrganizationProfileSuccess(state, action) {
      state.organizationProfile = action.payload;
      state.loading.organizationProfile = false;
    },

    //GET LANGUAGES
    getLanguagesSuccess(state, action) {
      state.languages = action.payload;
      state.isLoading = false;
    },

    // GET TEMPLATES
    getTemplatesSuccess(state, action) {
      state.templates = action.payload;
      state.isLoading = false;
    },

    // GET TEMPLATE
    getTemplateSuccess(state, action) {
      state.currentTemplate = action.payload;
      state.isLoading = false;
    },

    getLogosSuccess(state, action) {
      state.isLogoLoading = false;
      state.logos = action.payload;
    },

    addLogoSuccess(state) {
      state.isLogoLoading = false;
    },

    getTemplateCopySuccess(state, action) {
      state.copyTemplate = action.payload;
      state.isLoading = false;
    },

    putTemplateSuccess(state, action) {
      state.currentTemplate = action.payload;
      state.isLoading = false;
    },

    changeTemplate(state, action) {
      state.isLoading = true;
      const templateId = action.payload;
      // number and any comparison
      // eslint-disable-next-line eqeqeq
      if (state.currentTemplate && templateId == state.currentTemplate.id) {
        state.isLoading = false;
        return;
      }

      // number and any comparison
      // eslint-disable-next-line eqeqeq
      let newTemplate = state.templates.find((template) => template.id == templateId);

      if (newTemplate) {
        state.currentTemplate = newTemplate;
      }

      state.isLoading = false;
    },

    getAPIKeysSuccess(state, action) {
      state.apiKeys = action.payload;
      state.loading.apiKeys = false;
    },

    removeAPIKey(state, action) {
      state.apiKeys = state.apiKeys.filter((key) => key.id !== action.payload);
    },

    getIvrMapsSuccess(state, action) {
      state.ivrMaps = action.payload;
    },

    getIvrMapSuccess(state, action) {
      state.ivrMap = action.payload;
    },

    getSendersSuccess(state, action) {
      state.loading.senders = false;
      state.senders = action.payload || [];
    },

    updateSenderSuccess(state) {
      state.loading.updatedSender = false;
    },

    deleteSenderSuccess(state) {
      state.loading.deleteSender = false;
    },

    getOrganizationsSuccess(state, action) {
      state.organizations = action.payload;
      state.loading.organizations = false;
    },

    addSendersSuccess(state) {
      state.loading.addSender = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { changeTemplate, getOrganizationUserSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getOrganizationAnalytics() {
  return async () => {
    dispatch(slice.actions.startNewLoading('organizationAnalytics'));
    try {
      const response = await axios.get('/analytics/organizationsummary');
      dispatch(slice.actions.endNewLoading('organizationAnalytics'));
      dispatch(slice.actions.getOrganizationAnalyticsSuccess(response.data));

      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Fetched organization analytics'
      });
    } catch (error) {
      dispatch(slice.actions.endNewLoading('organizationAnalytics'));

      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch organization analytics'
      });
    }
  };
}

export function getOrganizationProfile() {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startNewLoading('getOrganizationProfile'));
    try {
      const response = await axios.get('/organization');
      dispatch(slice.actions.getOrganizationProfileSuccess(response.data));
      dispatch(slice.actions.endNewLoading('getOrganizationProfile'));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Updated organization profile'
      });
    } catch (error) {
      dispatch(slice.actions.endNewLoading('getOrganizationProfile'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch Organization Profile'
      });
    }
  };
}

export function updateOrganizationProfile(organizationProfile: OrganizationProfile) {
  dispatch(slice.actions.startNewLoading('organizationProfile'));
  return async () => {
    const response = await axios.put('/organization', organizationProfile).catch((error: any) => {
      dispatch(slice.actions.endNewLoading('organizationProfile'));
      return Promise.reject({
        error: error,
        defaultErrorMessage: `Could not update organization`
      });
    });
    dispatch(slice.actions.updateOrganizationProfileSuccess(response.data));
    dispatch(slice.actions.endNewLoading('organizationProfile'));
    return Promise.resolve({
      result: response,
      defaultSuccessMessage: 'Updated organization'
    });
  };
}

export function updateReviewConfiguration(dto: ReviewConfigurationDto) {
  dispatch(slice.actions.startNewLoading('reviewConfiguration'));
  return async () => {
    await axios.put('/reviews-configuration', dto).catch((error: any) => {
      dispatch(slice.actions.endNewLoading('reviewConfiguration'));
      return Promise.reject({
        error: error,
        defaultErrorMessage: `Could not update review configuration`
      });
    });
    dispatch(slice.actions.endNewLoading('reviewConfiguration'));
    return Promise.resolve({
      defaultSuccessMessage: 'Updated review configuration'
    });
  };
}

export function getOrganizationUsers() {
  return async () => {
    dispatch(slice.actions.startNewLoading('users'));
    try {
      const response = await axios.get('/profile/users');
      dispatch(slice.actions.getOrganizationUsersSuccess(response.data));
      dispatch(slice.actions.endNewLoading('users'));

      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Organization users fetched'
      });
    } catch (error) {
      dispatch(slice.actions.endNewLoading('users'));

      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch organization users'
      });
    }
  };
}

export function getOrganizationUser(userId?: string) {
  return async () => {
    if (!userId) {
      dispatch(slice.actions.getOrganizationUserSuccess(undefined));
      return Promise.resolve({
        defaultSuccessMessage: 'Organization user reset'
      });
    }

    dispatch(slice.actions.startNewLoading('user'));
    try {
      const response = await axios.get(`/users/${userId}`);
      dispatch(slice.actions.getOrganizationUserSuccess(response.data));
      dispatch(slice.actions.endNewLoading('user'));

      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Fetched organization user'
      });
    } catch (error) {
      dispatch(slice.actions.endNewLoading('user'));

      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch organization user'
      });
    }
  };
}

export function getLanguages() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/languages');
      dispatch(slice.actions.getLanguagesSuccess(response.data));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Updated langueages'
      });
    } catch (error) {
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch languages'
      });
    }
  };
}

export function getTemplates() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/templates');
      dispatch(slice.actions.getTemplatesSuccess(response.data));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Updated templates'
      });
    } catch (error) {
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch templates'
      });
    }
  };
}

export function getLogos() {
  return async () => {
    dispatch(slice.actions.startLogoLoading());
    try {
      const response = await axios.get('/logos');
      dispatch(slice.actions.getLogosSuccess(response.data));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Updated logos'
      });
    } catch (error) {
      dispatch(slice.actions.stopLogoLoading());

      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch logos'
      });
    }
  };
}

export function addLogo(file: any) {
  return async () => {
    if (file?.size > 3000000) {
      return Promise.reject({
        defaultErrorMessage: 'File too big'
      });
    }

    dispatch(slice.actions.startLogoLoading());
    try {
      let formData = new FormData();
      formData.append('image', file);
      await axios.post('/logos', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch(getLogos());
      dispatch(slice.actions.addLogoSuccess());
      return await Promise.resolve({
        defaultSuccessMessage: 'File uploaded'
      });
    } catch (error) {
      dispatch(slice.actions.stopLogoLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not upload logo'
      });
    }
  };
}

export function updateOrgLogo(file: any) {
  return async () => {
    dispatch(slice.actions.startLogoLoading());
    try {
      let formData = new FormData();
      formData.append('image', file);
      await axios.post('/organization/profile/logo', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      await dispatch(getOrganizationProfile());
      dispatch(slice.actions.addLogoSuccess());
      return await Promise.resolve({
        defaultSuccessMessage: 'File uploaded'
      });
    } catch (error) {
      dispatch(slice.actions.stopLogoLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: getDropzoneError('Could not upload logo')
      });
    }
  };
}

export function deleteLogo(fileName: string) {
  return async () => {
    dispatch(slice.actions.startLogoLoading());
    try {
      const params = {
        filename: fileName
      };
      await axios.delete('/logos', { params });
      await dispatch(getLogos());
      return await Promise.resolve({
        defaultSuccessMessage: `Logo deleted`
      });
    } catch (error) {
      dispatch(slice.actions.stopLogoLoading());
      const campaignIdsThatUseLogo = error as [];
      const isPlural = campaignIdsThatUseLogo?.length > 1;
      const ending = isPlural ? 's' : '';
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not delete logo as it is used in following campaign${ending} with id${ending}: ${campaignIdsThatUseLogo.join(
          ', '
        )}`
      });
    }
  };
}

export function getTemplate(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/templates/' + id);
      dispatch(slice.actions.getTemplateSuccess(response.data));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: `Template with id ${id} fetched`
      });
    } catch (error) {
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not fetch template with id ${id}`
      });
    }
  };
}

//'Created new template ' + values.template.name
export function postTemplate(template: Template) {
  return async () => {
    dispatch(slice.actions.startNewLoading('template'));
    await axios.post('/templates', template).catch((error: any) => {
      dispatch(slice.actions.endNewLoading('template'));
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Template could not be created'
      });
    });
    dispatch(slice.actions.endNewLoading('template'));
    return Promise.resolve({
      defaultSuccessMessage: `Created new template ${template.name}`
    });
  };
}

export function putTemplate(template: Template) {
  return async () => {
    dispatch(slice.actions.startNewLoading('template'));
    const response = await axios.put('/templates/' + template.id, template).catch((error: any) => {
      dispatch(slice.actions.endNewLoading('template'));
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Template could not be updated'
      });
    });
    dispatch(slice.actions.putTemplateSuccess(response?.data));
    dispatch(slice.actions.endNewLoading('template'));
    return Promise.resolve({
      result: response,
      defaultSuccessMessage: `Edited template ${template.name}`
    });
  };
}

export function getAPIKeys() {
  return async () => {
    dispatch(slice.actions.startNewLoading('apiKeys'));
    try {
      const response = await axios.get('/apikeys');
      dispatch(slice.actions.getAPIKeysSuccess(response.data));
      dispatch(slice.actions.endNewLoading('apiKeys'));

      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Fetched API keys'
      });
    } catch (error) {
      dispatch(slice.actions.endNewLoading('apiKeys'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'API Keys could not be fetched'
      });
    }
  };
}

export function deleteAPIKey(id: number) {
  return async () => {
    dispatch(slice.actions.startLogoLoading());
    try {
      dispatch(slice.actions.removeAPIKey(id));
      await axios.delete('/apikeys/' + id);
      dispatch(getAPIKeys());

      return await Promise.resolve({
        defaultSuccessMessage: `API Key with id ${id}  deleted`
      });
    } catch (error) {
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `API Key with id ${id} could not be deleted`
      });
    }
  };
}

export function createAPIKey(name: string) {
  return async () => {
    dispatch(slice.actions.startLogoLoading());
    try {
      await axios.post('/apikeys?name=' + name);
      dispatch(getAPIKeys());

      return await Promise.resolve({
        defaultSuccessMessage: `API Key ${name} created`
      });
    } catch (error) {
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `API Key ${name} could not be created`
      });
    }
  };
}

type EditAPIKeyProps = {
  apiKey: APIKey;
  newName: string;
};
export function editAPIKey({ apiKey, newName }: EditAPIKeyProps) {
  return async () => {
    try {
      await axios.put('/apikeys/' + apiKey.id, {
        id: apiKey.id,
        name: newName,
        key: apiKey.key
      });
      dispatch(getAPIKeys());
      return await Promise.resolve({
        defaultSuccessMessage: 'Edited name of api key'
      });
    } catch (error: any) {
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not edit api key`
      });
    }
  };
}

export function testWebhook(url: string) {
  dispatch(slice.actions.startNewLoading('webhook'));
  return async () => {
    const response = await axios
      .post('/webhooks/testwebhook', '"' + url + '"', {
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      })
      .catch((error: any) => {
        dispatch(slice.actions.endNewLoading('webhook'));
        return Promise.reject({
          error: error,
          defaultErrorMessage: 'Error testing webhook call'
        });
      });
    dispatch(slice.actions.endNewLoading('webhook'));
    if (response.data) {
      return Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Webhook call succeeded'
      });
    } else {
      return Promise.reject({
        defaultErrorMessage: 'Webhook call failed'
      });
    }
  };
}

export function addUser(user: any) {
  dispatch(slice.actions.startNewLoading('user'));
  return async () => {
    await axios
      .post('/user/invite', {
        email: user.email,
        role: user.role,
        claims: [
          {
            claimType: 'Applications',
            claimValue: 'basic'
          }
        ]
      })
      .catch((error: any) => {
        dispatch(slice.actions.endNewLoading('user'));
        return Promise.reject({
          error: error,
          defaultErrorMessage: `Could not add user`
        });
      });

    dispatch(slice.actions.endNewLoading('user'));
    dispatch(getOrganizationUsers());

    return Promise.resolve({
      defaultSuccessMessage: 'User added'
    });
  };
}

type DeleteClaimsProps = {
  user?: User;
  claims: Claim[];
};
export function deleteClaims({ user, claims }: DeleteClaimsProps) {
  dispatch(slice.actions.startNewLoading('deleteClaim'));
  return async () => {
    try {
      await axios.delete(`/user/claims`, { data: claims });
      dispatch(slice.actions.endNewLoading('deleteClaim'));

      const defaultSuccessMessage = `${claims
        .map((license: Claim) => capitalCase(license.claimValue))
        .join(', ')} removed from ${user?.name || user?.userName || user?.email || 'user'}`;
      return await Promise.resolve({
        defaultSuccessMessage: defaultSuccessMessage
      });
    } catch (error: any) {
      dispatch(slice.actions.endNewLoading('deleteClaim'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Encountered error while deleting claims'
      });
    }
  };
}

type AddClaimsProps = {
  user: User;
  claims: Claim[];
};
export function addClaims({ user, claims }: AddClaimsProps) {
  dispatch(slice.actions.startNewLoading('addClaims'));
  return async () => {
    const params = claims.map((claim) => {
      return {
        ...claim,
        userId: user.userId
      };
    });

    try {
      await axios.post('/user/claims', params);
      dispatch(slice.actions.endNewLoading('addClaims'));

      const defaultSuccessMessage = `${claims
        .map((license: Claim) => capitalCase(license.claimValue))
        .join(', ')} added to ${user.name || user.userName || user.email || 'user'}`;
      return await Promise.resolve({
        defaultSuccessMessage: defaultSuccessMessage
      });
    } catch (error: any) {
      dispatch(slice.actions.endNewLoading('addClaims'));

      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Encountered error while updating claims'
      });
    }
  };
}

type UpdateRoleProps = {
  userId?: string;
  roleId: string;
};
export function updateRole({ userId, roleId }: UpdateRoleProps) {
  dispatch(slice.actions.startNewLoading('updateRole'));
  return async () => {
    try {
      let role = 3;
      if (roleId === Role.CompanyAdmin) {
        role = 2;
      }
      if (roleId === Role.SuperAdmin) {
        role = 1;
      }
      await axios.put(`/user/role`, { roleId: role, userId: userId });
      dispatch(slice.actions.endNewLoading('updateRole'));
      return await Promise.resolve({
        defaultSuccessMessage: 'User role updated'
      });
    } catch (error: any) {
      dispatch(slice.actions.endNewLoading('updateRole'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Encountered error while updating role'
      });
    }
  };
}

type UpdateUserActiveStatusProps = {
  userId?: string;
  active: boolean;
};
export function updateUserActiveStatus({ userId, active }: UpdateUserActiveStatusProps) {
  dispatch(slice.actions.startNewLoading('updateActiveStatus'));
  return async () => {
    try {
      await axios.put(`/user/active-status`, { active: active, userId: userId });
      dispatch(slice.actions.endNewLoading('updateActiveStatus'));
      return await Promise.resolve({
        defaultSuccessMessage: 'User active status updated'
      });
    } catch (error: any) {
      dispatch(slice.actions.endNewLoading('updateActiveStatus'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Encountered error while updating user active status'
      });
    }
  };
}

type ResendUserInviteProps = {
  userId: string;
  userEmail: string;
};
export function resendUserInvite({ userId, userEmail }: ResendUserInviteProps) {
  const loadingId = `userInvite${userId}`;
  dispatch(slice.actions.startNewLoading(loadingId));
  return async () => {
    try {
      const response = await axios.post(`/user/${userId}/invite`);
      dispatch(slice.actions.endNewLoading(loadingId));
      dispatch(getOrganizationUsers());
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: `Invite sent to ${userEmail}`
      });
    } catch (error: any) {
      dispatch(slice.actions.endNewLoading(loadingId));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Invite not sent to ${userEmail}`
      });
    }
  };
}

export function deleteUsers(ids: string[]) {
  dispatch(slice.actions.startNewLoading('user'));
  return async () => {
    try {
      await Promise.all(ids.map((id) => axios.delete('/organization/user/' + id)))
        .then((data: any) => Promise.resolve(data))
        .catch((e: any) => Promise.reject(e));

      dispatch(slice.actions.endNewLoading('user'));
      dispatch(getOrganizationUsers());

      return await Promise.resolve({
        defaultSuccessMessage: 'Removed ' + ids.length + ' user' + (ids.length >= 2 ? 's' : '')
      });
    } catch (error: any) {
      dispatch(slice.actions.endNewLoading('user'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not delete users`
      });
    }
  };
}

export function getIvrMaps() {
  dispatch(slice.actions.startNewLoading('ivr'));
  return async () => {
    try {
      const response = await axios.get('/ivr/maps');
      dispatch(slice.actions.getIvrMapsSuccess(response.data));
      dispatch(slice.actions.endNewLoading('ivr'));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Fetched IVR maps'
      });
    } catch (error: any) {
      dispatch(slice.actions.endNewLoading('ivr'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not fetch IVR maps`
      });
    }
  };
}

export function getIvrMap(id: number | null) {
  return async () => {
    if (!id) {
      dispatch(slice.actions.getIvrMapSuccess(initialState.ivrMap));
      return Promise.resolve({
        defaultSuccessMessage: 'No ivr map to fetch'
      });
    }
    dispatch(slice.actions.startNewLoading('ivrMap'));

    try {
      const response = await axios.get('/ivr/maps/' + id);
      dispatch(slice.actions.getIvrMapSuccess(response.data));
      dispatch(slice.actions.endNewLoading('ivrMap'));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Fetched IVR map'
      });
    } catch (error: any) {
      dispatch(slice.actions.endNewLoading('ivrMap'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not fetch IVR map`
      });
    }
  };
}

export function addIvrMap(ivrMap: Partial<IvrMap>) {
  dispatch(slice.actions.startNewLoading('ivr'));
  return async () => {
    try {
      await axios.post('/ivr/maps', ivrMap);
      dispatch(slice.actions.endNewLoading('ivr'));
      return await Promise.resolve({
        defaultSuccessMessage: 'IVR added'
      });
    } catch (error: any) {
      dispatch(slice.actions.endNewLoading('ivr'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Error adding IVR'
      });
    }
  };
}

export function updateIvrMap(ivrMap: Partial<IvrMap>) {
  dispatch(slice.actions.startNewLoading('ivr'));
  return async () => {
    try {
      await axios.put('/ivr/maps/' + ivrMap.id, ivrMap);
      dispatch(slice.actions.endNewLoading('ivr'));
      return await Promise.resolve({
        defaultSuccessMessage: 'IVR updated'
      });
    } catch (error: any) {
      dispatch(slice.actions.endNewLoading('ivr'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Error updating IVR'
      });
    }
  };
}

export function deleteIvrMaps(ids: number[]) {
  dispatch(slice.actions.startNewLoading('ivr'));
  return async () => {
    try {
      await Promise.all(ids.map((id) => axios.delete('/ivr/maps/' + id)))
        .then(() => Promise.resolve())
        .catch((error: any) => Promise.reject(error));
      dispatch(slice.actions.endNewLoading('ivr'));
      return await Promise.resolve({
        defaultSuccessMessage: 'IVR deleted'
      });
    } catch (error: any) {
      dispatch(slice.actions.endNewLoading('ivr'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Error deleting IVR'
      });
    }
  };
}

export function getSenders() {
  dispatch(slice.actions.startNewLoading('senders'));
  return async () => {
    try {
      const response = await axios.get('/organization/senders');
      dispatch(slice.actions.getSendersSuccess(response.data));
      dispatch(slice.actions.endNewLoading('senders'));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Fetched senders'
      });
    } catch (error: any) {
      dispatch(slice.actions.endNewLoading('senders'));
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch senders'
      });
    }
  };
}

export function addSenders(senders: Sender[]) {
  dispatch(slice.actions.startNewLoading('addSenders'));
  return async () => {
    try {
      await axios.post('/organization/senders', senders);
      dispatch(slice.actions.addSendersSuccess());
      dispatch(slice.actions.endNewLoading('addSenders'));
      return await Promise.resolve({
        defaultSuccessMessage: `Sender${senders.length > 1 ? 's' : ''} added`
      });
    } catch (error: any) {
      dispatch(slice.actions.endNewLoading('addSenders'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not add sender${senders.length > 1 ? 's' : ''}`
      });
    }
  };
}

export function updateSender(updatedSender: Sender) {
  dispatch(slice.actions.startNewLoading('updateSender'));
  return async () => {
    try {
      await axios.put(`/organization/senders/${updatedSender.id}`, updatedSender);
      dispatch(slice.actions.updateSenderSuccess());
      dispatch(slice.actions.endNewLoading('updateSender'));
      return await Promise.resolve({
        defaultSuccessMessage: `Sender updated`
      });
    } catch (error: any) {
      dispatch(slice.actions.endNewLoading('updateSender'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not update sender`
      });
    }
  };
}

export function deleteSender(senderId: number) {
  dispatch(slice.actions.startNewLoading('deleteSender'));
  return async () => {
    try {
      await axios.delete(`/organization/senders/${senderId}`);
      dispatch(slice.actions.deleteSenderSuccess());
      dispatch(slice.actions.endNewLoading('deleteSender'));
      return await Promise.resolve({
        defaultSuccessMessage: `Sender deleted`
      });
    } catch (error: any) {
      dispatch(slice.actions.endNewLoading('deleteSender'));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not delete sender`
      });
    }
  };
}

export function getOrganizations() {
  return async () => {
    dispatch(slice.actions.startNewLoading('organizations'));
    try {
      const response = await axios.get('/user/organizations');
      dispatch(
        slice.actions.getOrganizationsSuccess(
          response.data?.sort(function (a: Organisation, b: Organisation) {
            const createdA = a?.createDateTime ?? '';
            const createdB = b?.createDateTime ?? '';
            return createdA < createdB ? 1 : createdA > createdB ? -1 : 0;
          }) || []
        )
      );
      dispatch(slice.actions.endNewLoading('organizations'));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Organizations fetched'
      });
    } catch (error: any) {
      dispatch(slice.actions.endNewLoading('organizations'));
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Organization was not fetched'
      });
    }
  };
}

export function addOrganization(newOrg: NewOrganization) {
  return async () => {
    dispatch(slice.actions.startNewLoading('addOrganization'));
    try {
      const response = await axios.post('user/organization', newOrg);
      dispatch(slice.actions.endNewLoading('addOrganization'));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Organization added'
      });
    } catch (error: any) {
      dispatch(slice.actions.endNewLoading('addOrganization'));
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Organization was not added'
      });
    }
  };
}

export function getCallDurationLimit() {
  return async () => {
    dispatch(slice.actions.startNewLoading('getCallDurationLimit'));
    try {
      const response = await axios.get(`/organization/calldurations`);

      dispatch(slice.actions.setCallDurationLimit(response.data));
      dispatch(slice.actions.endNewLoading('getCallDurationLimit'));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Fecthed minimum call duration'
      });
    } catch (error: any) {
      dispatch(slice.actions.endNewLoading('getCallDurationLimit'));
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch minimum call duration'
      });
    }
  };
}

type SetCallDurationLimitProps = {
  seconds: number;
};
export function setCallDurationLimit({ seconds }: SetCallDurationLimitProps) {
  return async () => {
    dispatch(slice.actions.startNewLoading('setCallDurationLimit'));
    try {
      const response = await axios.put(`/organization/calldurations?seconds=${seconds}`);
      dispatch(slice.actions.endNewLoading('setCallDurationLimit'));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage:
          seconds > 0 ? 'Updated minimum call duration' : 'Disabled minimum call duration'
      });
    } catch (error: any) {
      dispatch(slice.actions.endNewLoading('setCallDurationLimit'));
      return Promise.reject({
        error: error,
        defaultErrorMessage:
          seconds > 0
            ? 'Could not update minimum call duration'
            : 'Could not disable minimum call duration'
      });
    }
  };
}

export function getAutomaticAnonymization() {
  return async () => {
    dispatch(slice.actions.startNewLoading('getAutomaticAnonymization'));
    try {
      const response = await axios.get(`/organization/anonymization`);

      dispatch(slice.actions.setAnonymizationConfiguration(response.data));
      dispatch(slice.actions.endNewLoading('getAutomaticAnonymization'));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Fecthed automatic anonymization'
      });
    } catch (error: any) {
      dispatch(slice.actions.endNewLoading('getAutomaticAnonymization'));
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch automatic anonymization'
      });
    }
  };
}

type SetAutomaticAnonymizationProps = {
  anonymizeAfterDays?: number | null;
  anonymizeEmployees: boolean;
  anonymizeCompanies: boolean;
  anonymizeUsers: boolean;
};
export function setAutomaticAnonymization({
  anonymizeAfterDays,
  anonymizeCompanies,
  anonymizeEmployees,
  anonymizeUsers
}: SetAutomaticAnonymizationProps) {
  return async () => {
    dispatch(slice.actions.startNewLoading('setAutomaticAnonymization'));
    try {
      const response = await axios.put(`/organization/anonymization`, {
        anonymizeAfterDays,
        anonymizeCompanies,
        anonymizeEmployees,
        anonymizeUsers
      });
      dispatch(slice.actions.endNewLoading('setAutomaticAnonymization'));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: anonymizeAfterDays
          ? 'Updated automatic anonymization'
          : 'Disabled automatic anonymization'
      });
    } catch (error: any) {
      dispatch(slice.actions.endNewLoading('setAutomaticAnonymization'));
      return Promise.reject({
        error: error,
        defaultErrorMessage: anonymizeAfterDays
          ? 'Could not update automatic anonymization'
          : 'Could not disable automatic anonymization'
      });
    }
  };
}

type UpdateActiveStatesProps = {
  organizationIds: string[];
};
export function updateActiveStates({ organizationIds }: UpdateActiveStatesProps) {
  return async () => {
    dispatch(slice.actions.startNewLoading('updateActiveStates'));
    try {
      const response = await axios.put(`/organizations/active-status`, {
        organizationIds: organizationIds
      });
      dispatch(slice.actions.endNewLoading('updateActiveStates'));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Updated organization active states'
      });
    } catch (error: any) {
      dispatch(slice.actions.endNewLoading('updateActiveStates'));
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not update organization active states'
      });
    }
  };
}
