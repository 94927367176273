import { Stack, TextField, Typography } from '@mui/material';
import { License } from '../../../@types/license';
import CustomButton from 'components/util/CustomButton';
import { IntegerInput } from 'components/util/input/IntegerInput';
import { useFormik } from 'formik';
import { useLicense } from 'hooks/useLicense';
import { FunctionComponent, useState } from 'react';
import { LinkLicense } from './LinkLicense';
import { useTranslator } from 'translation/useTranslator';
import { SelectItem } from 'components/util/select/selectData';

interface AdminLicenseProps {
  editable: boolean;
  onSave: () => void;
}

const AdminLicense: FunctionComponent<AdminLicenseProps> = ({ editable, onSave }) => {
  const translator = useTranslator();
  const { license, createNewLicense, editLicense } = useLicense();
  const [selectedLicense, setSelectedLicense] = useState<SelectItem>();
  const [reassigningLicense, setReassigningLicense] = useState(false);

  const formik = useFormik<License>({
    enableReinitialize: true,
    initialValues: {
      seats: license?.seats ?? 0,
      customerVat: license?.customerVat ?? '',
      currency: license?.currency ?? '',
      countryIsoCode: license?.countryIsoCode ?? '',
      customerName: license?.customerName ?? '',
      id: license?.id ?? '',
      totalEmailQuota: license?.totalEmailQuota ?? 0,
      totalSmsQuota: license?.totalSmsQuota ?? 0,
      totalSurveyQuota: license?.totalSurveyQuota ?? 0,
      totalEmailUsage: license?.totalEmailUsage ?? 0,
      totalSmsUsage: license?.totalSmsUsage ?? 0,
      totalSurveyUsage: license?.totalSurveyUsage ?? 0,
      totalTier1Usage: license?.totalTier1Usage ?? 0,
      totalTier2Usage: license?.totalTier2Usage ?? 0,
      totalTier3Usage: license?.totalTier3Usage ?? 0,
      totalTier4Usage: license?.totalTier4Usage ?? 0
    },
    onSubmit: async (values, { setSubmitting }) => {
      let id = license?.id;
      try {
        setSubmitting(true);
        if (id) {
          editLicense({ license: values });
        } else {
          createNewLicense({ license: values });
        }
        onSave();
      } catch (error: any) {
        //messages are handled in the license methods
      }
    }
  });

  const { errors, touched, dirty, getFieldProps, submitForm, setFieldValue, isSubmitting } = formik;

  return (
    <Stack gap={3}>
      {!license && !editable ? (
        <LinkLicense selectedLicense={selectedLicense} onChange={(i) => setSelectedLicense(i)} />
      ) : (
        <>
          <Stack flexDirection={'row'} width={'100%'} justifyContent={'space-between'}>
            <Typography variant="subtitle1">{translator.licenseDetails()}</Typography>
            <CustomButton
              variant="contained"
              onClick={() => setReassigningLicense((prev) => !prev)}
            >
              {reassigningLicense ? translator.cancel() : translator.reassignLicense()}
            </CustomButton>
          </Stack>
          {reassigningLicense ? (
            <LinkLicense
              selectedLicense={selectedLicense}
              isReassignment={reassigningLicense}
              onChange={(i) => setSelectedLicense(i)}
              onSave={() => setReassigningLicense(false)}
            />
          ) : (
            <>
              <TextField
                required
                fullWidth
                label={translator.customerName()}
                {...getFieldProps('customerName')}
                error={Boolean(touched.customerName && errors.customerName)}
                helperText={touched.customerName && errors.customerName}
                disabled={!editable}
              />
              <TextField
                required
                fullWidth
                label={translator.vatNumber()}
                {...getFieldProps('customerVat')}
                error={Boolean(touched.customerVat && errors.customerVat)}
                helperText={touched.customerVat && errors.customerVat}
                disabled={!editable}
              />
              <TextField
                required
                fullWidth
                label={translator.countryCode()}
                {...getFieldProps('countryIsoCode')}
                error={Boolean(touched.countryIsoCode && errors.countryIsoCode)}
                helperText={touched.countryIsoCode && errors.countryIsoCode}
                disabled={!editable}
              />
              <TextField
                required
                fullWidth
                label={translator.currency()}
                {...getFieldProps('currency')}
                error={Boolean(touched.currency && errors.currency)}
                helperText={touched.currency && errors.currency}
                disabled={!editable}
              />
              <IntegerInput
                required
                label={translator.seats()}
                value={getFieldProps('seats').value}
                onChange={(v) => setFieldValue('seats', v)}
                error={Boolean(touched.seats && errors.seats)}
                helperText={touched.seats ? errors.seats : ''}
                disabled={!editable}
              />
              {editable && (
                <CustomButton
                  onClick={submitForm}
                  disabled={!dirty || isSubmitting}
                  variant="contained"
                >
                  {translator.save()}
                </CustomButton>
              )}
            </>
          )}
        </>
      )}
    </Stack>
  );
};

export default AdminLicense;
