import { Divider, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export type MessageLines = string[];
export type DetailSegements = MessageLines[];

export type Message = {
  title: string;
  description?: string;
  detailSegements?: DetailSegements;
};

type GetMessageContentProps = {
  content: Message;
};
const MessageContent = ({ content }: GetMessageContentProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: isMobile ? '100%' : '20rem'
      }}
    >
      <Typography style={{ fontSize: '1.2rem' }} color={theme.palette.primary.main}>
        {content.title}
      </Typography>
      {content.description && (
        <Typography style={{ fontSize: '1rem' }}>{content.description}</Typography>
      )}

      {content.detailSegements && content.detailSegements.length > 0 && (
        <>
          <Divider style={{ marginBlock: '.5rem' }} />
          <Typography style={{ fontSize: '.8rem' }}>Further details:</Typography>
          {content.detailSegements.map((segment, index) => (
            <div key={index}>
              {segment?.map((line, index) => (
                <Typography key={index} style={{ fontSize: '.8rem' }}>
                  {line}
                </Typography>
              ))}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default MessageContent;
