import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { getOrganizations, updateActiveStates } from 'redux/slices/organization';
import { RootState, useSelector } from 'redux/store';
import { Avatar } from '@mui/material';
import {
  CustomDataGrid,
  CustomGridToolbar,
  CustomLoadingOverlay,
  CustomPagination
} from 'components/datagrid/Custom';
import { GridColDef } from '@mui/x-data-grid';
import { fDate } from 'utils/formatTime';
import useCustomDispatch from 'redux/dispatch';
import { DataGridKey } from 'redux/slices/datagrid';
import { ActiveListItem } from 'components/datagrid/listItems/ActiveListItem';
import { OrganizationActiveStateSummary } from './OrganizationActiveStateSummary';
import { OnUpdateActiveStateClick } from '../Admin';
import AreYouSureModal from 'components/util/AreYouSureModal';
import { ITranslator } from 'translation/generated/translationInterface';
import { useTranslator } from 'translation/useTranslator';

let thumbSize = 48;
const columns = (translator: ITranslator): GridColDef[] => {
  return [
    {
      field: 'id',
      headerName: 'id'
    },
    {
      field: 'logoLink',
      headerName: translator.logo(),
      width: 120,
      align: 'center',
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        const url = params.row.logoLink as string;
        if (url) {
          return (
            <Avatar
              imgProps={{ sx: { objectFit: 'contain' } }}
              variant="square"
              src={url}
              sx={{ width: thumbSize, height: thumbSize }}
            />
          );
        }
      }
    },
    {
      field: 'organizationName',
      headerName: translator.name(),
      flex: 1
    },
    {
      field: 'displayName',
      headerName: translator.displayName(),
      flex: 1
    },
    {
      field: 'createDateTime',
      headerName: translator.created(),
      flex: 1,
      renderCell: (params) => {
        const created = params.row.createDateTime as string;
        return fDate(created);
      }
    },
    {
      field: 'active',
      headerName: translator.active(),
      type: 'boolean',
      width: 100,
      renderCell: (params) => {
        const active = params.row.active;
        return <ActiveListItem active={active} activeTooltip="" inactiveTooltip="" />;
      }
    }
  ];
};

interface OrganizationsProps {
  onOrganizationSelect: (selectedOrgs: string[]) => void;
}

const Organizations = forwardRef<OnUpdateActiveStateClick, OrganizationsProps>(
  ({ onOrganizationSelect }, ref) => {
    const translator = useTranslator();
    const customDispatch = useCustomDispatch();
    const { organizations, loading } = useSelector((state: RootState) => state.organization);
    const { density } = useSelector((state: RootState) => state.datagrid);
    const DATA_GRID_KEY = DataGridKey.OrganizationsList;
    const [selectedOrganizations, setSelectedOrganizations] = useState<any[]>([]);
    const [displayModal, setDisplayModal] = useState(false);

    useImperativeHandle(ref, () => ({
      onClickUpdate: () => {
        setDisplayModal(true);
      }
    }));

    useEffect(() => {
      customDispatch({ action: getOrganizations, disableSuccessMessage: true });
    }, [customDispatch]);

    const onUpdate = () => {
      customDispatch({
        action: updateActiveStates,
        actionParameters: { organizationIds: selectedOrganizations },
        onSuccess: () => {
          customDispatch({ action: getOrganizations, disableSuccessMessage: true });
          setDisplayModal(false);
        }
      });
    };

    useEffect(() => {
      if (density[DATA_GRID_KEY] === 'compact') {
        thumbSize = 30;
      } else if (density[DATA_GRID_KEY] === 'standard') {
        thumbSize = 46;
      } else if (density[DATA_GRID_KEY] === 'comfortable') {
        thumbSize = 62;
      }
    }, [density, DATA_GRID_KEY]);

    return (
      <>
        <CustomDataGrid
          dataGridKey={DATA_GRID_KEY}
          columns={columns(translator)}
          rows={organizations.map((organization) => {
            return { ...organization, id: organization.organizationId };
          })}
          getRowId={(org) => org.organizationId}
          loading={loading.organizations}
          checkboxSelection={true}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectedOrganizations(newSelectionModel);
            onOrganizationSelect(newSelectionModel.map((m) => m.toString()));
          }}
          pagination
          components={{
            Toolbar: CustomGridToolbar,
            Pagination: CustomPagination,
            LoadingOverlay: CustomLoadingOverlay
          }}
        />
        <AreYouSureModal
          type="Continue"
          onContinue={onUpdate}
          title={translator.updateOrganizations()}
          display={displayModal}
          description={
            <OrganizationActiveStateSummary
              selectedOrganizations={organizations.filter((o) =>
                selectedOrganizations.includes(o.organizationId)
              )}
            />
          }
          hideModal={() => setDisplayModal(false)}
        />
      </>
    );
  }
);

export default Organizations;
