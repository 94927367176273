import { IconButton, Pagination, styled, Tooltip } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import {
  DataGridPro,
  getGridBooleanOperators,
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridColDef,
  GridColumnVisibilityModel,
  GridDensity,
  GridFilterItem,
  GridLinkOperator,
  GridOverlay,
  gridPageSelector,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueFormatterParams,
  useGridApiContext,
  useGridApiRef,
  useGridSelector
} from '@mui/x-data-grid-pro';
import { GridFilterModel } from '@mui/x-data-grid/models/gridFilterModel';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { DataGridProps } from '@mui/x-data-grid/models/props/DataGridProps';
import FurtherInfo from 'components/util/FurtherInfo';
import { Dispatch, SetStateAction, useEffect, useMemo, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'routes/paths';
import {
  setColumnVisibility,
  setColumnOrder,
  setDensity,
  setFiltering,
  setPinnedColumns,
  setSorting,
  defaultSorting,
  DataGridKey,
  defaultFiltering
} from '../../redux/slices/datagrid';
import { RootState, useSelector } from '../../redux/store';
import { useTheme } from '@mui/material/styles';
import { useCustomDispatchWithoutPromise } from 'redux/dispatch';
import Icon from 'components/icons/Icon';

export const CustomGridToolbar = ({
  dataGridKey,
  setGridDensity,
  initialFilterModel,
  setFilterModel,
  setSortModel,
  intitialColumnsHidden,
  setRerenderColumns,
  setUpdateDataGrid
}: {
  dataGridKey?: DataGridKey;
  setGridDensity?: Dispatch<SetStateAction<GridDensity | undefined>>;

  initialFilterModel?: GridFilterModel;
  setFilterModel?: Dispatch<SetStateAction<GridFilterModel | undefined>>;

  setSortModel?: Dispatch<SetStateAction<GridSortModel | undefined>>;

  intitialColumnsHidden?: GridColumnVisibilityModel;

  setRerenderColumns?: Dispatch<SetStateAction<any>>;

  setUpdateDataGrid?: Dispatch<SetStateAction<any>>;
}) => {
  const customDispatch = useCustomDispatchWithoutPromise();
  const onClickResetTable = () => {
    //filtering
    const newFilterModel =
      initialFilterModel ||
      (dataGridKey
        ? { ...defaultFiltering[dataGridKey] }
        : {
            items: [],
            linkOperator: GridLinkOperator.And,
            quickFilterLogicOperator: GridLinkOperator.And,
            quickFilterValues: []
          });

    setFilterModel?.(newFilterModel);
    customDispatch({
      action: setFiltering,
      actionParameters: [dataGridKey, newFilterModel],
      disableSuccessMessage: true
    });

    //sorting
    const newSortModel = dataGridKey ? defaultSorting[dataGridKey] : [];
    setSortModel?.(newSortModel);
    customDispatch({
      action: setSorting,
      actionParameters: [dataGridKey, newSortModel],
      disableSuccessMessage: true
    });

    //density
    setGridDensity?.('standard');
    customDispatch({
      action: setDensity,
      actionParameters: [dataGridKey, 'standard'],
      disableSuccessMessage: true
    });

    //pined columns
    customDispatch({
      action: setPinnedColumns,
      actionParameters: [dataGridKey, undefined],
      disableSuccessMessage: true
    });

    //column visibility
    customDispatch({
      action: setColumnVisibility,
      actionParameters: [dataGridKey, intitialColumnsHidden || undefined],
      disableSuccessMessage: true
    });

    //column order
    customDispatch({
      action: setColumnOrder,
      actionParameters: [dataGridKey, undefined],
      disableSuccessMessage: true
    });

    //rerender columns
    setRerenderColumns?.((prevState: any) => !prevState);

    setUpdateDataGrid?.((prevState: number) => prevState + 1);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <CustomGridToolbarExport />
      {dataGridKey && (
        <Tooltip
          title={
            <div style={{ maxWidth: '10rem' }}>
              <span>Reset all table filters, columns and sorting to its default state.</span>
            </div>
          }
        >
          <ResetTableSettingsButton onClick={onClickResetTable}>
            <Icon type="reset" />
          </ResetTableSettingsButton>
        </Tooltip>
      )}
    </GridToolbarContainer>
  );
};

const CustomGridToolbarExport = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const navigateToReportsPage = () => {
    navigate(`${PATH_DASHBOARD.reports.root}`);
  };

  return (
    <GridToolbarExport
      endIcon={
        <FurtherInfo
          description={
            <span>
              Only currently visible data will be exported. If this is not sufficient please build
              and download a report from the{' '}
              <a
                rel="noreferrer"
                href="#/"
                onClick={navigateToReportsPage}
                style={{ color: theme.palette.primary.main }}
              >
                Reports
              </a>{' '}
              page
            </span>
          }
        />
      }
    />
  );
};

const ResetTableSettingsButton = styled(IconButton)({
  marginLeft: 'auto'
});

export function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

type CustomPaginationProps = {
  onPageChange?: (newPageNumber: number) => void;
};
export const CustomPagination = ({ onPageChange }: CustomPaginationProps) => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const rowsMatchingFilter = apiRef.current.getVisibleRowModels().size;
  const pageSize = apiRef.current.state.pagination.pageSize;
  const totalPages = Math.ceil(rowsMatchingFilter / pageSize);

  useEffect(() => {
    if (page + 1 > totalPages) {
      apiRef.current.setPage(0);
    }
  }, [page, totalPages, apiRef]);

  return (
    <Pagination
      color="primary"
      count={totalPages}
      page={page + 1} //when filtering, and on a page that is no longer avilable, gå to first page
      onChange={(event, value) => {
        const newPage = value - 1;
        if (onPageChange) {
          onPageChange(newPage);
        }
        apiRef.current.setPage(newPage);
      }}
    />
  );
};

const gridStringOperators = getGridStringOperators()?.map((operator) => operator.value) || [];
const gridNumericOperators = getGridNumericOperators()?.map((operator) => operator.label) || [];
const gridDateOperators = getGridDateOperators()?.map((operator) => operator.value) || [];

const getFilterModelItemType = (operatorValue?: string) => {
  //string or date
  if (operatorValue === 'isEmpty' || operatorValue === 'isNotEmpty') {
    return 'stringOrDate';
  }

  //string
  if (!operatorValue || gridStringOperators.includes(operatorValue)) {
    return 'string';
  }
  //number
  if (gridNumericOperators.includes(operatorValue)) {
    return 'number';
  }
  //date
  if (gridDateOperators.includes(operatorValue)) {
    return 'date';
  }
};

export type CustomDataGridProps = {
  dataGridKey: DataGridKey;
  initialFilterModel?: GridFilterModel;
  intitialColumnsHidden?: GridColumnVisibilityModel;
  height?: string;
  other?: any;
};

const getGridDensityInPixels = (density?: GridDensity) => {
  switch (density) {
    case 'compact':
      return 36;
    case 'comfortable':
      return 67;
    case 'standard':
    default:
      return 52;
  }
};

const getValidFilter = (filterModel: GridFilterModel, columns: GridColDef[]) => {
  let validFilterItems: GridFilterItem[] = [];

  if (!filterModel?.items) return;

  //loop through filter value
  filterModel.items.forEach((filterItem) => {
    const column = columns.find((column) => column.field === filterItem.columnField);
    const filterItemType =
      column?.type === 'singleSelect'
        ? 'singleSelect'
        : column?.type === 'boolean'
        ? 'boolean'
        : getFilterModelItemType(filterItem.operatorValue);

    //if the column does not exsists
    if (!column) {
      return;
    }

    //filtertype is not string with no specific type on the column (string is default type on columns)
    if (filterItemType !== 'string' && filterItemType !== 'stringOrDate' && !column?.type) {
      return;
    }

    //if the filtertype is not what the column type is, or
    if (
      (filterItemType !== 'stringOrDate' &&
        filterItemType !== column?.type &&
        filterItemType !== 'string') ||
      (filterItemType === 'string' && column?.type) ||
      (filterItemType === 'stringOrDate' &&
        filterItem.operatorValue !== 'isEmpty' &&
        filterItem.operatorValue !== 'isNotEmpty')
    ) {
      return;
    }

    //filterItem is valid
    validFilterItems.push({ ...filterItem, operatorValue: filterItem.operatorValue });
  });

  return { ...filterModel, items: [...validFilterItems] };
};

/// A Data grid that saves its state in localstorage
export function CustomDataGrid({
  dataGridKey,
  columns,
  initialFilterModel,
  intitialColumnsHidden,
  height,
  ...other
}: CustomDataGridProps & DataGridProps) {
  const customDispatch = useCustomDispatchWithoutPromise();
  const { density, sorting, filtering, columnVisibility, pinnedColumns, columnOrder } = useSelector(
    (state: RootState) => state.datagrid
  );
  const [rerenderColumns, setRerenderColumns] = useState(0);
  const ref = useGridApiRef();

  //create columnsVisibilityModel
  let columnsVisibilyToUse = {};
  //if no visibility is set, use initial visibility (if any)
  if (columnVisibility[dataGridKey]) {
    columnsVisibilyToUse = { ...columnVisibility[dataGridKey] };
  } else {
    columnsVisibilyToUse = { ...intitialColumnsHidden };
  }
  //if no id is not specifically set to true, hide
  columnsVisibilyToUse = {
    ...columnsVisibilyToUse,
    id: columnVisibility[dataGridKey]?.id || false
  };

  const memorizedColumns = useMemo(
    () =>
      columns.map((col) => {
        if (col.type === 'number') {
          return {
            ...col,
            filterOperators: getGridNumericOperators().filter(
              (operator) => operator.value !== 'isAnyOf'
            ),
            valueFormatter: (params: GridValueFormatterParams<any>) => params.value
          };
        }
        if (!col.type) {
          return {
            ...col,
            filterOperators: getGridStringOperators().filter(
              (operator) => operator.value !== 'isAnyOf'
            )
          };
        }
        return {
          ...col
        };
      }),
    [columns]
  );

  return (
    <StyledDataGridPro
      autoHeight={!Boolean(height)}
      style={{ height: height || 'initial' }}
      key={rerenderColumns}
      columns={memorizedColumns}
      minHeight={getGridDensityInPixels(density[dataGridKey])}
      getRowHeight={() => 'auto'}
      apiRef={ref}
      density={density[dataGridKey] ?? 'standard'}
      initialState={{
        columns: {
          columnVisibilityModel: columnsVisibilyToUse,
          orderedFields: columnOrder[dataGridKey]
        },
        sorting: {
          sortModel: sorting[dataGridKey]
        },
        filter: {
          filterModel: getValidFilter(filtering[dataGridKey], columns)
        }
      }}
      onStateChange={(v) => {
        customDispatch({
          action: setDensity,
          actionParameters: [dataGridKey, v.density.value],
          disableSuccessMessage: true
        });
      }}
      onSortModelChange={(model) => {
        customDispatch({
          action: setSorting,
          actionParameters: [dataGridKey, model],
          disableSuccessMessage: true
        });
      }}
      onFilterModelChange={(model) => {
        if (model.items.length === 0) {
          model.items = defaultFiltering[dataGridKey].items;
        }
        customDispatch({
          action: setFiltering,
          actionParameters: [dataGridKey, model],
          disableSuccessMessage: true
        });
      }}
      onColumnVisibilityModelChange={(model) => {
        customDispatch({
          action: setColumnVisibility,
          actionParameters: [dataGridKey, model],
          disableSuccessMessage: true
        });
      }}
      onColumnOrderChange={() => {
        const allColumns = ref.current.getAllColumns();
        const newColumnOrder = allColumns.map((column) => column.field);
        customDispatch({
          action: setColumnOrder,
          actionParameters: [dataGridKey, newColumnOrder],
          disableSuccessMessage: true
        });
      }}
      pinnedColumns={{
        left: pinnedColumns[dataGridKey]?.left,
        right: pinnedColumns[dataGridKey]?.right
      }}
      onPinnedColumnsChange={(pinnedColumns) => {
        customDispatch({
          action: setPinnedColumns,
          actionParameters: [dataGridKey, pinnedColumns],
          disableSuccessMessage: true
        });
      }}
      {...other}
      components={{
        Toolbar: () => (
          <CustomGridToolbar
            dataGridKey={dataGridKey}
            initialFilterModel={initialFilterModel}
            intitialColumnsHidden={intitialColumnsHidden}
            setRerenderColumns={setRerenderColumns}
          />
        ),
        Pagination: CustomPagination,
        LoadingOverlay: CustomLoadingOverlay
      }}
    />
  );
}

export type CustomServerDataGridProps = {
  setUpdateDataGrid?: Dispatch<SetStateAction<number>>;
  dataGridKey: DataGridKey;
  intitialColumnsHidden?: GridColumnVisibilityModel;
  initialFilterModel?: GridFilterModel;
  height?: string;
  other?: any;
  modifySortModelOnChange?: (newModel: GridSortModel) => GridSortModel;
  modifyFilterModelOnChange?: (newModel: GridFilterModel) => GridFilterModel;
};

export function CustomServerDataGrid({
  setUpdateDataGrid,
  dataGridKey,
  columns,
  intitialColumnsHidden,
  initialFilterModel,
  height,
  modifySortModelOnChange = (sortModel) => sortModel,
  modifyFilterModelOnChange = (filterModel) => filterModel,
  ...other
}: CustomServerDataGridProps & DataGridProps) {
  const customDispatch = useCustomDispatchWithoutPromise();
  const ref = useGridApiRef();
  const { density, sorting, filtering, columnVisibility, pinnedColumns, columnOrder } = useSelector(
    (state: RootState) => state.datagrid
  );
  //to ensure the density is only updated once, use local state for grid density, and update Redux state after
  const lastUsedDataGridKeyRef = useRef(dataGridKey);
  const [gridDensity, setGridDensity] = useState<GridDensity | undefined>(density[dataGridKey]);
  const [sortModel, setSortModel] = useState<GridSortModel | undefined>(sorting[dataGridKey]);
  const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>(
    filtering[dataGridKey]
  );

  //if the datagridkey is changed, update density, filtering, and sorting
  useEffect(() => {
    if (lastUsedDataGridKeyRef.current === dataGridKey) return;
    setGridDensity(density[dataGridKey]);
    setSortModel(sorting[dataGridKey]);
    setFilterModel(filtering[dataGridKey]);
    lastUsedDataGridKeyRef.current = dataGridKey;
  }, [dataGridKey, density, filtering, sorting]);

  useEffect(() => {
    if (gridDensity !== density[dataGridKey]) {
      customDispatch({
        action: setDensity,
        actionParameters: [dataGridKey, gridDensity],
        disableSuccessMessage: true
      });
    }
  }, [gridDensity, density, dataGridKey, customDispatch]);

  useEffect(() => {
    if (JSON.stringify(sortModel) !== JSON.stringify(sorting[dataGridKey])) {
      if (lastUsedDataGridKeyRef.current !== dataGridKey) return;
      customDispatch({
        action: setSorting,
        actionParameters: [dataGridKey, sortModel],
        disableSuccessMessage: true
      });
      setUpdateDataGrid?.((count) => count + 1);
    }
  }, [sortModel, sorting, dataGridKey, setUpdateDataGrid, customDispatch]);

  useEffect(() => {
    if (JSON.stringify(filterModel) !== JSON.stringify(filtering[dataGridKey])) {
      if (lastUsedDataGridKeyRef.current !== dataGridKey) return;
      customDispatch({
        action: setFiltering,
        actionParameters: [dataGridKey, filterModel],
        disableSuccessMessage: true
      });
      setUpdateDataGrid?.((count) => count + 1);
    }
  }, [filterModel, filtering, dataGridKey, setUpdateDataGrid, customDispatch]);

  //create columnsVisibilityModel
  let columnsVisibilyToUse = {};
  //if no visibility is set, use initial visibility (if any)
  if (columnVisibility[dataGridKey]) {
    columnsVisibilyToUse = { ...columnVisibility[dataGridKey] };
  } else {
    columnsVisibilyToUse = { ...intitialColumnsHidden };
  }
  //if no id is not specifically set to true, hide
  columnsVisibilyToUse = {
    ...columnsVisibilyToUse,
    id: columnVisibility[dataGridKey]?.id || false
  };

  const [rerenderColumns, setRerenderColumns] = useState(0);

  //remove filteroperator isAnyOf
  const modifiedColumns = useMemo(
    () =>
      columns.map((col) => {
        if (col.type === 'singleSelect') {
          return {
            ...col,
            filterOperators: getGridSingleSelectOperators().filter(
              (operator) => operator.value === 'is'
            )
          };
        }
        if (col.type === 'number') {
          return {
            ...col,
            filterOperators: getGridNumericOperators().filter(
              (operator) => operator.value !== 'isAnyOf'
            ),
            valueFormatter: (params: GridValueFormatterParams<any>) => params.value
          };
        }
        if (!col.type) {
          return {
            ...col,
            filterOperators: getGridStringOperators().filter(
              (operator) => operator.value !== 'isAnyOf'
            )
          };
        }
        if (col.type === 'boolean') {
          return {
            ...col,
            filterOperators: getGridBooleanOperators()
          };
        }

        return {
          ...col
        };
      }),
    [columns]
  );

  const previousFilterModelRef = useRef<GridFilterModel>();

  const handleFilterModelChange = (newModel: GridFilterModel) => {
    if (JSON.stringify(newModel) === JSON.stringify(filterModel)) return;
    if (lastUsedDataGridKeyRef.current !== dataGridKey) return;

    const prevModel = previousFilterModelRef.current;

    if (prevModel && prevModel.items.length === newModel.items.length) {
      newModel.items.forEach((item, index) => {
        const prevItem = prevModel.items[index];
        //if there was a prevous column selected, and the column was changed, reset value to avoid type conflicts
        if (prevItem && prevItem.columnField !== item.columnField && item.value !== undefined) {
          item.value = undefined;
        }
      });
    }

    previousFilterModelRef.current = newModel;
    setFilterModel(newModel);
  };

  return (
    <StyledDataGridPro
      autoHeight={!Boolean(height)}
      style={{ height: height || 'initial' }}
      key={rerenderColumns}
      columns={modifiedColumns}
      minHeight={getGridDensityInPixels(gridDensity)}
      getRowHeight={() => 'auto'}
      apiRef={ref}
      initialState={{
        columns: {
          orderedFields: columnOrder[dataGridKey]
        }
      }}
      density={gridDensity ?? 'standard'}
      onStateChange={async (v) => {
        if (lastUsedDataGridKeyRef.current !== dataGridKey) return;
        setGridDensity(v.density.value);
      }}
      sortingMode="server"
      sortModel={sorting[dataGridKey]}
      onSortModelChange={async (model) => {
        if (lastUsedDataGridKeyRef.current !== dataGridKey) return;
        setSortModel(modifySortModelOnChange(model));
      }}
      filterMode="server"
      filterModel={getValidFilter(filtering[dataGridKey], columns)}
      onFilterModelChange={async (model) => {
        if (model.items.length === 0) {
          model.items = defaultFiltering[dataGridKey].items;
        }
        handleFilterModelChange(modifyFilterModelOnChange(model));
      }}
      columnVisibilityModel={columnsVisibilyToUse}
      onColumnVisibilityModelChange={(model) => {
        customDispatch({
          action: setColumnVisibility,
          actionParameters: [dataGridKey, model],
          disableSuccessMessage: true
        });
      }}
      onColumnOrderChange={() => {
        const allColumns = ref.current.getAllColumns();
        const newColumnOrder = allColumns.map((column) => column.field);
        customDispatch({
          action: setColumnOrder,
          actionParameters: [dataGridKey, newColumnOrder],
          disableSuccessMessage: true
        });
      }}
      onPinnedColumnsChange={(pinnedColumns) => {
        customDispatch({
          action: setPinnedColumns,
          actionParameters: [dataGridKey, pinnedColumns],
          disableSuccessMessage: true
        });
      }}
      pinnedColumns={{
        left: pinnedColumns[dataGridKey]?.left,
        right: pinnedColumns[dataGridKey]?.right
      }}
      {...other}
      components={{
        Toolbar: () => (
          <CustomGridToolbar
            dataGridKey={dataGridKey}
            setGridDensity={setGridDensity}
            initialFilterModel={initialFilterModel}
            setFilterModel={setFilterModel}
            setSortModel={setSortModel}
            intitialColumnsHidden={intitialColumnsHidden}
            setRerenderColumns={setRerenderColumns}
            setUpdateDataGrid={setUpdateDataGrid}
          />
        ),
        LoadingOverlay: CustomLoadingOverlay
      }}
    />
  );
}

const StyledDataGridPro = styled(DataGridPro)(({ minHeight }: { minHeight: number }) => ({
  width: '100%',
  '.MuiDataGrid-row': {
    minHeight: `${minHeight}px !important`
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    whiteSpace: 'normal',
    lineHeight: 'normal'
  },
  '& .MuiDataGrid-columnHeader': {
    // Forced to use important since overriding inline styles
    height: 'unset !important'
  },
  '& .MuiDataGrid-columnHeaders': {
    // Forced to use important since overriding inline styles
    maxHeight: '168px !important'
  }
}));
