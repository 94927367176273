import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number) {
  if (!date) {
    return '';
  }
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fShortDate(date: Date | string | number) {
  if (!date) {
    return '';
  }
  return format(new Date(date), 'dd/MM-yy');
}

export function fDateHyphen(date: Date | string | number) {
  if (!date) {
    return '';
  }
  return format(new Date(date), 'yyyy-MM-dd');
}

export function fDateTime(date: Date | string | number) {
  if (!date) {
    return '';
  }
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fTimestamp(date: Date | string | number) {
  if (!date) {
    return '';
  }
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  if (!date) {
    return '';
  }
  return format(new Date(date), 'dd/MM/yyyy HH:mm');
}

export function fToNow(date: Date | string | number) {
  if (!date) {
    return '';
  }
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

//date is invalid, if it points to before January 1, 1970, at midnight UTC
export function isDateValid(date: Date) {
  const timestamp = new Date(date).getTime();

  return timestamp >= 0;
}

export function addMonthsToDate(date: Date, monthsToAdd: number): Date {
  const newDate = new Date(date);
  const targetMonth = date.getMonth() + monthsToAdd;
  const yearsToAdd = Math.floor(targetMonth / 12);
  const newMonth = targetMonth % 12;

  newDate.setFullYear(date.getFullYear() + yearsToAdd);
  newDate.setMonth(newMonth);

  // To handle cases where the day of the month is out of bounds (e.g., Feb 29),
  // adjust the date to the last day of the previous month.
  newDate.setDate(Math.min(date.getDate(), daysInMonth(newDate.getFullYear(), newMonth + 1)));

  return newDate;
}

export function daysInMonth(year: number, month: number): number {
  return new Date(year, month, 0).getDate();
}

export function getDayDifference(date1: Date, date2: Date): string {
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // One day in milliseconds

  // Calculate the absolute difference in days
  const timeDifference = Math.abs(date2.getTime() - date1.getTime());
  const daysDifference = Math.floor(timeDifference / oneDayInMilliseconds);

  return `${daysDifference}`;
}

export function getDateDifference(
  date1: Date,
  date2: Date
): { type: 'day' | 'month' | 'year'; difference: string } {
  const monthsInAYear = 12;

  const year1 = date1.getFullYear();
  const month1 = date1.getMonth();

  const year2 = date2.getFullYear();
  const month2 = date2.getMonth();

  const totalMonths1 = year1 * monthsInAYear + month1;
  const totalMonths2 = year2 * monthsInAYear + month2;

  // Check if the difference is less than a month
  if (totalMonths2 === totalMonths1) {
    return { type: 'day', difference: getDayDifference(date1, date2) };
  }

  const monthDifference = Math.abs(totalMonths2 - totalMonths1);
  return { type: 'month', difference: `${monthDifference}` };
}

export const getEndDate = (startDate: Date, months: number): string => {
  try {
    const endDate = addMonthsToDate(startDate, months);
    return fShortDate(endDate);
  } catch (error) {
    return `Invalid date: ${startDate}. Error: ${error}`;
  }
};
