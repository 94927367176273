import { Grid } from '@mui/material';
import { Box, Card, CardContent, TextField, Typography } from '@mui/material';
import NPSGauge from 'components/campaignOverview/NPSGauge';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import palette from 'theme/palette';
import './NpsCalculator.css';

const MIN_INPUT_VALUE = 0;
const MAX_INPUT_VALUE = 9999;

// Assuming indexes 0-6 are detractors, 7-8 are passives, 9-10 are promoters
const getTotalResponses = (scores: number[]) => scores.reduce((acc, cur) => acc + cur, 0) ?? 0;
const getTotalDetractors = (scores: number[]) =>
  scores.slice(0, 7).reduce((acc, cur) => acc + cur, 0) ?? 0;
const getTotalPromoters = (scores: number[]) =>
  scores.slice(9).reduce((acc, cur) => acc + cur, 0) ?? 0;
const getTotalPassives = (scores: number[]) =>
  getTotalResponses(scores) - getTotalPromoters(scores) - getTotalDetractors(scores);
const getNpsScore = (scores: number[]) =>
  formatPercentage(
    ((getTotalPromoters(scores) - getTotalDetractors(scores)) / getTotalResponses(scores)) * 100
  );

const formatPercentage = (percentage: number) => (percentage ? Math.round(percentage) : 0);

const NpsCalculatorApp = () => {
  const [nps, setNps] = useState(0);
  // Create state for each score input (0-10)
  const [scores, setScores] = useState<number[]>(Array(11).fill(0));

  //set css variables
  useEffect(() => {
    const root = document.documentElement;

    // Set CSS variables on the root element
    root.style.setProperty('--color-promoter', palette.common.npsScore.promoter);
    root.style.setProperty('--color-passive', palette.common.npsScore.passive);
    root.style.setProperty('--color-detractor', palette.common.npsScore.detractor);

    root.style.setProperty('--color-contrast-text', palette.common.primary.contrastText);
  }, []);

  // Handler for changing score inputs
  const handleScoreChange = (index: number) => (newScore: string) => {
    const newScores = [...scores];
    newScores[index] = parseInt(newScore) || 0;
    setScores(newScores);

    const totalResponses = getTotalResponses(newScores);

    const updatedNps = formatPercentage(
      ((getTotalPromoters(newScores) - getTotalDetractors(newScores)) / totalResponses) * 100
    );

    setNps(updatedNps);
  };

  return (
    <div className="page">
      <HelmetSetup />

      <NPSGauge nps={nps} maxWidth="30rem" />

      <Box className="input-and-explanation-wrapper">
        <div className="input-groups-wrapper">
          <NpsScoreInputGroup type="detractor" scores={scores} onChange={handleScoreChange} />
          <Box className="input-groups-pas-prom">
            <NpsScoreInputGroup type="passive" scores={scores} onChange={handleScoreChange} />
            <NpsScoreInputGroup type="promoter" scores={scores} onChange={handleScoreChange} />
          </Box>
        </div>

        <NpsScoreExplanation scores={scores} />
      </Box>
    </div>
  );
};

export default NpsCalculatorApp;

const HelmetSetup = () => {
  return (
    <Helmet>
      <title>{`nps calculator | nps.today`}</title>
      <meta
        name="description"
        content="Use our free NPS Calculator to measure customer loyalty and satisfaction."
      />
      <meta
        name="keywords"
        content="NPS, Net Promoter Score, customer loyalty, customer satisfaction, calculator, NPS calculator"
      />
    </Helmet>
  );
};

type NpsType = 'detractor' | 'passive' | 'promoter';
type NpsScoreInputGroupProps = {
  type: NpsType;
  scores: number[];
  onChange: (index: number) => (newScore: string) => void;
};
const NpsScoreInputGroup = ({ type, scores, onChange }: NpsScoreInputGroupProps) => {
  // Define the range for each type
  const ranges = {
    detractor: [0, 6],
    passive: [7, 8],
    promoter: [9, 10]
  };

  // Get the correct range based on type
  const [start, end] = ranges[type];

  return (
    <>
      <Card className={`nps-score-group-wrapper`} data-nps-type={type}>
        <div className={`inputs-wrapper`}>
          {scores.slice(start, end + 1).map((score, index) => (
            <NpsScoreInput
              type={type}
              key={start + index} // Use a unique and correct index based on type
              score={start + index} // Set the score as per NPS range
              onChange={onChange} // Ensure correct index is used in the onChange handler
            />
          ))}
        </div>
        <Typography className={`group-description`} variant="h6">{`${
          type.charAt(0).toUpperCase() + type.slice(1)
        }s`}</Typography>
      </Card>
    </>
  );
};

type NpsScoreInputProps = {
  score: number;
  onChange: (index: number) => (newScore: string) => void;
  type: NpsType;
};

const NpsScoreInput = ({ type, score, onChange }: NpsScoreInputProps) => {
  const [amount, setAmount] = useState('');

  const sanitizeInput = (value: string) => {
    const numericValue = parseInt(value, 10);
    if (isNaN(numericValue)) return '';

    // Apply constraints, assuming max is also defined somewhere
    if (numericValue < MIN_INPUT_VALUE) return `${MIN_INPUT_VALUE}`;
    if (numericValue > MAX_INPUT_VALUE) return `${MAX_INPUT_VALUE}`;
    return value;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedValue = sanitizeInput(event.target.value);

    //set local state for the input
    setAmount(sanitizedValue);

    // Handle the new value in the parent component
    onChange(score)(sanitizedValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (['e', '.', '-', '+', 'E'].includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <div>
      <Typography className={`nps-score-input-label nps-color`} data-nps-type={type}>
        {score}
      </Typography>
      <TextField
        value={amount}
        className={`nps-score-input`}
        data-nps-type={type}
        name={`score-${score}`}
        onKeyDown={handleKeyDown}
        type="number"
        variant="outlined"
        onChange={handleChange}
      />
    </div>
  );
};

type NpsScoreExplanationProps = {
  scores: number[];
};

const NpsScoreExplanation = ({ scores }: NpsScoreExplanationProps) => {
  const nps = getNpsScore(scores);
  const totalResponses = getTotalResponses(scores);
  const totalPromoters = getTotalPromoters(scores);
  const totalPassives = getTotalPassives(scores);
  const totalDetractors = getTotalDetractors(scores);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4}>
            <Box className="explanations-wrapper">
              <Box className="explanation-row">
                <Typography>Promoters:</Typography>
                <Typography data-nps-type={'promoter'} className="explanation-value nps-color">
                  {totalPromoters} ({formatPercentage((totalPromoters / totalResponses) * 100)}%)
                </Typography>
              </Box>
              <Box className="explanation-row">
                <Typography>Passives:</Typography>
                <Typography data-nps-type={'passive'} className="explanation-value nps-color">
                  {totalPassives} ({formatPercentage((totalPassives / totalResponses) * 100)}%)
                </Typography>
              </Box>
              <Box className="explanation-row">
                <Typography>Detractors:</Typography>
                <Typography data-nps-type={'detractor'} className="explanation-value nps-color">
                  {totalDetractors} ({formatPercentage((totalDetractors / totalResponses) * 100)}%)
                </Typography>
              </Box>
              <Box className="explanation-row">
                <Typography>Total:</Typography>
                <Typography className="explanation-value">{totalResponses}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <Typography component="div">
              <strong>NPS Formula:</strong>
              <p>NPS = (% Promoters - % Detractors) × 100</p>
              <p>
                NPS = ({formatPercentage((totalPromoters / totalResponses) * 100)} -{' '}
                {formatPercentage((totalDetractors / totalResponses) * 100)}) × 100 = {nps}
              </p>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
