import { FormHelperText, Stack, Typography } from '@mui/material';
import { TimeInput } from 'components/editor/TimeSpanPicker';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import useCustomDispatch from 'redux/dispatch';
import { getAutomaticAnonymization } from 'redux/slices/organization';
import { RootState, useSelector } from 'redux/store';
import * as Yup from 'yup';
import ChangeAutomaticAnonymization from 'pages/account/components/ChangeAutomaticAnonymization';
import { useRegisterUnsavedFormChanges } from 'components/form/useRegisterUnsavedFormChanges';
import SettingsCard from 'components/settings/SettingsCard';
import { useTranslator } from 'translation/useTranslator';
import CustomSwitch from 'components/util/CustomSwitch';

const DataPrivacy = () => {
  const translator = useTranslator();
  const [displayChangeAnonymizeDaysDialog, setDisplayAnonymizeDaysDialog] = useState(false);
  const customDispatch = useCustomDispatch();
  const { anonymizationConfiguration, loading } = useSelector(
    (state: RootState) => state.organization
  );
  useEffect(() => {
    customDispatch({
      action: getAutomaticAnonymization,
      disableSuccessMessage: true
    });
  }, [customDispatch]);

  const DataRetentionSchema = Yup.object().shape({
    enableAnonymizeAfterDays: Yup.boolean(),
    anonymizeAfterDays: Yup.number()
      .nullable()
      .when('enableAnonymizeAfterDays', {
        is: true,
        then: Yup.number().nullable().required(translator.amountOfDaysRequired())
      })
  });

  type DataRetention = {
    enableAnonymizeAfterDays: boolean;
    anonymizeAfterDays?: number | null;
    anonymizeEmployees: boolean;
    anonymizeCompanies: boolean;
    anonymizeUsers: boolean;
  };

  const formik = useFormik<DataRetention>({
    enableReinitialize: true,
    initialValues: {
      enableAnonymizeAfterDays: Boolean(anonymizationConfiguration?.anonymizeAfterDays),
      anonymizeAfterDays: anonymizationConfiguration?.anonymizeAfterDays,
      anonymizeEmployees: anonymizationConfiguration?.anonymizeEmployees ?? false,
      anonymizeCompanies: anonymizationConfiguration?.anonymizeCompanies ?? false,
      anonymizeUsers: anonymizationConfiguration?.anonymizeUsers ?? false
    },
    validationSchema: DataRetentionSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setDisplayAnonymizeDaysDialog(true);
    }
  });

  const { errors, values, getFieldProps, setFieldValue, setValues, handleSubmit, dirty } = formik;

  useRegisterUnsavedFormChanges(dirty && !displayChangeAnonymizeDaysDialog);

  const getDays = (days?: number | null) => {
    return days ? days % 365 : 0;
  };

  const getYears = (days?: number | null) => {
    return days ? Math.floor(days / 365) : 0;
  };

  type HandleDaysChangeProps = {
    years: number | null;
    days: number | null;
  };
  const handleDaysChange = ({ years, days }: HandleDaysChangeProps) => {
    if (years === null) {
      years = getYears(values.anonymizeAfterDays);
    }
    if (days === null) {
      days = getDays(values.anonymizeAfterDays);
    }
    setFieldValue('anonymizeAfterDays', years * 365 + days || null);
  };

  const onCloseDialog = () => {
    setDisplayAnonymizeDaysDialog(false);
  };

  const anonymizeEmployees = getFieldProps('anonymizeEmployees').value;
  const anonymizeCompanies = getFieldProps('anonymizeCompanies').value;
  const anonymizeUsers = getFieldProps('anonymizeUsers').value;

  return (
    <>
      <FormikProvider value={formik}>
        <Form id="dataPrivacy" noValidate onSubmit={handleSubmit}>
          <SettingsCard
            formik={formik}
            isGetLoading={loading.getAutomaticAnonymization}
            isUpdateLoading={loading.setAutomaticAnonymization}
            switchLabel={translator.automaticAnonymization()}
            switchChecked={Boolean(values.anonymizeAfterDays)}
            switchOnChange={(event: any) => {
              const enabled = event.target.checked;
              setValues({
                anonymizeAfterDays: enabled
                  ? anonymizationConfiguration?.anonymizeAfterDays
                    ? anonymizationConfiguration?.anonymizeAfterDays
                    : 365 * 3
                  : null,
                enableAnonymizeAfterDays: enabled,
                anonymizeCompanies: anonymizeCompanies,
                anonymizeEmployees: anonymizeEmployees,
                anonymizeUsers: anonymizeUsers
              });
            }}
            descriptionParagraphs={[translator.automaticAnonymizationDescription()]}
            additionalFormFieldsWhenSwitchIsOn={[
              <div key="anonymizeAfterDaysInput" style={{ width: 'min-content' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                    width: 'max-content'
                  }}
                >
                  <Stack flexDirection={'row'} gap={'1rem'} width={'100%'} alignItems={'center'}>
                    <Typography>{translator.anonymizeDataAfter()}</Typography>
                    <TimeInput
                      label={translator.years()}
                      value={getYears(values.anonymizeAfterDays)}
                      setValue={(newValue: string | number) => {
                        handleDaysChange({
                          years: typeof newValue === 'string' ? Number(newValue) : newValue,
                          days: null
                        });
                      }}
                      range={5}
                      enableAddMoreOptions
                      disableZero={!Boolean(getDays(values.anonymizeAfterDays))}
                      hasError={Boolean(errors.anonymizeAfterDays)}
                    />
                    <TimeInput
                      label={translator.days()}
                      value={getDays(values.anonymizeAfterDays)}
                      setValue={(newValue: string | number) => {
                        handleDaysChange({
                          years: null,
                          days: typeof newValue === 'string' ? Number(newValue) : newValue
                        });
                      }}
                      range={364}
                      disableZero={!Boolean(getYears(values.anonymizeAfterDays))}
                      hasError={Boolean(errors.anonymizeAfterDays)}
                    />
                  </Stack>
                </div>
                <Stack gap={1} width={'100%'}>
                  <CustomSwitch
                    label={translator.anonymizeEmployees()}
                    switchProps={{
                      checked: anonymizeEmployees,
                      onChange: (_, checked) => setFieldValue('anonymizeEmployees', checked),
                      disabled: false
                    }}
                  />
                  <CustomSwitch
                    label={translator.anonymizeCompanies()}
                    switchProps={{
                      checked: anonymizeCompanies,
                      onChange: (_, checked) => setFieldValue('anonymizeCompanies', checked),
                      disabled: false
                    }}
                  />
                  <CustomSwitch
                    label={translator.anonymizeUsers()}
                    switchProps={{
                      checked: anonymizeUsers,
                      onChange: (_, checked) => setFieldValue('anonymizeUsers', checked),
                      disabled: false
                    }}
                  />
                  <FormHelperText>{translator.anonymizationExplanation()}</FormHelperText>
                </Stack>
                <FormHelperText
                  error={Boolean(errors.anonymizeAfterDays)}
                  style={{ float: 'right' }}
                >
                  {errors.anonymizeAfterDays}
                </FormHelperText>
              </div>
            ]}
            access="ManagementEdit"
          />
        </Form>
      </FormikProvider>
      <ChangeAutomaticAnonymization
        automaticAnonymizationChange={{
          currentAnnonymizeAfterDays: anonymizationConfiguration?.anonymizeAfterDays,
          newAnonymizeAfterDays: values.anonymizeAfterDays || null,
          newAnonymizeEmployees: !values.anonymizeAfterDays ? false : anonymizeEmployees,
          newAnonymizeCompanies: !values.anonymizeAfterDays ? false : anonymizeCompanies,
          newAnonymizeUsers: !values.anonymizeAfterDays ? false : anonymizeUsers,
          currentAnonymizeEmployees: anonymizationConfiguration?.anonymizeEmployees ?? false,
          currentAnonymizeCompanies: anonymizationConfiguration?.anonymizeCompanies ?? false,
          currentAnonymizeUsers: anonymizationConfiguration?.anonymizeUsers ?? false
        }}
        isOpen={displayChangeAnonymizeDaysDialog}
        closeDialog={onCloseDialog}
        formik={formik}
      />
    </>
  );
};

export default DataPrivacy;
