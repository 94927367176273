import { CircularProgress, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import GaugeChart from 'react-gauge-chart';
import palette from 'theme/palette';

type NPSGaugeProps = {
  nps: number | undefined;
  maxWidth?: string;
};

const calculatePercent = (nps: number | undefined) => {
  return ((nps ?? 0) + 100) / 200;
};

export default function NPSGauge({ nps, maxWidth }: NPSGaugeProps) {
  const memoizedGaugeChart = useMemo(
    () => (
      <GaugeChart
        id="NpsGaugheId"
        animDelay={0}
        hideText={true}
        arcsLength={[0.5, 0.15, 0.35]}
        colors={[
          palette.common.npsScore.detractor,
          palette.common.npsScore.passive,
          palette.common.npsScore.promoter
        ]}
        percent={calculatePercent(nps)}
        arcPadding={0.02}
      />
    ),
    [nps]
  );

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ width: 1 }}
      maxWidth={maxWidth}
    >
      {memoizedGaugeChart}
      <Typography variant="h4" sx={{ mb: 0.5 }}>
        {nps || nps === 0 ? nps : <CircularProgress />}
      </Typography>
      <Typography variant="body2" sx={{ opacity: 0.72 }}>
        NPS Score
      </Typography>
    </Stack>
  );
}
