import EditItemDialog from 'components/util/EditItemDialog';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';
import { Sender } from '../../../@types/organization';
import * as Yup from 'yup';
import { addSenders, updateSender } from 'redux/slices/organization';
import useCustomDispatch from 'redux/dispatch';
import { useRegisterUnsavedFormChanges } from 'components/form/useRegisterUnsavedFormChanges';

const SENDER_TYPES = ['SMS', 'Email'];

type AddSenderDialogProps = {
  display: boolean;
  close: () => void;
  fetchSenders: () => void;
  senderToEdit?: Sender;
};
const AddSenderDialog = ({ display, close, senderToEdit, fetchSenders }: AddSenderDialogProps) => {
  const customDispatch = useCustomDispatch();

  const smsSenderRegEx = /^(?=.*[a-zA-Z0-9])(?=.*[a-zA-Z0-9])([a-zA-Z0-9 .]{3,11})$/gm;
  const smsSenderNotContainingDotOrSpaceRegEx = /^((?![\s|.]).)*$/;
  const UpdateUserSchema = Yup.object().shape({
    senderType: Yup.string().required('Type is required'),
    sender: Yup.string()
      .when('senderType', {
        is: 'SMS',
        then: Yup.string().matches(
          smsSenderRegEx,
          'SMS sender is not valid - must contain 3-11 characters and only use a-z, 0-9, dot, and blank space'
        )
      })
      .required('Sender is required')
  });

  const formik = useFormik<Sender>({
    enableReinitialize: true,
    initialValues: {
      sender: senderToEdit?.sender ?? '',
      senderType: senderToEdit?.senderType ?? ''
    },
    validationSchema: UpdateUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const onSuccess = () => {
        //Update senders
        fetchSenders();

        //close dialog
        onClose();
      };
      const onFinally = () => {
        setSubmitting(false);
      };

      setSubmitting(true);
      if (senderToEdit) {
        customDispatch({
          action: updateSender,
          actionParameters: { ...senderToEdit, ...values },
          onSuccess: onSuccess,
          onFinally: onFinally
        });
      } else {
        const newSenders = [values];
        customDispatch({
          action: addSenders,
          actionParameters: newSenders,
          onSuccess: onSuccess,
          onFinally: onFinally
        });
      }
    }
  });

  const {
    submitForm,
    resetForm,
    errors,
    touched,
    dirty,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    values,
    setFieldValue
  } = formik;

  useRegisterUnsavedFormChanges(dirty);

  const onClose = () => {
    close();
    resetForm();
  };

  const isDotOrWhitespaceUsedInSmsSender =
    values.senderType === 'SMS' && !smsSenderNotContainingDotOrSpaceRegEx.test(values.sender);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <EditItemDialog
          itemType="sender"
          editable
          isOpen={display}
          title={senderToEdit ? 'Edit sender' : 'Add new sender'}
          editContent={[
            <TextField
              key="sender"
              fullWidth
              label="Sender"
              {...getFieldProps('sender')}
              required
              error={Boolean(touched.sender && errors.sender)}
              helperText={
                (touched.sender && errors.sender) ||
                (isDotOrWhitespaceUsedInSmsSender &&
                  'Dot and whitespace may not be accepted in some countries for SMS Senders')
              }
            />,
            <FormControl fullWidth required key="senderType">
              <InputLabel id="senderType">Type</InputLabel>
              <Select
                fullWidth
                value={values.senderType}
                onChange={(event: any) => {
                  setFieldValue('senderType', event.target.value);
                }}
                labelId="senderType"
                label="Type"
                error={Boolean(touched.senderType && errors.senderType)}
              >
                {SENDER_TYPES?.map((type: any, index: number) => (
                  <MenuItem key={index} value={type} disabled={type === 'Email'}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
                      {type} {type === 'Email' ? '- not yet implemented' : ''}
                    </div>
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={Boolean(touched.senderType && errors.senderType)}>
                {touched.senderType && errors.senderType}
              </FormHelperText>
            </FormControl>
          ]}
          dirty={dirty}
          isSubmitting={isSubmitting}
          close={() => onClose()}
          resetForm={resetForm}
          submitForm={submitForm}
        />
      </Form>
    </FormikProvider>
  );
};

export default AddSenderDialog;
