import { createSlice } from '@reduxjs/toolkit';
import { getFromToFilterFromTimespan } from 'components/util/datePicker/FromToDatePicker';
import { DashboardState, TimeSpan } from '../../@types/dashboard';
// utils
import axios from '../../utils/axios';
import { dispatch, store } from '../store';

// ----------------------------------------------------------------------
const initialReponseData = { total: 0, skip: 0, take: 6, responses: [] };
const { from: initialFrom, to: initialTo } = getFromToFilterFromTimespan(TimeSpan.LAST_30_DAYS);
export const initialState: DashboardState = {
  lastUpdated: undefined,
  isAnswerListLoading: false,
  isDashboardLoading: false,
  spread: {
    detractors: 0,
    nps: 0,
    passives: 0,
    promoters: 0,
    total: 0
  },
  respondentCount: 0,
  responseData: initialReponseData,
  sortValue: 'RatingTime',
  filterValue: 'none',
  searchRespondentValue: '',
  timespan: TimeSpan.LAST_30_DAYS,
  fromDateFilter: initialFrom,
  toDateFilter: initialTo,
  campaignDropdownKeys: ['activeCampaigns']
};

//only persist timespan, fromDateFilter, toDateFilter
export const dashboardPersistWhitelist = []; //Removed the following, as the persistent state of the timespan causes confusion 'timespan', 'fromDateFilter', 'toDateFilter'

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardGraphs(state, action) {
      state.spread = action.payload.spread;
      state.respondentCount = action.payload.respondentCount;
      state.lastUpdated = new Date().getTime();
    },
    setDashboardAnswersLoading(state, action) {
      state.isAnswerListLoading = action.payload;
    },
    setDashboardAnswers(state, action) {
      state.responseData = { ...action.payload };
      state.isAnswerListLoading = false;
    },
    setAnswerListParams(state, action) {
      const payload = action.payload;
      state.isAnswerListLoading = true;
      if (payload.skip !== undefined) {
        state.responseData.skip = payload.skip;
      }
      if (payload.take !== undefined) {
        state.responseData.take = payload.take;
      }
      if (payload.sortValue !== undefined) {
        state.sortValue = payload.sortValue;
      }
      if (payload.searchRespondentValue !== undefined) {
        state.searchRespondentValue = payload.searchRespondentValue;
      }
      if (payload.filterValue !== undefined) {
        state.filterValue = payload.filterValue;
      }
    },
    setDateFilter(state, action) {
      state.fromDateFilter = action.payload.from;
      state.toDateFilter = action.payload.to;
      state.timespan = action.payload.timespan;
    },
    setCampaignDropdownKeys(state, action) {
      state.campaignDropdownKeys = action.payload;
    },
    startLoadingDashboard(state) {
      state.isDashboardLoading = true;
    },
    stopLoadingDashboard(state) {
      state.isDashboardLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// ----------------------------------------------------------------------

export const updateDateFilter = ({
  from,
  to,
  timespan
}: {
  from: any;
  to: any;
  timespan: TimeSpan;
}) => {
  return () => {
    dispatch(
      slice.actions.setDateFilter({
        from:
          typeof from === 'number'
            ? from
            : from?.getFullYear?.()?.toString?.().length === 4
            ? from?.getTime?.()
            : undefined,
        to:
          typeof to === 'number'
            ? to
            : to?.getFullYear?.()?.toString?.().length === 4
            ? to?.getTime?.()
            : undefined,
        timespan: timespan
      })
    );

    return Promise.resolve({
      defaultSuccessMessage: 'Dashboard date filter updated'
    });
  };
};

export const updateCampaignDropdownKeys = (campaignDropdownKeys: (number | string)[]) => {
  return () => {
    dispatch(slice.actions.setCampaignDropdownKeys(campaignDropdownKeys));
    return Promise.resolve({ defaultSuccessMessage: 'Selected campaign updated' });
  };
};

export const updateDashboardAnalytics = () => {
  return async () => {
    dispatch(slice.actions.startLoadingDashboard());
    dispatch(slice.actions.setDashboardAnswersLoading(true));
    try {
      const currentState = store.getState().dashboard;
      const start = currentState.fromDateFilter ? new Date(currentState.fromDateFilter) : undefined;
      const end = currentState.toDateFilter ? new Date(currentState.toDateFilter) : undefined;
      const params = {
        start,
        end
      };
      let response: any;

      const campaignIds = getCampaignIdsFromDropdownKeys(currentState.campaignDropdownKeys);
      if (!campaignIds) {
        dispatch(
          slice.actions.setDashboardGraphs({
            spread: {
              detractors: 0,
              nps: 0,
              passives: 0,
              promoters: 0,
              total: 0
            },
            respondentCount: 0
          })
        );
        dispatch(slice.actions.setDashboardAnswersLoading(false));
        dispatch(slice.actions.stopLoadingDashboard());

        return await Promise.resolve({
          result: response,
          defaultSuccessMessage: 'Dashboard analytics updated'
        });
      }

      response = await axios.post(
        `dashboards/summary/analytics`,
        getCampaignIdsFromDropdownKeys(currentState.campaignDropdownKeys),
        {
          params
        }
      );

      dispatch(slice.actions.setDashboardGraphs(response?.data));
      dispatch(slice.actions.stopLoadingDashboard());

      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Dashboard analytics updated'
      });
    } catch (error) {
      dispatch(
        slice.actions.setDashboardGraphs({
          spread: {
            detractors: 0,
            nps: 0,
            passives: 0,
            promoters: 0,
            total: 0
          },
          respondentCount: 0
        })
      );
      dispatch(slice.actions.setDashboardAnswersLoading(false));
      dispatch(slice.actions.stopLoadingDashboard());
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not get dashboard analytics'
      });
    }
  };
};

const getActiveCampaignIds = () => {
  const currentcampaignState = store.getState().campaign;
  const campaignIds =
    currentcampaignState.campaignsSummary
      ?.filter((campaign) => campaign.active)
      ?.map((campaign) => campaign.id) || [];
  return campaignIds;
};

const getClosedCampaignIds = () => {
  const currentcampaignState = store.getState().campaign;
  const campaignIds =
    currentcampaignState.campaignsSummary
      ?.filter((campaign) => !campaign.active)
      ?.map((campaign) => campaign.id) || [];
  return campaignIds;
};

export const updateDashboardResponseList = ({
  skip,
  take,
  sortValue,
  searchRespondentValue,
  filterValue
}: {
  skip?: number;
  take?: number;
  sortValue?: string;
  searchRespondentValue?: string;
  filterValue?: boolean;
}) => {
  return async () => {
    await dispatch(
      slice.actions.setAnswerListParams({
        skip,
        take,
        sortValue,
        searchRespondentValue,
        filterValue
      })
    );
    try {
      const currentState = store.getState().dashboard;
      const after = currentState.fromDateFilter ? new Date(currentState.fromDateFilter) : undefined;
      const before = currentState.toDateFilter ? new Date(currentState.toDateFilter) : undefined;
      const params = {
        before,
        after,
        uncategorizedOnly: currentState.filterValue === 'catOnly',
        catOnly: currentState.filterValue === 'catOnly',
        skip: currentState.responseData.skip,
        take: currentState.responseData.take,
        order: currentState.sortValue,
        name: currentState.searchRespondentValue,
        empty: false,
        includeDoNotContact: true
      };
      let response: any;

      const campaignIds = getCampaignIdsFromDropdownKeys(currentState.campaignDropdownKeys);
      if (!campaignIds) {
        dispatch(slice.actions.setDashboardAnswers(initialReponseData));
        dispatch(slice.actions.setDashboardAnswersLoading(false));

        return await Promise.resolve({
          result: response,
          defaultSuccessMessage: 'Responses updated'
        });
      }
      response = await axios.post(`dashboards/summary/responses`, campaignIds, {
        params
      });

      dispatch(slice.actions.setDashboardAnswers(response?.data));

      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Responses updated'
      });
    } catch (error) {
      dispatch(slice.actions.setDashboardAnswers(initialReponseData));
      dispatch(slice.actions.setDashboardAnswersLoading(false));

      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not get responses'
      });
    }
  };
};

const getCampaignIdsFromDropdownKeys = (campaignDropdownKeys: (string | number)[]) => {
  if (campaignDropdownKeys.includes('activeCampaigns')) {
    const campaignIds = getActiveCampaignIds();
    if (campaignIds.length > 0) {
      return getActiveCampaignIds();
    }
    //endpoint should not be called if no campaigns. Reset states manually
    return false;
  }

  if (campaignDropdownKeys.includes('allCampaigns')) {
    return [];
  }

  if (campaignDropdownKeys.includes('closedCampaigns')) {
    return getClosedCampaignIds();
  }

  return campaignDropdownKeys.map((key) => Number(key));
};
