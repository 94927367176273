import { Box, Container, Divider, Stack, Typography } from '@mui/material';
// material
import { useState } from 'react';
import { Organisation } from '../../@types/organization';
import LoginForm from './components/LoginForm';
import { ContentStyle, RootStyle } from './components/Styles';
import SelectOrganization from './components/SelectOrganization';
import LoginGreeting from './components/LoginGreeting';
import { CustomLoadingButton } from 'components/util/CustomButton';
import useMicrosoftLogin from './components/useMicrosoftLogin';

// ----------------------------------------------------------------------

function MicrosoftLogo() {
  return (
    <img
      src={'/static/icons/ms-symbol.svg'}
      alt="Microsoft logo"
      width="100%"
      style={{ cursor: 'pointer', alignSelf: 'center' }}
    />
  );
}

// ----------------------------------------------------------------------

export default function Login() {
  const [userOrganisations, setUserOrganisations] = useState<Organisation[]>([]);
  const { handleLoginWithMicrosoft, isMicrosoftSsoLoading } = useMicrosoftLogin({
    userOrganisations,
    setUserOrganisations
  });

  let loginForm: JSX.Element;
  // If the user has multiple organisations, show the select organisation form
  if (userOrganisations.length > 1) {
    loginForm = <SelectOrganization organisations={userOrganisations} />;
  } else {
    loginForm = (
      <>
        <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h4" gutterBottom>
              Sign in to nps.today
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
          </Box>
        </Stack>
        <LoginForm setUserOrganisations={setUserOrganisations} />

        <Divider sx={{ pt: 2, pb: 2 }}>
          <Typography variant="body2">or</Typography>
        </Divider>

        <CustomLoadingButton
          color={'secondary'}
          onClick={() => handleLoginWithMicrosoft()}
          fullWidth
          size="large"
          variant={'outlined'}
          startIcon={<MicrosoftLogo />}
          loading={isMicrosoftSsoLoading}
        >
          Sign in with Microsoft
        </CustomLoadingButton>
      </>
    );
  }

  return (
    <RootStyle title="Login" disableHeader>
      <div style={{ display: 'flex' }}>
        <LoginGreeting />
        <Container maxWidth={userOrganisations.length < 1 ? 'sm' : false}>
          <ContentStyle>{loginForm}</ContentStyle>
        </Container>
      </div>
    </RootStyle>
  );
}
