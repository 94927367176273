import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

export enum LoginMethod {
  MICROSOFT = 'microsoft',
  USERNAME_PASSWORD = 'username/password'
}
const useCurrentLoginMethod = (): LoginMethod => {
  const location = useLocation();
  const [loginMethod, setLoginMethod] = useState<LoginMethod>(LoginMethod.USERNAME_PASSWORD);

  useEffect(() => {
    // Check if the current path contains '/microsoft' - this indicates that the user is logging in with Microsoft SSO
    if (location.pathname.includes('/microsoft')) {
      setLoginMethod(LoginMethod.MICROSOFT);
    } else {
      setLoginMethod(LoginMethod.USERNAME_PASSWORD);
    }
  }, [location.pathname]);

  return loginMethod;
};

export default useCurrentLoginMethod;
