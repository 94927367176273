import { DetailSegements, Message } from 'components/messages/MessageContent';
import { CustomDispatchErrorResponse } from 'redux/dispatch';

type GetErrorMessageContentProps = {
  errorResponse: CustomDispatchErrorResponse;
};
export const getErrorMessageContent = ({ errorResponse }: GetErrorMessageContentProps): Message => {
  const error = errorResponse.error;
  const defaultErrorMessage = errorResponse.defaultErrorMessage;

  //the default title is the default error message
  const errorTitle = defaultErrorMessage;

  let errorDescription: string | undefined = undefined;
  let detailErrorSegments: DetailSegements = [];

  //title is used, if it is a default C# error
  if (error.title) {
    //set the error title as the description
    errorDescription = error.title;

    const detailErrors = error.errors;
    // for each entry (segment) of detailErrors, get and save each line into ots corosponding field in detailErrorSegments
    if (detailErrors) {
      //if errors are an array
      if (detailErrors.length) {
        detailErrors.forEach((detailError: string[][], index: number) => {
          //save each line
          detailError[index]?.forEach((errorLine: string) => {
            detailErrorSegments[index].push(errorLine);
          });
        });
        //if not an array, its an object
      } else {
        const detailErrorKeys = Object.keys(detailErrors);
        detailErrorKeys.forEach((detailErrorKey, index) => {
          detailErrorSegments[index] = [];
          //save each line at this key
          detailErrors[detailErrorKey]?.forEach((errorLine: string) => {
            detailErrorSegments[index].push(errorLine);
          });
        });
      }
    }
  } else {
    //if a message is given, use as description
    errorDescription = error?.error?.message ?? error?.message ?? undefined;

    //if no message is given, and the error is a string, set this as a detailsegment line
    if (typeof error === 'string' && error.length > 0) {
      detailErrorSegments.push([error]);
    }
  }

  return {
    title: errorTitle,
    description: errorDescription,
    detailSegements: detailErrorSegments
  } as Message;
};
