import { GridFilterModel, GridSortModel } from '@mui/x-data-grid';
import { createSlice } from '@reduxjs/toolkit';
import {
  getFilterQueryParam,
  getOrderbyQueryParam,
  getSkipQueryParam,
  getTopQueryParam
} from 'utils/getQueryParams';
import { EmployeeState, Employee } from '../../@types/employee';
// utils
import axios from '../../utils/axios';
import { dispatch, store } from '../store';

// ----------------------------------------------------------------------

const initialState: EmployeeState = {
  isLoading: false,
  isLoadingDelete: false,
  isLoadingMerge: false,
  employee: null,
  employees: [],
  employeesFromSearch: [],
  count: 0
};

const slice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    // GET EMPLOYEES
    getEmployeesSuccess(state, action) {
      state.isLoading = false;
      state.employees = action.payload;
    },

    setEmployeesCount(state, action) {
      state.count = action.payload;
    },

    // ADD EMPLOYEES
    addEmployeeSuccess(state, action) {
      state.isLoading = false;
    },

    deleteEmployeeStart(state) {
      state.isLoadingDelete = true;
    },

    deleteEmployeeError(state) {
      state.isLoadingDelete = false;
    },

    deleteEmployeesSuccess(state) {
      state.isLoadingDelete = false;
    },

    setEmployeesFromSearch(state, action) {
      state.employeesFromSearch = action.payload;
    },

    setEmployee(state, action) {
      state.employee = action.payload;
    },

    startMerge(state) {
      state.isLoadingMerge = true;
    },

    mergeDone(state) {
      state.isLoadingMerge = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const { changeCampaign, dismissError } = slice.actions;

// ----------------------------------------------------------------------

export function getEmployee(empId?: number) {
  return async () => {
    if (!empId) {
      dispatch(slice.actions.setEmployee(null));
      return Promise.reject({
        defaultErrorMessage: `Could not get employee without an`
      });
    }
    try {
      const response = await axios.get(`/employees/${empId}`);
      dispatch(slice.actions.setEmployee(response.data));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Employee fetched'
      });
    } catch (error) {
      return Promise.reject({
        error: error,
        defaultErrorMessage: `Could not get employee with id ${empId}`
      });
    }
  };
}

export function getEmployeesWithParams({
  sortModel,
  filterModel,
  top,
  skip
}: {
  sortModel: GridSortModel | undefined;
  filterModel: GridFilterModel | undefined;
  top: number;
  skip: number;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `odata/employees?$count=true${getOrderbyQueryParam(sortModel)}${getFilterQueryParam(
          filterModel
        )}${getSkipQueryParam(skip)}${getTopQueryParam(top)}`
      );
      dispatch(slice.actions.getEmployeesSuccess(response.data.value));
      dispatch(slice.actions.setEmployeesCount(response.data['@odata.count']));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Employees fetched'
      });
    } catch (error) {
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch employees'
      });
    }
  };
}

export function getEmployees() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/employees');
      dispatch(slice.actions.getEmployeesSuccess(response.data));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Updated employees'
      });
    } catch (error) {
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch employees'
      });
    }
  };
}

export function getEmployeesIfNotAvail(employeeIds: number[]) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      //get any employees already saved
      const currentState = store.getState().employee;
      const savedEmployees = currentState.employees
        ?.map((employee) => employee?.id)
        ?.filter((employee) => employee !== null);
      //get requested ids
      const uniqueIds = employeeIds.filter((item, pos) => employeeIds.indexOf(item) === pos);
      //check if any new employees are requested
      const newIds = uniqueIds?.filter((id) => savedEmployees.indexOf(id) < 0);
      if (newIds?.length <= 0) {
        return await Promise.resolve({
          defaultSuccessMessage: 'No employees ti fetch'
        });
      }

      //allow a max of 7 id's per request, to limit request string
      //create an array, where 1 index corresponds to one axios get call
      let paramCollection = [];
      while (newIds.length) {
        paramCollection.push(newIds.splice(0, 7));
      }

      //make a seperate call for each collection, awating response and adding all response values together
      let newEmployees: Employee[] = [];
      while (paramCollection.length) {
        const ids = paramCollection.splice(0, 1);
        const params = {
          $filter: `id eq ${ids?.[0].join(' or id eq ')}`
        };
        const response = await axios.get('odata/employees', { params });
        newEmployees = newEmployees.concat(response.data.value as Employee[]);
      }

      dispatch(
        slice.actions.getEmployeesSuccess(store.getState().employee.employees.concat(newEmployees))
      );
      return await Promise.resolve({
        defaultSuccessMessage: 'Employees fetched'
      });
    } catch (error: any) {
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch employees'
      });
    }
  };
}

export function getEmployeesSearch({
  top = 6,
  searchString
}: {
  top?: number;
  searchString: string;
}) {
  return async () => {
    if (!searchString?.length) {
      dispatch(slice.actions.setEmployeesFromSearch([]));
      return Promise.resolve({
        defaultSuccessMessage: 'Employees fetched'
      });
    }
    const individualSearchString = searchString.split(' ');
    let filterQuery = '';
    individualSearchString.forEach((string) => {
      filterQuery += `${
        filterQuery.length ? ' and ' : ''
      }(contains(firstName,'${string}') or contains(lastName,'${string}') or contains(email,'${string}'))`;
    });
    try {
      const response = await axios.get(`/odata/employees?$top=${top}&$filter=${filterQuery}`);
      dispatch(slice.actions.setEmployeesFromSearch(response.data.value));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Employees fetched'
      });
    } catch (error) {
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch employees'
      });
    }
  };
}

export function addEmployee(employee: Employee) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let response;
      if (employee.id) {
        response = await axios.put(`/profile/employees/${employee.id}`, {
          ...employee
        });
      } else {
        response = await axios.post('/profile/employees', {
          ...employee
        });
      }

      dispatch(slice.actions.addEmployeeSuccess(response.data));

      let defaultSuccessMessage = '';
      if (employee.id) {
        defaultSuccessMessage = 'Edited employee';
      } else {
        defaultSuccessMessage = 'Added employee';
      }
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: defaultSuccessMessage
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading());
      let defaultErrorMessage = '';
      if (employee.id) {
        defaultErrorMessage = 'Could not edit employee';
      } else {
        defaultErrorMessage = 'Could not add employee';
      }
      return await Promise.reject({
        error: error,
        defaultErrorMessage: defaultErrorMessage
      });
    }
  };
}

export function deleteEmployees(ids: number[]) {
  return async () => {
    dispatch(slice.actions.deleteEmployeeStart());
    try {
      for (let id of ids) {
        await axios.delete('/profile/employees/' + id);
      }
      dispatch(slice.actions.deleteEmployeesSuccess());
      return await Promise.resolve({
        defaultSuccessMessage: `Employee${ids.length > 1 ? 's' : ''} deleted`
      });
    } catch (error: any) {
      dispatch(slice.actions.deleteEmployeeError());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not delete employee${ids.length > 1 ? 's' : ''}`
      });
    }
  };
}

type MergeEmployeesProps = {
  sourceId: number;
  targetId: number;
};
export function mergeEmployees({ sourceId, targetId }: MergeEmployeesProps) {
  return async () => {
    dispatch(slice.actions.startMerge());
    try {
      await axios.put(`/employees/merge?sourceId=${sourceId}&targetId=${targetId}`);
      dispatch(slice.actions.mergeDone());
      return await Promise.resolve({
        defaultSuccessMessage: 'Employess merged'
      });
    } catch (error: any) {
      dispatch(slice.actions.mergeDone());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not merge employees`
      });
    }
  };
}
