import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from 'redux/store';
import { ReviewCategory, ReviewsState } from '../../@types/reviews';

// ----------------------------------------------------------------------
const initialState: ReviewsState = {
  isLoading: false,
  consentReviews: [],
  publicReviews: [],
  flaggedReviews: [],
  total: 0,
  skip: 0,
  take: 10
};

const slice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    setConsentReviews(state, action) {
      state.consentReviews = action.payload;
    },
    setPublicReviews(state, action) {
      state.publicReviews = action.payload;
    },
    setFlaggedReviews(state, action) {
      state.flaggedReviews = action.payload;
    },
    setPagination(state, action) {
      state.total = action.payload.total || initialState.total;
      state.skip = action.payload.skip || initialState.skip;
      state.take = action.payload.take || initialState.take;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setPagination } = slice.actions;

// ----------------------------------------------------------------------

export const getReviews = ({
  reviewType,
  take,
  skip
}: {
  reviewType: ReviewCategory;
  take?: number;
  skip?: number;
}) => {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const params = {
        skip: skip ?? initialState.skip,
        take: take ?? initialState.take,
        consent: reviewType === ReviewCategory.consent,
        isPublic: reviewType === ReviewCategory.public,
        isFlagged: reviewType === ReviewCategory.flagged,
        orderby: '(rating asc)'
      };
      //get reviews data
      const response = await axios.get(`/selectionTool/reviews`, { params });
      const reviews = response.data?.results ?? [];
      switch (reviewType) {
        case ReviewCategory.consent:
          dispatch(slice.actions.setConsentReviews(reviews));
          break;
        case ReviewCategory.public:
          dispatch(slice.actions.setPublicReviews(reviews));
          break;
        case ReviewCategory.flagged:
          dispatch(slice.actions.setFlaggedReviews(reviews));
          break;
      }

      dispatch(slice.actions.setPagination(response.data));

      dispatch(slice.actions.stopLoading());

      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Reviews fetched'
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch reviews'
      });
    }
  };
};

export const changeReviewType = ({
  currentReviewType,
  id,
  isFlagged,
  isPublic
}: {
  currentReviewType: ReviewCategory;
  id: number;
  isFlagged?: boolean;
  isPublic?: boolean;
}) => {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      //set parameters for put request
      const params = {
        id,
        isFlagged: isFlagged ?? undefined,
        isPublic: isPublic ?? undefined,
        take: 10
      };

      //update review
      await axios.put(`/selectionTool/response`, null, { params });

      //get new reviews
      dispatch(
        getReviews({
          reviewType: currentReviewType
        })
      );

      dispatch(slice.actions.stopLoading());

      return await Promise.resolve({
        defaultSuccessMessage: `Response moved to "${
          isFlagged ? 'Flagged' : isPublic ? 'Public' : 'Reponses'
        }"`
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not  edit review'
      });
    }
  };
};
