export enum RelayType {
  Rejected = 'Rejected',
  None = 'None',
  Filter = 'Filter',
  External = 'External'
}

export enum AuthenticationType {
  None = 'None',
  Basic = 'Basic',
  Bearer = 'Bearer',
  ApiKey = 'ApiKey',
  OAuth = 'OAuth'
}

export type Relay = {
  id?: string;
  orgId?: string;
  relayType: RelayType;
  externalWebHookUrl?: string | null;
  apiAuthentication: {
    authenticationType?: AuthenticationType | null;
    basicAuthUser?: string | null;
    basicAuthPassword?: string | null;
    bearerToken?: string | null;
    oAuthClientId?: string | null;
    oAuthClientSecret?: string | null;
    oAuthTokenEndpoint?: string | null;
    oAuthResource?: string | null;
  };
};

type Loading = {
  [key: string]: boolean;
};

export type RelayState = {
  isLoading: Loading;
  isInitialyFetched: boolean;
  relaySettings: Relay;
};
