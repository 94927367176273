import { useState, useEffect } from 'react';
import 'firebase/remote-config';
import remoteConfig from './remoteConfig';
import { Value, fetchAndActivate, getAll } from 'firebase/remote-config';
import { RemoteConfigKeyConfiguration } from './remoteConfigKey';
import { isNotProduction } from 'authConfig';
import { FeatureFlagConfig } from './useFeatureFlags';
interface RemoteConfigValues {
  [key: string]: Value;
}

interface RemoteConfigHook {
  getRemoteConfigValue: (
    keyConfiguration: RemoteConfigKeyConfiguration
  ) => boolean | string | number | FeatureFlagConfig | null;
}
export const useRemoteConfig = (): RemoteConfigHook => {
  const [remoteConfigValues, setRemoteConfigValues] = useState<RemoteConfigValues>({});
  const fetchRemoteConfig = async (): Promise<void> => {
    await fetchAndActivate(remoteConfig);
    const updatedValues = getAll(remoteConfig);
    setRemoteConfigValues(updatedValues);
  };

  useEffect(() => {
    fetchRemoteConfig();
  }, []);

  const getRemoteConfigValue = (
    keyConfiguration: RemoteConfigKeyConfiguration
  ): boolean | string | number | FeatureFlagConfig | null => {
    const value = remoteConfigValues[keyConfiguration.key];
    if (!value) {
      return null;
    }

    if (keyConfiguration.type === 'json') {
      const config = JSON.parse(value.asString()) as FeatureFlagConfig;
      return config;
    }
    if (keyConfiguration.type === 'boolean') {
      return isNotProduction && keyConfiguration.showInNonProductionEnvironments
        ? true
        : value.asBoolean();
    }
    if (keyConfiguration.type === 'string') {
      return value.asString();
    }
    return value.asNumber();
  };
  return { getRemoteConfigValue };
};
