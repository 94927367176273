import { GridFilterModel, GridSortModel } from '@mui/x-data-grid';
import { createSlice } from '@reduxjs/toolkit';
import { convertFileToFormData } from 'components/util/convert';
import {
  getFilterQueryParam,
  getOrderbyQueryParam,
  getSkipQueryParam,
  getTopQueryParam
} from 'utils/getQueryParams';
import { Respondent, RespondentDataState } from '../../@types/respondent';
import axios from '../../utils/axios';
import { dispatch } from '../store';
import { getDropzoneError } from 'components/CustomDropzone';

// ----------------------------------------------------------------------

const initialState: RespondentDataState = {
  isLoading: false,
  isDeleteLoading: false,
  isMergeLoading: false,
  isSendSurveyLoading: false,
  isSamlpeRespondentsFromSpreadsheetLoading: false,
  respondentData: { count: 0, respondents: [], respondentsSampleFromSpreadsheed: undefined },
  isRespondentLoading: false,
  respondent: undefined
};

const slice = createSlice({
  name: 'respondent',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      //state.respondentData = initialState.respondentData;
      state.isLoading = true;
    },

    stopLoading(state) {
      //state.respondentData = initialState.respondentData;
      state.isLoading = false;
    },

    startDeleteLoading(state) {
      state.isDeleteLoading = true;
    },

    stopDeleteLoading(state) {
      state.isDeleteLoading = false;
    },

    startLoadingSendSurvey(state) {
      state.isSendSurveyLoading = true;
    },

    stopLoadingSendSurvey(state) {
      state.isSendSurveyLoading = false;
    },

    startSamlpeRespondentsFromSpreadsheetLoading(state) {
      state.isSamlpeRespondentsFromSpreadsheetLoading = true;
    },

    stopSamlpeRespondentsFromSpreadsheetLoading(state) {
      state.isSamlpeRespondentsFromSpreadsheetLoading = false;
    },

    // GET RESPONDENTS
    getRespondentsSuccess(state, action) {
      state.isLoading = false;
      state.respondentData.count = action.payload['@odata.count'];
      state.respondentData.respondents = action.payload.value;
    },

    editRespondentSuccess(state, action) {
      const newRes = JSON.parse(action.payload.config.data);
      state.respondentData.respondents = state.respondentData.respondents.map((res) =>
        newRes.id === res.id ? newRes : res
      );
      state.isLoading = false;
    },

    addRespondentSuccess(state, action) {
      state.isLoading = false;
    },

    addRespondentsSampleFromSpreadsheed(state, action) {
      state.respondentData.respondentsSampleFromSpreadsheed = action.payload;
      state.isSamlpeRespondentsFromSpreadsheetLoading = false;
    },

    startMerge(state) {
      state.isMergeLoading = true;
    },

    mergeDone(state) {
      state.isMergeLoading = false;
    },

    startGetRespondent(state) {
      state.isRespondentLoading = true;
    },

    setRespondent(state, action) {
      state.isRespondentLoading = false;
      state.respondent = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setRespondent } = slice.actions;

// ----------------------------------------------------------------------

type GetRespondentProps = {
  id: number;
};
export const getRespondent = ({ id }: GetRespondentProps) => {
  return async () => {
    dispatch(slice.actions.startGetRespondent());
    try {
      const response = await axios.get(`/odata/respondents/${id}?$expand=employee,company`);

      dispatch(slice.actions.setRespondent(response.data));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Respondent fetched'
      });
    } catch (error) {
      dispatch(slice.actions.setRespondent(undefined));
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch respondent'
      });
    }
  };
};

export function getRespondents({
  sortModel,
  filterModel,
  top,
  skip
}: {
  sortModel: GridSortModel | undefined;
  filterModel: GridFilterModel | undefined;
  top: number;
  skip: number;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/odata/respondents?$count=true${getOrderbyQueryParam(sortModel)}${getFilterQueryParam(
          filterModel
        )}${getSkipQueryParam(skip)}${getTopQueryParam(top)}&$expand=employee`
      );
      dispatch(slice.actions.getRespondentsSuccess(response.data));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Respondents fetched'
      });
    } catch (error) {
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch respondents'
      });
    }
  };
}

type EditRespondentProps = {
  respondent: Respondent;
  shouldChangeSubscribed?: boolean;
};
export function editRespondent({ respondent, shouldChangeSubscribed }: EditRespondentProps) {
  dispatch(slice.actions.startLoading());
  return async () => {
    try {
      const response = await axios.put('/respondents/' + respondent.id, {
        ...respondent
      });
      if (shouldChangeSubscribed) {
        await axios.put(`/respondents/${respondent.id}/active/${respondent.active}`);
      }

      dispatch(slice.actions.editRespondentSuccess(response));

      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Respondent edited'
      });
    } catch (error: any) {
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not edit respondent'
      });
    }
  };
}

type UnsubscribeRespondentProps = {
  publicGuid: string;
};
export function unsubscribeRespondent({ publicGuid }: UnsubscribeRespondentProps) {
  dispatch(slice.actions.startLoading());
  return async () => {
    try {
      const response = await axios.put('/respondents/' + publicGuid + '/unsubscribe');

      dispatch(slice.actions.stopLoading());

      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Respondent unsubscribed'
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not unsubscribe respondent'
      });
    }
  };
}

export function deleteRespondents(ids: number[]) {
  return async () => {
    dispatch(slice.actions.startDeleteLoading());
    try {
      for (let id of ids) {
        await axios.delete('/respondents/' + id);
      }
      dispatch(slice.actions.stopDeleteLoading());

      return await Promise.resolve({
        defaultSuccessMessage: `Deleted ${ids.length} respondent${ids.length > 1 ? 's' : ''}`
      });
    } catch (error: any) {
      dispatch(slice.actions.stopDeleteLoading());

      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not delete respondent'
      });
    }
  };
}

export function addRespondent({
  respondent,
  campaignId
}: {
  respondent: Partial<Respondent>;
  campaignId: number;
}) {
  dispatch(slice.actions.startLoading());
  return async () => {
    try {
      const response = await axios.post(`/campaigns/${campaignId}/respondents`, [respondent]);
      dispatch(slice.actions.addRespondentSuccess(response));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Respondent added'
      });
    } catch (error: any) {
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not add respondent'
      });
    }
  };
}

export function samlpeRespondentsFromSpreadsheet({
  campaignId,
  file
}: {
  campaignId: number;
  file: File | undefined;
}) {
  return async () => {
    dispatch(slice.actions.startSamlpeRespondentsFromSpreadsheetLoading());
    try {
      if (!file) {
        dispatch(slice.actions.addRespondentsSampleFromSpreadsheed({}));
        return await Promise.resolve({
          defaultSuccessMessage: 'No file uplaoded'
        });
      }

      const response = await axios.post(
        `/campaigns/${campaignId}/respondents/fromspreadsheet/sample`,
        convertFileToFormData(file)
      );
      dispatch(slice.actions.addRespondentsSampleFromSpreadsheed(response.data));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Your campaign was scheduled'
      });
    } catch (error: any) {
      dispatch(slice.actions.stopSamlpeRespondentsFromSpreadsheetLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: getDropzoneError('Uploaded file could not be processed.')
      });
    }
  };
}

export function sendOutCampaignFromSpreadsheet({
  campaignId,
  file,
  sendEmailAt
}: {
  campaignId: number;
  file: File | undefined | null;
  sendEmailAt: Date;
}) {
  return async () => {
    dispatch(slice.actions.startLoadingSendSurvey());
    try {
      await axios.post(
        `/campaigns/${campaignId}/respondents/fromspreadsheet?sendEmailAt=${sendEmailAt.toISOString()}`,
        convertFileToFormData(file)
      );
      dispatch(slice.actions.stopLoadingSendSurvey());
      return await Promise.resolve({
        defaultSuccessMessage: 'Processing of new campaign members started'
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoadingSendSurvey());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Your campaign was not scheduled'
      });
    }
  };
}

type MergeRespondentsProps = {
  sourceId: number;
  targetId: number;
};
export function mergeRespondents({ sourceId, targetId }: MergeRespondentsProps) {
  return async () => {
    dispatch(slice.actions.startMerge());
    try {
      await axios.put(`/respondents/merge?sourceId=${sourceId}&targetId=${targetId}`);
      dispatch(slice.actions.mergeDone());
      return await Promise.resolve({
        defaultSuccessMessage: 'Respondents merged'
      });
    } catch (error: any) {
      dispatch(slice.actions.mergeDone());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not merge respodents'
      });
    }
  };
}
