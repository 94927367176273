import {
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import MoreMenuButton from 'components/util/MoreMenuButton';
import { useState } from 'react';
import { APIKey } from '../../../@types/organization';
import { createAPIKey, deleteAPIKey, editAPIKey } from '../../../redux/slices/organization';
import { RootState, useSelector } from '../../../redux/store';
import { MIconButton } from '../../../components/@material-extend';
import useCustomDispatch from 'redux/dispatch';
import useDisplayMessage from 'components/messages/useMessage';
import CustomButton from 'components/util/CustomButton';
import Icon from 'components/icons/Icon';
import { styled } from '@mui/material/styles';
import AccessGuard from 'guards/AccessGuard';

// ----------------------------------------------------------------------

const ApiKeyDiv = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& > :nth-child(2)': { opacity: 0 },
  '&:hover': {
    cursor: 'pointer',
    '& > :nth-child(2)': { opacity: 1 }
  }
});

export default function APIKeys() {
  const customDispatch = useCustomDispatch();
  const displayMessage = useDisplayMessage();
  const { apiKeys } = useSelector((state: RootState) => state.organization);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [name, setName] = useState<string>('');
  const [selectedApiKey, setSelectedApiKey] = useState<APIKey | null>(null);
  const [apiIdSelected, setApiIdSelected] = useState<number | null>(null);

  const ApiKeyRow = ({ apiKey }: { apiKey: APIKey }) => {
    return (
      <Stack direction="row" alignItems="center" key={apiKey.id}>
        <Box sx={{ flexGrow: 1, ml: 2, minWidth: 100, height: '3rem' }}>
          <Typography variant="subtitle2" sx={{ mb: 0.5 }} noWrap>
            {apiKey.name}
          </Typography>

          <Stack direction="row" alignItems="center" key={apiKey.id}>
            <ApiKeyDiv
              id="my-custom-id"
              onClick={() => {
                navigator.clipboard.writeText(apiKey.key);
                displayMessage.success({
                  message: { title: `Api key "${apiKey.name}" copied to clipboard` }
                });
              }}
            >
              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                {apiKey.key}
              </Typography>
              <Tooltip title="Copy api key">
                <MIconButton size="small">
                  <Icon type="copy" isText />
                </MIconButton>
              </Tooltip>
            </ApiKeyDiv>
          </Stack>
        </Box>

        <MoreMenuButton
          itemType="api key"
          onEdit={() => {
            setName(apiKey.name);
            setSelectedApiKey(apiKey);
            setEditOpen(true);
          }}
          onEditAccess="ManagementEdit"
          onDelete={() => {
            customDispatch({ action: deleteAPIKey, actionParameters: apiKey.id });
          }}
          onDeleteAccess="ManagementDelete"
        />
      </Stack>
    );
  };

  return (
    <Card sx={{ py: 3, mt: 3, width: '100%' }}>
      <CardHeader
        title="Api keys"
        subheader={'You have ' + apiKeys.length + ' api keys'}
        action={
          <AccessGuard access="ManagementCreate">
            <CustomButton variant="contained" onClick={() => setCreateOpen(true)}>
              Add Api Key
            </CustomButton>
          </AccessGuard>
        }
      />

      <Dialog open={createOpen} onClose={() => setCreateOpen(false)}>
        <DialogTitle>Add new api key</DialogTitle>
        <DialogContent>
          <Typography>Please give the api key a name</Typography>
          <TextField
            value={name}
            onChange={(event) => setName(event.target.value)}
            label={'name'}
            margin={'normal'}
            color={'primary'}
            required
          />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={() => setCreateOpen(false)}>Cancel</CustomButton>
          <CustomButton
            disabled={name?.length <= 0}
            onClick={() => {
              if (!name) return;
              customDispatch({
                action: createAPIKey,
                actionParameters: name,
                onSuccess: () => {
                  setCreateOpen(false);
                }
              });
            }}
            autoFocus
            variant={'contained'}
          >
            Create
          </CustomButton>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
        <DialogTitle>Are you sure you want to delete?</DialogTitle>
        <DialogActions>
          <CustomButton onClick={() => setDeleteOpen(false)}>Cancel</CustomButton>
          <CustomButton
            color={'error'}
            onClick={() => {
              if (!apiIdSelected) return;
              customDispatch({
                action: deleteAPIKey,
                actionParameters: apiIdSelected,
                onSuccess: () => {
                  setApiIdSelected(null);
                  setDeleteOpen(false);
                }
              });
            }}
            autoFocus
          >
            Delete
          </CustomButton>
        </DialogActions>
      </Dialog>

      <Dialog open={editOpen} onClose={() => setEditOpen(false)}>
        <DialogTitle>Edit api key</DialogTitle>
        <DialogContent>
          <Typography>Please give the api key a new name</Typography>
          <TextField
            value={name}
            onChange={(event) => setName(event.target.value)}
            label={'name'}
            margin={'normal'}
            color={'primary'}
            required
          />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={() => setEditOpen(false)}>Cancel</CustomButton>
          <CustomButton
            onClick={() => {
              if (!selectedApiKey) return;
              customDispatch({
                action: editAPIKey,
                actionParameters: { apiKey: selectedApiKey, newName: name },
                onSuccess: () => {
                  setSelectedApiKey(null);
                  setEditOpen(false);
                }
              });
            }}
            autoFocus
          >
            Edit
          </CustomButton>
        </DialogActions>
      </Dialog>

      <Stack spacing={2} sx={{ p: 3 }}>
        <ul style={{ gap: '1rem' }}>
          {apiKeys.map((apiKey, index) => (
            <div key={index}>
              <li key={index} style={{ listStyle: 'none', gap: '1rem' }}>
                <ApiKeyRow apiKey={apiKey} />
              </li>
              {apiKeys.length - 1 > index && <Divider style={{ margin: '.5rem' }} />}
            </div>
          ))}
        </ul>
      </Stack>
    </Card>
  );
}
