import { GridColDef } from '@mui/x-data-grid';
import { SurveyOrder, SurveyOrderType } from '../../../../@types/order';
import { CustomDataGrid } from 'components/datagrid/Custom';
import { useEffect, useMemo } from 'react';
import { DataGridKey } from 'redux/slices/datagrid';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'routes/paths';
import useCustomDispatch, { useCustomDispatchWithoutPromise } from 'redux/dispatch';
import { RootState, useSelector } from 'redux/store';
import { getOrders, resetOrders } from 'redux/slices/orders';
import { Stack } from '@mui/material';
import { UsageIndicator } from '../UsageIndicator';
import { baseOrderColumns, baseOrderInitialColumnsHidden } from '../baseOrder/baseOrderColumns';

export default function SurveyOrderList() {
  const navigate = useNavigate();
  const customDispatch = useCustomDispatch();
  const customDispatchWithoutPromise = useCustomDispatchWithoutPromise();
  const { isLoading, orderData } = useSelector((state: RootState) => state.order);
  useEffect(() => {
    (async () => {
      await customDispatch({
        action: getOrders,
        actionParameters: { endpoint: 'survey-orders' },
        disableSuccessMessage: true
      });
    })();

    return () => {
      customDispatchWithoutPromise({
        action: resetOrders,
        disableAllMessages: true
      });
    };
  }, [customDispatch, customDispatchWithoutPromise]);

  const columns: GridColDef[] = [
    {
      field: 'surveyOrderType',
      headerName: 'Type',
      flex: 1,
      valueGetter: (params) => {
        return params.row.surveyOrderType as SurveyOrderType;
      },
      renderCell: (params) => {
        return SurveyOrderType[params.row.surveyOrderType as SurveyOrderType];
      }
    },
    {
      field: 'smsUsage',
      headerName: 'Sms usage',
      type: 'number',
      flex: 1,
      renderCell: (params) => {
        const smsQuota = params.row.smsQuota as number;
        const smsUsage = params.row.smsUsage as number;
        if (smsQuota !== undefined && smsUsage !== undefined) {
          return `${smsUsage} / ${smsQuota}`;
        }
      }
    },
    {
      field: 'emailUsage',
      headerName: 'Email usage',
      type: 'number',
      flex: 1,
      renderCell: (params) => {
        const emailQuota = params.row.emailQuota as number;
        const emailUsage = params.row.emailUsage as number;
        if (emailQuota !== undefined && emailUsage !== undefined) {
          return `${emailUsage} / ${emailQuota}`;
        }
      }
    },
    {
      field: 'surveyQuota',
      headerName: 'Total usage',
      type: 'number',
      flex: 1,
      renderCell: (params) => {
        const emailQuota = params.row.emailQuota as number;
        const smsQuota = params.row.smsQuota as number;
        const emailUsage = params.row.emailUsage as number;
        const smsUsage = params.row.smsUsage as number;
        return `${emailUsage + smsUsage} / ${
          params.row.surveyQuota ? params.row.surveyQuota : emailQuota + smsQuota
        }`;
      }
    },
    ...baseOrderColumns
  ];

  const activeOrders = useMemo(() => {
    return orderData.orders.filter((o) => o.active === true);
  }, [orderData.orders]);

  const totalSmsUsage = useMemo(
    () =>
      activeOrders.reduce((a, b) => {
        const orderB = b as SurveyOrder;
        return a + orderB.smsUsage;
      }, 0),
    [activeOrders]
  );

  const totalEmailUsage = useMemo(
    () =>
      activeOrders.reduce((a, b) => {
        const orderB = b as SurveyOrder;
        return a + orderB.emailUsage;
      }, 0),
    [activeOrders]
  );

  const totalSmsQuota = useMemo(
    () =>
      activeOrders.reduce((a, b) => {
        const orderB = b as SurveyOrder;
        return a + orderB.smsQuota;
      }, 0),
    [activeOrders]
  );

  const totalEmailQuota = useMemo(
    () =>
      activeOrders.reduce((a, b) => {
        const orderB = b as SurveyOrder;
        return a + orderB.emailQuota;
      }, 0),
    [activeOrders]
  );

  return (
    <>
      <Stack direction={'row'} gap={4} marginBottom={4}>
        <UsageIndicator
          usageType="Total sms"
          quota={totalSmsQuota}
          usage={totalSmsUsage}
          loading={isLoading}
        />
        <UsageIndicator
          usageType="Total email"
          quota={totalEmailQuota}
          usage={totalEmailUsage}
          loading={isLoading}
        />
      </Stack>
      <CustomDataGrid
        intitialColumnsHidden={{
          surveyQuota: true,
          smsUsage: true,
          emailUsage: true,
          surveyOrderType: true,
          ...baseOrderInitialColumnsHidden
        }}
        dataGridKey={DataGridKey.Orders}
        columns={columns}
        loading={isLoading}
        rows={orderData.orders}
        onCellClick={(params) => {
          navigate(`${PATH_DASHBOARD.orders.surveyOrders}/${params.id}`);
        }}
        pagination
        pageSize={20}
      />
    </>
  );
}
