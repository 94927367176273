import AuthLayout from 'layouts/AuthLayout';
import { SectionStyle } from './Styles';
import { Box, Typography } from '@mui/material';
import { MHidden } from 'components/@material-extend';

const LoginGreeting = () => {
  return (
    <MHidden width="mdDown">
      <SectionStyle>
        <AuthLayout />
        <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
          Hi, Welcome Back
        </Typography>
        <Box p={5}>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </Box>
      </SectionStyle>
    </MHidden>
  );
};
export default LoginGreeting;
