import { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import CustomButton, { CustomLoadingButton } from './CustomButton';
import CustomTextField from './CustomTextField';
import useIsMountedRef from 'hooks/useIsMountedRef';

export enum AreYouSureModalType {
  Continue = 'Continue',
  Delete = 'Delete',
  Merge = 'Merge'
}

type UnsavedChangesModalProps = {
  type: keyof typeof AreYouSureModalType;
  display: boolean;
  hideModal: () => void;
  title: string;
  description: string | JSX.Element;
  onContinue: (() => void) | (() => Promise<any>);
  useConfirmationSafeguard?: boolean;
};

const AreYouSureModal = ({
  type,
  display,
  hideModal,
  title,
  description,
  onContinue,
  useConfirmationSafeguard
}: UnsavedChangesModalProps) => {
  const [isContinueLoading, setIsContinueLoading] = useState(false);
  const isMountedRef = useIsMountedRef();

  const onClose = () => {
    hideModal();
  };
  const onClickContinue = async () => {
    setIsContinueLoading(true);
    await onContinue();
    if (!isMountedRef.current) return;
    setIsContinueLoading(false);
    hideModal();
  };

  const [disableAction, setDisableAction] = useState(useConfirmationSafeguard);
  const confirmationText = type.toUpperCase();

  useEffect(() => {
    setDisableAction(useConfirmationSafeguard);
  }, [display, useConfirmationSafeguard]);

  const onConfirmationSafeguardChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredConfirmationSafeguard = event.target.value;
    setDisableAction(enteredConfirmationSafeguard !== confirmationText);
  };

  const isDelete = AreYouSureModalType[type] === AreYouSureModalType.Delete;

  return (
    <Dialog open={display} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {typeof description === 'string' ? (
          <Typography>{description}</Typography>
        ) : (
          <>{description}</>
        )}
      </DialogContent>
      <DialogActions>
        {useConfirmationSafeguard && (
          <CustomTextField
            autoFocus
            style={{ width: '15rem', marginRight: '1rem' }}
            label={`Type "${confirmationText}" to confirm`}
            variant="standard"
            onChange={onConfirmationSafeguardChange}
          />
        )}
        <CustomLoadingButton
          disabled={disableAction}
          color={isDelete ? 'error' : 'primary'}
          variant={isDelete ? 'outlined' : 'contained'}
          onClick={onClickContinue}
          loading={isContinueLoading}
        >
          {AreYouSureModalType[type]}
        </CustomLoadingButton>
        <CustomButton
          color="primary"
          variant={!isDelete ? 'outlined' : 'contained'}
          onClick={onClose}
        >
          Cancel
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default AreYouSureModal;
