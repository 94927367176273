import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';
import {
  FeedbackAppAccessDto,
  FeedbackAppConfigurationDataState,
  FeedbackAppConfigurationDto
} from '../../@types/productConfiguration';

const initialState: FeedbackAppConfigurationDataState = {
  isLoading: false,
  isDeleteLoading: false,
  configuration: undefined,
  isConfigurationLoading: false
};

const slice = createSlice({
  name: 'productConfigurations',
  initialState,
  reducers: {
    // General use
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    startDeleteLoading(state) {
      state.isDeleteLoading = true;
    },

    stopDeleteLoading(state) {
      state.isDeleteLoading = false;
    },

    addConfigurationSuccess(state, action) {
      state.isLoading = false;
    },

    startGetConfiguration(state) {
      state.isConfigurationLoading = true;
    },

    stopIsConfigurationLoading(state) {
      state.isConfigurationLoading = false;
    },

    getConfigurationSuccess(state, action) {
      state.isLoading = false;
      state.configuration = action.payload;
    },

    editConfigurationSuccess(state, action) {
      state.isLoading = false;
    },
    setConfiguration(state, action) {
      state.configuration = action.payload;
      state.isConfigurationLoading = false;
    },
    resetConfigurations(state) {
      state.configuration = undefined;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setConfiguration } = slice.actions;

// ----------------------------------------------------------------------

export function resetConfigurations() {
  dispatch(slice.actions.resetConfigurations());
}

const feedbackAppEndpoint = '/feedback-app';
const accessConfigEndpoint = `${feedbackAppEndpoint}/access-configuration`;
const configurationEndpoint = `${feedbackAppEndpoint}/configuration`;

export function getConfiguration() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(feedbackAppEndpoint);
      dispatch(slice.actions.getConfigurationSuccess(response.data));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Configurations fetched'
      });
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch configurations'
      });
    }
  };
}

type EditAccessConfigurationProps = {
  configuration: FeedbackAppAccessDto;
};
export function editAccessConfiguration({ configuration }: EditAccessConfigurationProps) {
  dispatch(slice.actions.startLoading());
  return async () => {
    try {
      const response = await axios.put(accessConfigEndpoint, configuration);
      dispatch(slice.actions.editConfigurationSuccess(response));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Configuration updated'
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not update configuration'
      });
    }
  };
}

type AddAccessConfigurationProps = {
  configuration: FeedbackAppAccessDto;
};
export function addAccessConfiguration({ configuration }: AddAccessConfigurationProps) {
  dispatch(slice.actions.startLoading());
  return async () => {
    try {
      const response = await axios.post(accessConfigEndpoint, configuration);
      dispatch(slice.actions.addConfigurationSuccess(response));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Access configuration added'
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading());
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not add access configuration'
      });
    }
  };
}

type AddConfigurationProps = {
  configuration: FeedbackAppConfigurationDto;
};
export function addOrUpdateConfiguration({ configuration }: AddConfigurationProps) {
  dispatch(slice.actions.startLoading());
  return async () => {
    try {
      const response = await axios.put(configurationEndpoint, configuration);
      dispatch(slice.actions.addConfigurationSuccess(response));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Configuration saved'
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading());
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not save configuration'
      });
    }
  };
}

type GrantAllAccessProps = {
  configuration: FeedbackAppAccessDto;
};
export function grantAccessToAll({ configuration }: GrantAllAccessProps) {
  dispatch(slice.actions.startLoading());
  return async () => {
    try {
      const response = await axios.put(`${accessConfigEndpoint}/grant-all`, configuration);
      dispatch(slice.actions.addConfigurationSuccess(response));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Access granted to all active employees'
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading());
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not grant access to all employees'
      });
    }
  };
}

type RevokeAllAccessProps = {
  configuration: FeedbackAppAccessDto;
};
export function revokeAccessFromAll({ configuration }: RevokeAllAccessProps) {
  dispatch(slice.actions.startLoading());
  return async () => {
    try {
      const response = await axios.put(`${accessConfigEndpoint}/revoke-all`, configuration);
      dispatch(slice.actions.addConfigurationSuccess(response));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Access revoked from all employees'
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading());
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not revoke access from all employees'
      });
    }
  };
}
