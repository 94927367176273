import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography
} from '@mui/material';
import CustomButton, { CustomLoadingButton } from 'components/util/CustomButton';
import useCustomDispatch from 'redux/dispatch';
import { RootState, useSelector } from 'redux/store';
import { getAutomaticAnonymization, setAutomaticAnonymization } from 'redux/slices/organization';
import { FormikValues } from 'formik';
import { useTranslator } from 'translation/useTranslator';
import { ReactNode } from 'react';

export type AutomaticAnonymizationChange = {
  currentAnnonymizeAfterDays?: number | null;
  currentAnonymizeEmployees: boolean;
  currentAnonymizeCompanies: boolean;
  currentAnonymizeUsers: boolean;
  newAnonymizeAfterDays: number | null;
  newAnonymizeEmployees: boolean;
  newAnonymizeCompanies: boolean;
  newAnonymizeUsers: boolean;
};
type ChangeAutomaticAnonymizationProps = {
  automaticAnonymizationChange: AutomaticAnonymizationChange;
  isOpen: boolean;
  closeDialog: () => void;
  formik: FormikValues;
};
const ChangeAutomaticAnonymization = ({
  automaticAnonymizationChange,
  isOpen,
  closeDialog,
  formik
}: ChangeAutomaticAnonymizationProps) => {
  const translator = useTranslator();
  const customDispatch = useCustomDispatch();

  const { loading } = useSelector((state: RootState) => state.organization);
  const {
    currentAnnonymizeAfterDays,
    currentAnonymizeEmployees,
    currentAnonymizeCompanies,
    currentAnonymizeUsers,
    newAnonymizeAfterDays,
    newAnonymizeEmployees,
    newAnonymizeCompanies,
    newAnonymizeUsers
  } = automaticAnonymizationChange;

  const getDialogText = (): ReactNode => {
    let anonymizationText = !newAnonymizeAfterDays
      ? translator.disableAutomaticAnonymizationDescription(
          calculateFullYears(currentAnnonymizeAfterDays),
          calculateRemainingDays(currentAnnonymizeAfterDays),
          `${currentAnnonymizeAfterDays}`
        )
      : translator.changeAnonymizationConfigurationDescription(
          calculateFullYears(currentAnnonymizeAfterDays),
          calculateRemainingDays(currentAnnonymizeAfterDays),
          `${currentAnnonymizeAfterDays}`,
          calculateFullYears(newAnonymizeAfterDays),
          calculateRemainingDays(newAnonymizeAfterDays),
          `${newAnonymizeAfterDays}`
        );

    let employeeSettingChangedToEnabled =
      currentAnonymizeEmployees !== newAnonymizeEmployees && newAnonymizeEmployees === true;
    let companySettingChangedToEnabled =
      currentAnonymizeCompanies !== newAnonymizeCompanies && newAnonymizeCompanies === true;
    let userSettingChangedToEnabled =
      currentAnonymizeUsers !== newAnonymizeUsers && newAnonymizeUsers === true;
    return (
      <Stack gap={1}>
        <Typography>{anonymizationText}</Typography>
        {employeeSettingChangedToEnabled === true && (
          <Typography>{translator.employeeAnonymizationEnabled()}</Typography>
        )}
        {companySettingChangedToEnabled && (
          <Typography>{translator.companyAnonymizationEnabled()}</Typography>
        )}
        {userSettingChangedToEnabled && (
          <Typography>{translator.userAnonymizationEnabled()}</Typography>
        )}
      </Stack>
    );
  };

  const calculateFullYears = (days: number | undefined | null): string => {
    if (!days) {
      return '0';
    }
    const years = Math.floor(days / 365);
    return `${years}`;
  };

  const calculateRemainingDays = (days: number | undefined | null): string => {
    if (!days) {
      return '0';
    }
    const restDays = days % 365;
    return `${restDays}`;
  };

  const title = !newAnonymizeAfterDays
    ? translator.disableAutomaticAnonymization()
    : translator.changeAutomaticAnonymization();

  const onClose = () => {
    closeDialog();
    formik.resetForm();
  };

  const onChange = () => {
    customDispatch({
      action: setAutomaticAnonymization,
      actionParameters: {
        anonymizeAfterDays: newAnonymizeAfterDays,
        anonymizeEmployees: newAnonymizeEmployees,
        anonymizeCompanies: newAnonymizeCompanies,
        anonymizeUsers: newAnonymizeUsers
      },
      onSuccess: () => {
        customDispatch({
          action: getAutomaticAnonymization,
          disableSuccessMessage: true
        });
        onClose();
      }
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose} disableRestoreFocus>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{getDialogText()}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomLoadingButton
          loading={loading.setAutomaticAnonymization}
          color="primary"
          variant="contained"
          onClick={onChange}
        >
          {translator.continue()}
        </CustomLoadingButton>

        <CustomButton color="primary" onClick={onClose}>
          {translator.cancel()}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeAutomaticAnonymization;
