export enum ReviewCategory {
  consent = 'consent',
  public = 'public',
  flagged = 'flagged',
  configuration = 'configuration'
}

export type Respondent = {
  id: number;
  address1: null;
  address2: null;
  city: null;
  country: null;
  zipCode: null;
  emailAddress: string;
  firstName: string;
  lastName: null;
  active: boolean;
  title: null;
  company: null;
  employee: null;
  department: null;
  phoneNumber: null;
  externalId: string;
};

export type Review = {
  id: number;
  rating: number;
  ratingTime: Date;
  userComment: null;
  isPublic: boolean;
  isFlagged: boolean;
  category: null;
  respondent: Respondent;
  campaignId: number;
  campaignName: string;
  device: string;
};

export type ReviewsState = {
  isLoading: boolean;
  consentReviews: Review[];
  publicReviews: Review[];
  flaggedReviews: Review[];
  total: number;
  skip: number;
  take: number;
};

export interface ReviewConfigurationDto {
  reviewDescription?: string;
  reviewName?: string;
  enablePublicReviews?: boolean;
}
