import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import DisabledIcon from 'components/util/Status/DisabledIcon';
import EnabledIcon from 'components/util/Status/EnabledIcon';
import { fShortDate, getEndDate } from 'utils/formatTime';

export const baseOrderColumns: GridColDef[] = [
  {
    field: 'id'
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1
  },
  {
    field: 'notes',
    headerName: 'Notes',
    flex: 1
  },
  {
    field: 'active',
    headerName: 'Active',
    type: 'boolean',
    flex: 1,
    renderCell: (params) => {
      const status = params.row.active as boolean;
      return status ? <EnabledIcon tooltip={'Active'} /> : <DisabledIcon tooltip={'Inactive'} />;
    }
  },
  {
    field: 'renewable',
    headerName: 'Renewable',
    type: 'boolean',
    flex: 1,
    renderCell: (params) => {
      const status = params.row.renewable as boolean;
      return status ? (
        <EnabledIcon tooltip={'Renewable'} />
      ) : (
        <DisabledIcon tooltip={'Not renewable'} />
      );
    }
  },
  {
    field: 'startedat',
    headerName: 'Start Date',
    flex: 1,
    renderCell: (params) => {
      const startDate = params.row.startedAt;
      try {
        const date = fShortDate(new Date(startDate));
        return date;
      } catch (error) {
        return `Invalid date: ${startDate}. Error: ${error}`;
      }
    }
  },
  {
    field: 'usageintervalmonths',
    headerName: 'End Date',
    flex: 1,
    renderCell: (params) => {
      if (!params.row.usageIntervalMonths) {
        return 'No end date';
      }
      const startDate = new Date(params.row.startedAt);
      const usagePeriod = params.row.usageIntervalMonths as number;
      return getEndDate(startDate, usagePeriod);
    }
  },
  {
    field: 'paymentintervalmonths',
    headerName: 'Billing Date',
    flex: 1,
    renderCell: (params) => {
      if (!params.row.usageIntervalMonths) {
        return 'No billing date';
      }
      const startDate = new Date(params.row.startedAt);
      const paymentPeriod = params.row.paymentIntervalMonths as number;
      return getEndDate(startDate, paymentPeriod);
    }
  }
];

export const baseOrderInitialColumnsHidden: GridColumnVisibilityModel = {
  description: true,
  active: true,
  renewable: false,
  notes: false,
  startedat: false,
  usageintervalmonths: true,
  paymentintervalmonths: true
};
