import { Message } from 'components/messages/MessageContent';
import { CustomDispatchSuccessResponse } from 'redux/dispatch';

type GetSuccessMessageContentProps = {
  successResponse: CustomDispatchSuccessResponse;
};
export const getSuccessMessageContent = ({ successResponse }: GetSuccessMessageContentProps) => {
  let description = '';
  if (typeof successResponse?.result?.data?.message === 'string') {
    description = successResponse.result.data.message;
  }

  return {
    title: successResponse.defaultSuccessMessage,
    description
  } as Message;
};
