import { useTheme } from '@mui/system';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/styles';
import { IconType } from '../../../@types/icons';
import Icon from 'components/icons/Icon';

type StatusIconProps = {
  tooltip?: string;
  size?: string;
  color: string;
  icon: IconType;
};
const StatusIcon = ({ tooltip = '', size = '20px', color, icon }: StatusIconProps) => {
  const theme = useTheme();
  return (
    <IconWrapper size={size}>
      <Tooltip title={tooltip}>
        <Icon
          type={icon}
          id="statusIcon"
          sx={{
            color: color,
            fontSize: size
          }}
          fill={theme.palette.background.paper}
        />
      </Tooltip>
    </IconWrapper>
  );
};
export default StatusIcon;

const IconWrapper = styled('div')(({ theme, size }: { size: string } & any) => ({
  position: 'relative',
  width: size,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
  borderRadius: '50%'
}));
