import { useMemo } from 'react';
import { RootState, useSelector } from 'redux/store';
import { resetIvrTest, testIvrConnection } from 'redux/slices/ivr';

import { TimeSpan } from '../../../@types/dashboard';
import { useCustomDispatchWithoutPromise } from 'redux/dispatch';
import TestEndpointDialog from 'components/test/TestEndpointDialog';
import { getIvrTestUrl } from '../util/getIvrTestUrl';

const TestIvrDialog = () => {
  const customDispatchWithoutPromise = useCustomDispatchWithoutPromise();
  const { ivrToTest, ivrTypes } = useSelector((state: RootState) => state.ivr);

  const onClose = () => {
    customDispatchWithoutPromise({ action: resetIvrTest, disableSuccessMessage: true });
  };

  const actionParameters = useMemo(() => {
    const ivrTestUrl = getIvrTestUrl({
      ivrName: ivrToTest?.provider?.name ?? undefined,
      ivrId: ivrToTest?.provider?.id ?? undefined,
      ivrTypes
    });
    return {
      action: testIvrConnection,
      actionParameters: { ivr: ivrToTest, testUrl: ivrTestUrl },
      disableAllMessages: true
    };
  }, [ivrToTest, ivrTypes]);

  return (
    <TestEndpointDialog
      isOpen={Boolean(ivrToTest)}
      close={onClose}
      title="Test ivr connection"
      dispatchProps={actionParameters}
      timespanParams={{
        initialTimespan: TimeSpan.TODAY,
        maxTimeSpan: TimeSpan.LAST_30_DAYS
      }}
    />
  );
};

export default TestIvrDialog;
