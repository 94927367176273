import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { List } from '@mui/material';
import Icon from 'components/icons/Icon';

type DocumentationProps = {
  handleClose: () => void;
};
const Documentation = ({ handleClose }: DocumentationProps) => {
  return (
    <List>
      <ListItem disablePadding>
        <ListItemButton href="https://docs.nps.today/" target="_blank" onClick={handleClose}>
          <ListItemIcon>
            <Icon type="note" />
          </ListItemIcon>
          <ListItemText primary="Documentation" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          target="_blank"
          href="https://docs.nps.today/guides/faq/"
          onClick={handleClose}
        >
          <ListItemIcon>
            <Icon type="question" />
          </ListItemIcon>
          <ListItemText primary="FAQ" />
        </ListItemButton>
      </ListItem>
    </List>
  );
};
export default Documentation;
