import { createSlice } from '@reduxjs/toolkit';
import { DataGridState } from '../../@types/datagrid';
import { GridFilterModel, GridLinkOperator, GridSortItem } from '@mui/x-data-grid';

export enum DataGridKey {
  CampaignList = 'CampaignList',
  Employees = 'Employees',
  ThrottlingGroups = 'ThrottlingGroups',
  ThrottlingGroupCampaigns = 'ThrottlingGroupCampaigns',
  OrganizationsList = 'OrganizationsList',
  CampaignMembersResponses = 'CampaignMembersResponses',
  CampaignMembersDelivered = 'CampaignMembersDelivered',
  CampaignMembersScheduled = 'CampaignMembersScheduled',
  CampaignMembersUnsubscribed = 'CampaignMembersUnsubscribed',
  CampaignMembersFailed = 'CampaignMembersFailed',
  Companies = 'Companies',
  Etrack1Maps = 'Etrack1Maps',
  IVRList = 'IVRList',
  LogoList = 'LogoList',
  SendersList = 'SendersList',
  OrganizationUsers = 'OrganizationUsers',
  OutlookList_Access = 'OutlookList_Access',
  OutlookList_NoAccess = 'OutlookList_NoAccess',
  PGapList = 'PGapList',
  PGapAddEmployees = 'PGapAddEmployees',
  Reports = 'Reports',
  Respondents = 'Respondents',
  Reviews = 'Reviews',
  TemplateList = 'TemplateList',
  Orders = 'Orders',
  NpsNotifications = 'NpsNotifications'
}

type getFilterModelProps = {
  columnField: string;
  operatorValue: string;
};
const getFilterModel = ({ columnField, operatorValue }: getFilterModelProps): GridFilterModel => {
  return {
    items: [
      {
        columnField,
        operatorValue
      }
    ],
    linkOperator: GridLinkOperator.And,
    quickFilterValues: [],
    quickFilterLogicOperator: GridLinkOperator.And
  };
};

type DataGridFiltering = { [key in DataGridKey]: GridFilterModel };
export const defaultFiltering: DataGridFiltering = {
  [DataGridKey.NpsNotifications]: getFilterModel({
    columnField: 'name',
    operatorValue: 'contains'
  }),
  [DataGridKey.OrganizationsList]: getFilterModel({
    columnField: 'organizationName',
    operatorValue: 'contains'
  }),
  [DataGridKey.Companies]: getFilterModel({
    columnField: 'name',
    operatorValue: 'contains'
  }),
  [DataGridKey.Etrack1Maps]: getFilterModel({
    columnField: 'name',
    operatorValue: 'contains'
  }),
  [DataGridKey.IVRList]: getFilterModel({
    columnField: 'name',
    operatorValue: 'contains'
  }),
  [DataGridKey.LogoList]: getFilterModel({
    columnField: 'fileName',
    operatorValue: 'contains'
  }),
  [DataGridKey.SendersList]: getFilterModel({
    columnField: 'sender',
    operatorValue: 'contains'
  }),
  [DataGridKey.OrganizationUsers]: getFilterModel({
    columnField: 'name',
    operatorValue: 'contains'
  }),
  [DataGridKey.OutlookList_Access]: getFilterModel({
    columnField: 'name',
    operatorValue: 'contains'
  }),
  [DataGridKey.OutlookList_NoAccess]: getFilterModel({
    columnField: 'name',
    operatorValue: 'contains'
  }),
  [DataGridKey.PGapList]: getFilterModel({
    columnField: 'campaign/name',
    operatorValue: 'contains'
  }),
  [DataGridKey.ThrottlingGroupCampaigns]: getFilterModel({
    columnField: 'name',
    operatorValue: 'contains'
  }),
  [DataGridKey.PGapAddEmployees]: getFilterModel({
    columnField: 'firstName',
    operatorValue: 'contains'
  }),
  [DataGridKey.Reports]: getFilterModel({
    columnField: 'name',
    operatorValue: 'contains'
  }),
  [DataGridKey.Respondents]: getFilterModel({
    columnField: 'name',
    operatorValue: 'contains'
  }),
  [DataGridKey.Reviews]: getFilterModel({
    columnField: '',
    operatorValue: ''
  }),
  [DataGridKey.CampaignList]: getFilterModel({
    columnField: 'name',
    operatorValue: 'contains'
  }),
  [DataGridKey.Employees]: getFilterModel({
    columnField: 'firstName',
    operatorValue: 'contains'
  }),
  [DataGridKey.TemplateList]: getFilterModel({
    columnField: 'name',
    operatorValue: 'contains'
  }),
  [DataGridKey.ThrottlingGroups]: getFilterModel({
    columnField: 'name',
    operatorValue: 'contains'
  }),
  [DataGridKey.CampaignMembersResponses]: getFilterModel({
    columnField: 'firstName',
    operatorValue: 'contains'
  }),
  [DataGridKey.CampaignMembersDelivered]: getFilterModel({
    columnField: 'firstName',
    operatorValue: 'contains'
  }),
  [DataGridKey.CampaignMembersScheduled]: getFilterModel({
    columnField: 'firstName',
    operatorValue: 'contains'
  }),
  [DataGridKey.CampaignMembersUnsubscribed]: getFilterModel({
    columnField: 'firstName',
    operatorValue: 'contains'
  }),
  [DataGridKey.CampaignMembersFailed]: getFilterModel({
    columnField: 'firstName',
    operatorValue: 'contains'
  }),
  [DataGridKey.Orders]: getFilterModel({
    columnField: 'description',
    operatorValue: 'contains'
  })
};

type DataGridSorting = { [key in DataGridKey]: GridSortItem[] };
export const defaultSorting: DataGridSorting = {
  [DataGridKey.OrganizationsList]: [{ field: 'organizationName', sort: 'asc' }],
  [DataGridKey.Companies]: [{ field: 'name', sort: 'asc' }],
  [DataGridKey.Etrack1Maps]: [{ field: 'name', sort: 'asc' }],
  [DataGridKey.IVRList]: [{ field: 'name', sort: 'asc' }],
  [DataGridKey.LogoList]: [],
  [DataGridKey.SendersList]: [{ field: 'sender', sort: 'asc' }],
  [DataGridKey.OrganizationUsers]: [{ field: 'name', sort: 'asc' }],
  [DataGridKey.OutlookList_Access]: [{ field: 'name', sort: 'asc' }],
  [DataGridKey.OutlookList_NoAccess]: [{ field: 'name', sort: 'asc' }],
  [DataGridKey.PGapList]: [{ field: 'campaign/name', sort: 'asc' }],
  [DataGridKey.PGapAddEmployees]: [{ field: 'firstName', sort: 'asc' }],
  [DataGridKey.Reports]: [{ field: 'name', sort: 'asc' }],
  [DataGridKey.Respondents]: [{ field: 'firstName', sort: 'asc' }],
  [DataGridKey.Reviews]: [],
  [DataGridKey.CampaignList]: [{ field: 'created', sort: 'desc' }],
  [DataGridKey.Employees]: [{ field: 'firstName', sort: 'asc' }],
  [DataGridKey.TemplateList]: [{ field: 'created', sort: 'desc' }],
  [DataGridKey.ThrottlingGroups]: [{ field: 'name', sort: 'asc' }],
  [DataGridKey.ThrottlingGroupCampaigns]: [{ field: 'name', sort: 'asc' }],
  [DataGridKey.CampaignMembersResponses]: [{ field: 'response/rated', sort: 'desc' }],
  [DataGridKey.Orders]: [{ field: 'active', sort: 'desc' }],
  [DataGridKey.NpsNotifications]: [{ field: 'name', sort: 'desc' }],

  //The below tables cannot yet sort on the scheduled time (or other datetime values taken from the sms/email tables)
  [DataGridKey.CampaignMembersDelivered]: [{ field: 'id', sort: 'desc' }], //surveySendDate
  [DataGridKey.CampaignMembersScheduled]: [{ field: 'id', sort: 'desc' }], //surveyScheduleDate
  [DataGridKey.CampaignMembersUnsubscribed]: [{ field: 'id', sort: 'desc' }], //surveyScheduleDate
  [DataGridKey.CampaignMembersFailed]: [{ field: 'id', sort: 'desc' }] //surveyScheduleDate
};

export const initialState: DataGridState = {
  density: {},
  sorting: defaultSorting,
  filtering: defaultFiltering,
  columnVisibility: {},
  columnVisibilityModel: {
    id: false,
    edit: true
  },
  pinnedColumns: {},
  columnOrder: {}
};

export const persistsVersion = 2;
// Function to apply default updates to state
const getDefaultState = (state: any) => {
  return {
    ...state,
    sorting: defaultSorting,
    filtering: defaultFiltering,
    columnVisibilityModel: {
      id: false,
      edit: true
    }
  };
};
export const migrations = {
  //updated initial sorting on all tables
  0: (state: any) => getDefaultState(state),
  //updated initial sorting on all tables - to ensure changes for CampaignsList columns are applied
  1: (state: any) => getDefaultState(state),
  //ensure that any sorting/filtering on datagrid 'edit' columns are reset
  2: (state: any) => getDefaultState(state)
};

const slice = createSlice({
  name: 'datagrid',
  initialState,
  reducers: {
    setDensity(state, action) {
      state.density[action.payload[0]] = action.payload[1];
    },

    setSorting(state, action) {
      state.sorting[action.payload[0]] = action.payload[1];
    },

    setFiltering(state, action) {
      state.filtering[action.payload[0]] = action.payload[1];
    },

    setColumnVisibility(state, action) {
      state.columnVisibility[action.payload[0]] = action.payload[1];
    },

    setPinnedColumns(state, action) {
      state.pinnedColumns[action.payload[0]] = action.payload[1];
    },

    setColumnOrder(state, action) {
      const dataGridKey = action.payload[0];
      state.columnOrder[dataGridKey] = action.payload[1];
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setDensity,
  setSorting,
  setFiltering,
  setColumnVisibility,
  setPinnedColumns,
  setColumnOrder
} = slice.actions;
