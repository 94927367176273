import { CLAIMS } from '../../../@types/claims';
import { RootState, useSelector } from 'redux/store';
import { setClaimsToIgnore, setExtraClaims } from 'redux/slices/admin';
import SuperAdminIcon from './SuperAdminIcon';
import BetaIcon from './BetaIcon';
import { useCustomDispatchWithoutPromise } from 'redux/dispatch';
import CustomSwitch from 'components/util/CustomSwitch';

const AdminGeneral = () => {
  const { claimsToIgnore, extraClaims } = useSelector((state: RootState) => state.admin);
  const customDispatch = useCustomDispatchWithoutPromise();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <CustomSwitch
        label={
          <span>
            Show Super Admin features - marked with <SuperAdminIcon />
          </span>
        }
        labelPlacement="end"
        switchProps={{
          onChange: (event) => {
            const checked = event.target.checked;
            customDispatch({
              action: setClaimsToIgnore,
              actionParameters: !checked ? [CLAIMS.management.superadmin] : [],
              disableSuccessMessage: true
            });
          },
          checked: !claimsToIgnore.includes(CLAIMS.management.superadmin)
        }}
      />
      <CustomSwitch
        label={
          <span style={{ display: 'flex', gap: '.2rem', alignItems: 'center' }}>
            Show beta features - marked with <BetaIcon />
          </span>
        }
        labelPlacement="end"
        switchProps={{
          onChange: (event) => {
            const checked = event.target.checked;
            customDispatch({
              action: setExtraClaims,
              actionParameters: checked ? [CLAIMS.beta] : [],
              disableSuccessMessage: true
            });
          },
          checked: extraClaims.includes(CLAIMS.beta)
        }}
      />
    </div>
  );
};

export default AdminGeneral;
