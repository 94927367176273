import { IconButton } from '@mui/material';
import { Tooltip } from '@mui/material';
import Icon from 'components/icons/Icon';

type ToggleEditProps = {
  isEditable: boolean;
  onToggleEditable: () => void;
};
const ToggleEdit = ({ isEditable, onToggleEditable }: ToggleEditProps) => {
  return (
    <Tooltip title="Toggle editable">
      <IconButton color={isEditable ? 'primary' : 'default'} onClick={onToggleEditable}>
        <Icon type="edit" />
      </IconButton>
    </Tooltip>
  );
};

export default ToggleEdit;
