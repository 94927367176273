import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Stack,
  TextField
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { Claim } from '../../../@types/organization';
import { Form, FormikProvider, getIn, useFormik } from 'formik';
import { Dispatch, SetStateAction, useEffect } from 'react';
import * as Yup from 'yup';
import {
  CustomDataGrid,
  CustomGridToolbar,
  CustomLoadingOverlay,
  CustomPagination
} from '../../../components/datagrid/Custom';
import { addUser, deleteUsers } from '../../../redux/slices/organization';
import { RootState, useSelector } from '../../../redux/store';
import { fDate } from '../../../utils/formatTime';
import { capitalCase } from 'change-case';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'routes/paths';
import EmailConfirmed from './EmailConfirmed';
import useCustomDispatch from 'redux/dispatch';
import CustomButton, { CustomLoadingButton } from 'components/util/CustomButton';
import { getPermissions } from 'redux/slices/permissions';
import { LICENSE_CLAIM_TYPES, MANAGEMENT_CLAIM_TYPES } from '../../../@types/claims';
import { DataGridKey } from 'redux/slices/datagrid';
import { ActiveListItem } from 'components/datagrid/listItems/ActiveListItem';
import { useTranslator } from 'translation/useTranslator';

export default function OrganizationUsers({
  selectedUsers,
  setSelectedUsers,
  openAddUser,
  setOpenAddUser,
  openDeleteUsers,
  setOpenDeleteUsers
}: {
  selectedUsers: any[];
  setSelectedUsers: Dispatch<SetStateAction<any[]>>;
  openAddUser: boolean;
  setOpenAddUser: Dispatch<SetStateAction<boolean>>;
  openDeleteUsers: boolean;
  setOpenDeleteUsers: Dispatch<SetStateAction<boolean>>;
}) {
  const translator = useTranslator();
  const customDispatch = useCustomDispatch();
  const navigate = useNavigate();
  const DATA_GRID_KEY = DataGridKey.OrganizationUsers;
  const { users, loading } = useSelector((state: RootState) => state.organization);
  const { assignableRoles, isLoading: isPermissionsLoading } = useSelector(
    (state: RootState) => state.permissions
  );

  useEffect(() => {
    customDispatch({ action: getPermissions, disableSuccessMessage: true });
  }, [customDispatch]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'id',
      flex: 1
    },
    {
      field: 'name',
      hide: false,
      headerName: translator.name(),
      flex: 1
    },
    {
      field: 'userName',
      hide: false,
      headerName: translator.username(),
      flex: 1
    },
    {
      field: 'created',
      headerName: translator.created(),
      flex: 1,
      hide: false,
      renderCell: (params) => {
        const created = params.row.created as string;
        return fDate(created);
      }
    },
    {
      field: 'licenseClaims',
      headerName: translator.licenses(),
      flex: 1,
      hide: false,
      valueGetter: (params) => {
        const claims = params.row.claims as Claim[];
        const applicationClaims = claims?.filter((claim: Claim) =>
          LICENSE_CLAIM_TYPES.includes(claim.claimType)
        );
        const claimValues =
          applicationClaims?.map((claim: Claim) => capitalCase(claim.claimValue)) || [];
        return claimValues.join(', ');
      }
    },
    {
      field: 'managementClaims',
      headerName: translator.management(),
      flex: 1,
      hide: false,
      valueGetter: (params) => {
        const claims = params.row.claims as Claim[];
        const managementClaims = claims?.filter((claim: Claim) =>
          MANAGEMENT_CLAIM_TYPES.includes(claim.claimType)
        );
        const claimValues =
          managementClaims?.map((claim: Claim) => capitalCase(claim.claimValue)) || [];
        return claimValues.join(', ');
      }
    },
    {
      field: 'emailConfirmed',
      headerName: translator.emailConfirmed(),
      type: 'boolean',
      hide: false,
      width: 150,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: (params) => {
        const userId = params.row.userId;
        const userEmail = params.row.email;
        const emailConfirmed = params.row.emailConfirmed;
        return (
          <EmailConfirmed userId={userId} userEmail={userEmail} emailConfirmed={emailConfirmed} />
        );
      }
    },
    {
      field: 'active',
      headerName: translator.active(),
      type: 'boolean',
      width: 100,
      renderCell: (params) => {
        const active = params.row.active;
        return <ActiveListItem active={active} activeTooltip="" inactiveTooltip="" />;
      }
    }
  ];

  let isLoading = loading.user;

  const Schema = Yup.object().shape({
    email: Yup.string()
      .email(translator.invalidEmail())
      .required(translator.fieldRequiredError(translator.email())),
    role: Yup.number().required(translator.fieldRequiredError(translator.role()))
  });

  let formik = useFormik({
    initialValues: {
      email: '',
      role: ''
    },
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setOpenAddUser(false);
      customDispatch({
        action: addUser,
        actionParameters: values,
        onFinally: () => {
          setSubmitting(false);
        }
      });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const deleteSelectedUsers = async () => {
    setOpenDeleteUsers(false);

    customDispatch({
      action: deleteUsers,
      actionParameters: selectedUsers,
      onSuccess: () => {
        setOpenAddUser(false);
      },
      onFinally: () => {
        setSelectedUsers([]);
      }
    });
  };

  function DeleteUsersDialog() {
    return (
      <Dialog open={openDeleteUsers}>
        <DialogTitle>{translator.deleteUserTitle()}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {translator.deleteUsersDescription(`${selectedUsers.length}`)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton color={'primary'} onClick={() => setOpenDeleteUsers(false)}>
            {translator.cancel()}
          </CustomButton>
          <CustomButton color={'error'} variant="contained" onClick={deleteSelectedUsers} autoFocus>
            {translator.delete()}
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
  }

  const onRowClick = (userId: string) => {
    navigate(`${PATH_DASHBOARD.organization.users}/${userId}`);
  };

  return (
    <Stack>
      <Dialog onClose={() => setOpenAddUser(false)} open={openAddUser} maxWidth={'sm'} fullWidth>
        <DialogTitle>{translator.addNewUser()}</DialogTitle>
        <DialogContent>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Box pt={2} />
              <Stack spacing={2}>
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    disabled={isPermissionsLoading}
                    select
                    label={translator.selectRole()}
                    fullWidth
                    value={getFieldProps('role').value}
                    onChange={(event: any) => {
                      formik.setFieldValue('role', event.target.value);
                    }}
                    error={Boolean(getIn(errors, 'role') && getIn(touched, 'role'))}
                    helperText={getIn(touched, 'role') && getIn(errors, 'role')}
                  >
                    {assignableRoles.includes('SuperAdmin') && (
                      <MenuItem value={1}>SuperAdmin</MenuItem>
                    )}
                    {assignableRoles.includes('CompanyAdmin') && (
                      <MenuItem value={2}>Admin</MenuItem>
                    )}
                    {assignableRoles.includes('CompanyUser') && <MenuItem value={3}>User</MenuItem>}
                  </TextField>
                </FormControl>
                <TextField
                  label={translator.email()}
                  {...getFieldProps('email')}
                  error={Boolean(getIn(errors, 'email') && getIn(touched, 'email'))}
                  helperText={getIn(touched, 'email') && getIn(errors, 'email')}
                />
              </Stack>
            </Form>
          </FormikProvider>
        </DialogContent>
        <DialogActions>
          <CustomButton variant={'text'} onClick={() => setOpenAddUser(false)}>
            {translator.cancel()}
          </CustomButton>
          <CustomLoadingButton
            variant="contained"
            loading={isSubmitting}
            onClick={() => formik.submitForm()}
          >
            {translator.addUser()}
          </CustomLoadingButton>
        </DialogActions>
      </Dialog>
      <CustomDataGrid
        dataGridKey={DATA_GRID_KEY}
        getRowId={(row) => row.userId}
        autoHeight
        loading={isLoading}
        checkboxSelection
        selectionModel={selectedUsers}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectedUsers(newSelectionModel);
        }}
        disableSelectionOnClick
        onCellClick={(params) => {
          //if checkbox is clicked, return
          if (params.field === '__check__') return;

          onRowClick(params.id as string);
        }}
        rows={users.map((user) => {
          return { ...user, id: user.userId };
        })}
        columns={columns}
        pagination
        pageSize={25}
        components={{
          Toolbar: CustomGridToolbar,
          Pagination: CustomPagination,
          LoadingOverlay: CustomLoadingOverlay
        }}
      />
      <DeleteUsersDialog />
    </Stack>
  );
}
