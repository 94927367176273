import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ThrottlingGroup } from '../../../@types/throttlingGroup';
import CustomButton from 'components/util/CustomButton';
import CampaignList from 'pages/campaigns/components/CampaignList';
import { useEffect, useRef, useState } from 'react';
import { useCustomDispatchWithoutPromise } from 'redux/dispatch';
import { DataGridKey, setColumnVisibility, setFiltering } from 'redux/slices/datagrid';
import { RootState, useSelector } from 'redux/store';

const getInitialFilterModel = (throttlingGroupId?: string) => {
  return {
    items: [
      {
        columnField: 'throttlingGroupId',
        operatorValue: 'is',
        value: throttlingGroupId || 'default'
      }
    ]
  };
};

type ThrottlingGroupCampaignsDialogProps = {
  dataGridKey: DataGridKey;
  throttlingGroup?: ThrottlingGroup;
  resetThrottlingGroup: () => void;
};

const ThrottlingGroupCampaignsDialog = ({
  dataGridKey,
  throttlingGroup,
  resetThrottlingGroup
}: ThrottlingGroupCampaignsDialogProps) => {
  const customDispatchWithoutPromise = useCustomDispatchWithoutPromise();
  const { filtering } = useSelector((state: RootState) => state.datagrid);
  const [selectionModel, setSelectionModel] = useState<number[]>([]);

  const intitialColumnsVisibilityModelRef = useRef({
    id: false,
    logo: false,
    npsScore: false,
    respondentCount: false,
    responseCount: false,
    responsePercent: false,
    created: false,
    reminder: false,
    sendoutDelay: false,
    forceAnonymous: false,
    allowDuplicates: false,
    content: false,
    type: false,
    throttlingDays: true,
    throttlingGroupId: true,
    active: true
  });

  useEffect(() => {
    if (!throttlingGroup) return;

    customDispatchWithoutPromise({
      action: setFiltering,
      actionParameters: [dataGridKey, getInitialFilterModel(throttlingGroup.id)],
      disableSuccessMessage: true
    });
    customDispatchWithoutPromise({
      action: setColumnVisibility,
      actionParameters: [dataGridKey, intitialColumnsVisibilityModelRef.current],
      disableSuccessMessage: true
    });
  }, [dataGridKey, customDispatchWithoutPromise, throttlingGroup]);

  const onClose = () => {
    resetThrottlingGroup();
  };

  const currentFirstFilterValue = (filtering[dataGridKey]?.items?.[0]?.value || '') as string;
  const isOnlyShowingCampaignFromGroup =
    filtering[dataGridKey]?.items?.length === 1 &&
    (currentFirstFilterValue.toLocaleLowerCase() === throttlingGroup?.id?.toLocaleLowerCase() ||
      currentFirstFilterValue.toLocaleLowerCase() === throttlingGroup?.name?.toLocaleLowerCase()) &&
    filtering[dataGridKey]?.items?.[0]?.operatorValue === 'is';

  return (
    <Dialog open={Boolean(throttlingGroup?.name)} onClose={onClose} maxWidth="xl">
      <DialogTitle style={{ marginBottom: '1rem' }}>
        {isOnlyShowingCampaignFromGroup
          ? `Campaigns that are part of the ${throttlingGroup?.name} throttling group`
          : 'Campaigns'}
      </DialogTitle>
      <DialogContent style={{ width: '80vw' }}>
        <CampaignList
          selectionModel={selectionModel}
          setSelectionModel={setSelectionModel}
          dataGridKey={dataGridKey}
          height="70vh"
          intitialColumnsHidden={intitialColumnsVisibilityModelRef.current}
          initialFilterModel={getInitialFilterModel(throttlingGroup?.name)}
        />
      </DialogContent>
      <DialogActions>
        <CustomButton color="primary" onClick={onClose}>
          Close
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ThrottlingGroupCampaignsDialog;
