import { GridFilterModel, GridSortModel } from '@mui/x-data-grid';
import { createSlice } from '@reduxjs/toolkit';
import {
  getFilterQueryParam,
  getOrderbyQueryParam,
  getSkipQueryParam,
  getTopQueryParam
} from 'utils/getQueryParams';
import { Company, CompanyState } from '../../@types/company';
// utils
import axios from '../../utils/axios';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: CompanyState = {
  isLoading: false,
  isLoadingEditCompany: false,
  isLoadingDelete: false,
  companies: [],
  count: 0,
  companiesFromSearch: [],
  isSearchLoading: false
};

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // GET COMPANIES
    getCompaniesSuccess(state, action) {
      state.companies = action.payload;
      state.isLoading = false;
    },

    // START LOADING EDIT COMPANY
    startLoadingEditCom(state) {
      state.isLoadingEditCompany = true;
    },

    // STOP LOADING EDIT COMPANY
    stopLoadingEditCom(state) {
      state.isLoadingEditCompany = false;
    },

    setCompaniesCount(state, action) {
      state.count = action.payload;
    },

    startSearchLoading(state) {
      state.isSearchLoading = true;
    },

    stopSearhLoading(state) {
      state.isSearchLoading = false;
    },

    setCompaniesFromSearch(state, action) {
      state.companiesFromSearch = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const { setSorting, setFilter } = slice.actions;

// ----------------------------------------------------------------------

export function getCompaniesWithParams({
  sortModel,
  filterModel,
  top,
  skip
}: {
  sortModel: GridSortModel | undefined;
  filterModel: GridFilterModel | undefined;
  top: number;
  skip: number;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/odata/companies?$count=true${getSkipQueryParam(skip)}${getTopQueryParam(
          top
        )}${getOrderbyQueryParam(sortModel)}${getFilterQueryParam(filterModel)}`
      );
      dispatch(slice.actions.getCompaniesSuccess(response.data.value));
      dispatch(slice.actions.setCompaniesCount(response.data['@odata.count']));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Companies fetched'
      });
    } catch (error: any) {
      return Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch companies'
      });
    }
  };
}

export function getCompanies() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/companies');
      dispatch(slice.actions.getCompaniesSuccess(response.data));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Companies fetched'
      });
    } catch (error: any) {
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch companies'
      });
    }
  };
}

export function editCompany({ updatedCompany }: { updatedCompany: Company }) {
  return async () => {
    dispatch(slice.actions.startLoadingEditCom());
    try {
      await axios.put(`/companies/${updatedCompany.id}`, updatedCompany);
      dispatch(slice.actions.stopLoadingEditCom());
      return await Promise.resolve({
        defaultSuccessMessage: 'Company edited'
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoadingEditCom());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not edit company'
      });
    }
  };
}

export function deleteCompanies(ids: number[]) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      for (let id of ids) {
        await axios.delete('/companies/' + id);
      }
      dispatch(slice.actions.stopLoading());
      return await Promise.resolve({
        defaultSuccessMessage: `Deleted ${ids.length} compan${ids.length ? 'ies' : 'y'}`
      });
    } catch (error: any) {
      dispatch(slice.actions.stopLoading());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not delete compan${ids.length ? 'ies' : 'y'}`
      });
    }
  };
}

export function getCompaniesFromSearch({
  top = 6,
  searchString
}: {
  top?: number;
  searchString: string;
}) {
  return async () => {
    if (!searchString?.length) {
      dispatch(slice.actions.setCompaniesFromSearch([]));
      return Promise.resolve({
        defaultSuccessMessage: 'Companies fetched'
      });
    }

    dispatch(slice.actions.startSearchLoading());

    const individualSearchString = searchString.split(' ');
    let filterQuery = '';
    individualSearchString.forEach((string) => {
      filterQuery += `${filterQuery.length ? ' and ' : ''}(contains(name,'${string}'))`;
    });
    try {
      const response = await axios.get(`/odata/companies?$top=${top}&$filter=${filterQuery}`);
      dispatch(slice.actions.setCompaniesFromSearch(response.data.value));

      dispatch(slice.actions.stopSearhLoading());

      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Companies fetched'
      });
    } catch (error) {
      dispatch(slice.actions.stopSearhLoading());

      return await Promise.reject({
        error: error,
        defaultErrorMessage: 'Could not fetch Companies'
      });
    }
  };
}
