import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from 'redux/store';
import { PermissionsState } from '../../@types/permissions';

// ----------------------------------------------------------------------
const initialState: PermissionsState = {
  isLoading: false,
  assignableRoles: []
};

const slice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    getPermissionsSuccess(state, action) {
      const { assignableRoles } = action.payload;
      state.isLoading = false;
      state.assignableRoles = assignableRoles as string[];
    },
    getPermissionsError(state) {
      state.isLoading = false;
      state.assignableRoles = [];
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export const getPermissions = () => {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/user/permissions`);
      dispatch(slice.actions.getPermissionsSuccess(response.data));
      return await Promise.resolve({
        result: response,
        defaultSuccessMessage: 'Fetched user permission'
      });
    } catch (error: any) {
      dispatch(slice.actions.getPermissionsError());
      return await Promise.reject({
        error: error,
        defaultErrorMessage: `Could not fetch user permissions`
      });
    }
  };
};
