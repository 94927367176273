import { createSlice } from '@reduxjs/toolkit';
import {
  FloatingActionButtonType,
  FloatingActionButtonsState
} from '../../@types/floatingActionButtons';

// ----------------------------------------------------------------------

const initialState: FloatingActionButtonsState = {
  display: {
    [FloatingActionButtonType.DOCUMENTATION]: true,
    [FloatingActionButtonType.CAMPAIGN_BUILDER_MERGE_FIELDS]: false
  }
};

const slice = createSlice({
  name: 'floatingActionButtons',
  initialState,
  reducers: {
    setDisplayActionButton(
      state,
      action: { payload: { type: FloatingActionButtonType; display: boolean } }
    ) {
      state.display[action.payload.type] = action.payload.display;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setDisplayActionButton } = slice.actions;
