import {
  Box,
  Card,
  Container,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { filter } from 'lodash';
import { useState } from 'react';
import { Organisation } from '../../../@types/organization';
import useAuth from '../../../hooks/useAuth';
import useSettings from '../../../hooks/useSettings';
import Scrollbar from '../../../components/util/Scrollbar';
import SearchNotFound from '../../../components/util/SearchNotFound';
import OrganizationListHead from 'pages/organizations/components/OrganizationListHead';
import OrganizationListToolbar from 'pages/organizations/components/OrganizationListToolbar';

const TABLE_HEAD = [
  { id: '' },
  { id: 'organizationName', label: 'Organisation Name', alignRight: false },
  { id: 'displayName', label: 'Display Name', alignRight: false }
];

const ThumbImgStyle = styled('img')(({ theme }) => ({
  maxWidth: 64,
  maxHeight: 64,
  objectFit: 'cover',
  margin: theme.spacing(0, 2),
  borderRadius: theme.shape.borderRadiusSm
}));

// ----------------------------------------------------------------------

function descendingComparator(a: Anonymous, b: Anonymous, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Anonymous = Record<string | number, string>;

function getComparator(order: string, orderBy: string) {
  return order === 'desc'
    ? (a: Anonymous, b: Anonymous) => descendingComparator(a, b, orderBy)
    : (a: Anonymous, b: Anonymous) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(
  array: Organisation[],
  comparator: (a: any, b: any) => number,
  query: string
) {
  const stabilizedThis = array.map((el, index) => [el, index] as const);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(
      array,
      (_org) =>
        (_org.organizationName?.length >= 1 &&
          _org.organizationName?.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
        (_org.displayName !== undefined &&
          _org.displayName !== null &&
          _org.displayName?.length >= 1 &&
          _org.displayName?.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    );
  }

  return stabilizedThis.map((el) => el[0]);
}

// ----------------------------------------------------------------------

type OrganisationSelectCardsProps = {
  organisations: Organisation[];
};

export default function OrganisationTableSelect({ organisations }: OrganisationSelectCardsProps) {
  const { themeStretch } = useSettings();
  const { login } = useAuth();

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [selected] = useState<string[]>([]);
  const [filterName, setFilterName] = useState('');
  const [orderBy, setOrderBy] = useState('organizationName');
  const [loading, setLoading] = useState(false);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = async (id: string) => {
    setLoading(true);

    await login('', '', id);

    setLoading(false);
  };

  const handleFilterByName = (filterName: string) => {
    setFilterName(filterName);
  };

  const filteredOrgs = applySortFilter(organisations, getComparator(order, orderBy), filterName);

  const isOrgNotFound = filteredOrgs.length === 0;

  return (
    <Container maxWidth={themeStretch ? false : 'xl'} style={{ paddingInline: 'unset' }}>
      {loading && <LinearProgress />}
      <Card>
        <OrganizationListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          placeholder={'Search organisation'}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 400 }}>
            <Table>
              <OrganizationListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={organisations.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                //onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredOrgs.map((row) => {
                  const { organizationId, organizationName, logoLink, displayName } = row;

                  const isItemSelected = selected.indexOf(organizationName) !== -1;

                  return (
                    <TableRow
                      hover
                      key={organizationId}
                      tabIndex={-1}
                      role="select"
                      selected={isItemSelected}
                      onClick={() => handleClick(organizationId)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell width={35} component="th" scope="row" padding="none">
                        <Box
                          sx={{
                            height: 64,
                            py: 2,
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          {logoLink && <ThumbImgStyle alt={organizationName} src={logoLink} />}
                        </Box>
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        <Box
                          sx={{
                            height: 64,
                            py: 2,
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <Typography variant="subtitle2" noWrap>
                            {organizationName}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell component="td" scope="row" padding="none">
                        <Typography variant="subtitle2" noWrap>
                          {displayName}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              {isOrgNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6}>
                      <Box sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </Container>
  );
}
