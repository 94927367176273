import { Claim } from './organization';

export const CLAIMS = {
  applications: {
    basic: 'applications_basic',
    outlook: 'applications_outlook'
  },
  features: {
    pgap: 'features_pgap',
    ivr: 'features_ivr',
    review: 'features_review'
  },
  management: {
    superadmin: 'management_superadmin',
    etrack1: 'management_etrack1',
    zapier: 'management_zapier',
    surveyJs: 'management_surveyjs'
  },
  actions: {
    mergeEmployees: 'actions_mergeEmployees', //do these work, as the getClaimValue sets them to lowercase? Should they be lowercase in the first place?
    mergeRespondents: 'actions_mergeRespondents', //do these work, as the getClaimValue sets them to lowercase? Should they be lowercase in the first place?
    editAnonymize: 'actions_edit_anonymize'
  },
  beta: 'beta'
};

export const LICENSE_CLAIM_TYPES = ['Applications'];
export const MANAGEMENT_CLAIM_TYPES = ['features', 'management'];
export const UNTOGGLEABLE_CLAIM_VALUES = ['superadmin', 'beta'];
type GetClaimsByTypeProps = {
  claimType: string;
  onlyIncludeToggleable?: boolean;
};
export const getClaimsByType = ({
  claimType,
  onlyIncludeToggleable
}: GetClaimsByTypeProps): Claim[] => {
  const newClaimsArray = [];

  for (const [currentClaimType, claimValues] of Object.entries(CLAIMS)) {
    if (currentClaimType === claimType.toLowerCase() && typeof claimValues !== 'string') {
      if (
        typeof claimValues === 'string' &&
        (!onlyIncludeToggleable || !UNTOGGLEABLE_CLAIM_VALUES.includes(claimValues))
      ) {
        newClaimsArray.push({ claimType, claimValue: claimValues });
      } else {
        for (const claimValue of Object.keys(claimValues)) {
          if (!onlyIncludeToggleable || !UNTOGGLEABLE_CLAIM_VALUES.includes(claimValue)) {
            newClaimsArray.push({ claimType, claimValue });
          }
        }
      }
    }
  }
  return newClaimsArray;
};

export enum Access {
  PAGE_PGAP = 'page_pgap',
  PAGE_REVIEWS = 'page_reviews',
  PAGE_ORDERS = 'page_orders',
  PAGE_INTEGRATION_STORE = 'page_integration_store',
  PAGE_IVR = 'page_ivr',
  PAGE_OUTLOOK = 'page_outlook',
  PAGE_ETRACK1 = 'page_etrack1',
  PAGE_ZAPIER = 'page_zapier',
  PAGE_SUPER_ADMIN = 'page_super_admin',
  PAGE_SENDERS = 'page_senders',

  ACTION_MERGE_EMPLOYEES = 'action_merge_employees',
  ACTION_MERGE_RESPONDENTS = 'action_merge_respondents',

  ACTION_DELETE_RESPONSE = 'action_delete_response',

  ACTION_ADD_SUBSCRIPTION = 'action_add_subscription',

  ACTION_EDIT_CAMPAIGN_SURVEY_JS = 'action_edit_campaign_survey_js',
  ACTION_EDIT_SUBSCRIPTION = 'action_edit_subscription',
  ACTION_EDIT_USER_CLAIMS = 'action_edit_user_claims',
  ACTION_EDIT_PROVIDERS = 'action_edit_providers',
  ACTION_EDIT_ANONYMIZE_AFTER_DAYS = 'action_edit_anonymize_after_days',

  ACTION_TOGGLE_INTEGRATIONS = 'action_toggle_integrations'
}

export const accessMap = new Map([
  //PAGE ACCESS
  [Access.PAGE_PGAP, [CLAIMS.features.pgap]],
  [Access.PAGE_REVIEWS, [CLAIMS.features.review]],
  [Access.PAGE_ORDERS, [CLAIMS.beta]],
  [Access.PAGE_INTEGRATION_STORE, [CLAIMS.beta]],
  [Access.PAGE_IVR, [CLAIMS.features.ivr]],
  [Access.PAGE_OUTLOOK, [CLAIMS.applications.outlook]],
  [Access.PAGE_ETRACK1, [CLAIMS.management.etrack1]],
  [Access.PAGE_ZAPIER, [CLAIMS.beta, CLAIMS.management.zapier]],
  [Access.PAGE_SUPER_ADMIN, [CLAIMS.management.superadmin]],
  [Access.PAGE_SENDERS, [CLAIMS.management.superadmin]],

  //MERGE
  [Access.ACTION_MERGE_EMPLOYEES, [CLAIMS.actions.mergeEmployees, CLAIMS.management.superadmin]],
  [
    Access.ACTION_MERGE_RESPONDENTS,
    [CLAIMS.actions.mergeRespondents, CLAIMS.management.superadmin]
  ],

  //DELETE
  [Access.ACTION_DELETE_RESPONSE, [CLAIMS.management.superadmin]],

  //ADD
  [Access.ACTION_ADD_SUBSCRIPTION, [CLAIMS.management.superadmin]],

  //EDIT
  [Access.ACTION_EDIT_CAMPAIGN_SURVEY_JS, [CLAIMS.beta, CLAIMS.management.surveyJs]],
  [Access.ACTION_EDIT_SUBSCRIPTION, [CLAIMS.management.superadmin]],
  [Access.ACTION_EDIT_USER_CLAIMS, [CLAIMS.management.superadmin]],
  [Access.ACTION_EDIT_PROVIDERS, [CLAIMS.management.superadmin]],
  [
    Access.ACTION_EDIT_ANONYMIZE_AFTER_DAYS,
    [CLAIMS.management.superadmin, CLAIMS.actions.editAnonymize]
  ],

  //OTHER
  [Access.ACTION_TOGGLE_INTEGRATIONS, [CLAIMS.beta]]
]);
