import {
  Autocomplete,
  ListItemText,
  MenuItem,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import useAuth from 'hooks/useAuth';
import { RootState, useSelector } from 'redux/store';
import { useEffect, useRef, useState } from 'react';
import { Organisation } from '../../@types/organization';
import { Paper } from '@mui/material';
import { List } from '@mui/material';

const CurrentOrg = () => {
  const theme = useTheme();
  const { login, selectedOrg, setSelectedOrg } = useAuth();
  const { organizationProfile, organizations } = useSelector(
    (state: RootState) => state.organization
  );
  const { hasUnsavedChanges } = useSelector((state: RootState) => state.editing);

  useEffect(() => {
    //if there is an organization feched, set it as selected
    if (organizationProfile?.id) {
      setSelectedOrg({
        ...organizationProfile,
        organizationId: organizationProfile?.id
      } as Organisation);
    }
  }, [organizationProfile, setSelectedOrg]);

  const isCurrentOrgFetched = organizations?.find((organization) => {
    return organization.organizationId === selectedOrg?.organizationId;
  });

  //ensure that the current selected org is in the autocomplete options
  const autocompleteOptions = isCurrentOrgFetched
    ? organizations
    : selectedOrg
    ? [selectedOrg]
    : [];

  const [inputWidth, setInputWidth] = useState<number | null>(null);
  const hiddenDivRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (hiddenDivRef.current) {
      setInputWidth(hiddenDivRef.current.offsetWidth);
    }
  }, [selectedOrg]);

  return (
    <>
      {selectedOrg ? (
        organizations?.length > 1 ? (
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Tooltip
              title={
                hasUnsavedChanges
                  ? 'To switch organization, please save or discard any unsaved changes first.'
                  : ''
              }
            >
              <Autocomplete
                disabled={hasUnsavedChanges}
                PaperComponent={StyledAutocompletePaper}
                ListboxComponent={StyledListbox}
                sx={{ width: `calc(${inputWidth || 80}px + 3rem)` }} // 3rem is for the width of the down arrow
                disableClearable
                value={selectedOrg}
                onChange={(_, val) => {
                  const selectedOrgId = val?.organizationId as string;
                  const selectedOrgObject = organizations?.find(
                    (organization) => organization.organizationId === selectedOrgId
                  );
                  setSelectedOrg({
                    organizationId: selectedOrgId,
                    displayName: selectedOrgObject?.displayName ?? '',
                    organizationName: selectedOrgObject?.organizationName ?? '',
                    active: selectedOrgObject?.active ?? true
                  });

                  login('', '', selectedOrgId, undefined, true);
                }}
                autoHighlight
                filterOptions={(options, state) => {
                  return options.filter((option) => {
                    return (
                      option.displayName?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                      option.organizationName
                        ?.toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                    );
                  });
                }}
                getOptionLabel={(option) => option.displayName ?? option.organizationName}
                isOptionEqualToValue={(option, value) =>
                  option.organizationId === value.organizationId
                }
                options={autocompleteOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    fullWidth
                    placeholder="Search for organization"
                  />
                )}
                renderOption={(props, option) => (
                  <MenuItem
                    {...props}
                    key={option.organizationId}
                    style={{
                      maxWidth: '80vw',
                      width: '20rem',
                      whiteSpace: 'normal'
                    }}
                  >
                    <ListItemText
                      primary={option.displayName ?? '-'}
                      secondary={option.organizationName ?? '-'}
                    />
                  </MenuItem>
                )}
              />
            </Tooltip>
          </div>
        ) : (
          <>
            {/* if there is only one org, just display the name */}
            <Typography color={theme.palette.text.primary} variant="body1" noWrap>
              {selectedOrg.displayName ?? selectedOrg.organizationName}
            </Typography>
          </>
        )
      ) : (
        <Typography color={theme.palette.text.primary} variant="body1" noWrap>
          -
        </Typography>
      )}
      {/*
       * This div is used to calculate the width of the selected org name, so that the autocomplete width can be set accordingly
       */}
      {selectedOrg && (
        <div
          ref={hiddenDivRef}
          style={{
            position: 'absolute',
            visibility: 'hidden',
            height: 'auto',
            width: 'auto',
            whiteSpace: 'nowrap'
          }}
        >
          {selectedOrg.displayName ?? selectedOrg.organizationName}
        </div>
      )}
    </>
  );
};

export default CurrentOrg;

//as the dropdown width is not dynamic, and the anchor point is top right, the placement needs to be adjusted, to avoid overflow
const StyledAutocompletePaper = styled(Paper)(({ theme }) => ({
  width: 'fit-content',
  position: 'absolute',
  top: 0,
  right: 0
}));

const StyledListbox = styled(List)(({ theme }) => ({
  display: 'block !important',
  maxHeight: '60vh !important',
  position: 'relative',
  pointerEvents: 'auto',
  '& li': {
    display: 'block !important'
  }
}));
