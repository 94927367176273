import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  LinearProgress,
  Divider
} from '@mui/material';
import { FormikState } from 'formik';
import { ReactNode, useState } from 'react';
import UnsavedChangesModal from './UnsavedChangesModal';
import CustomButton, { CustomLoadingButton } from './CustomButton';
import Icon from 'components/icons/Icon';
import AreYouSureModal from './AreYouSureModal';

type EditItemDialogProps = {
  itemType: string;
  isLoading?: boolean;
  editable: boolean;
  isOpen: boolean;
  title: string;
  displayContent?: JSX.Element[];
  editContent: JSX.Element[];
  dirty: boolean;
  isSubmitting: boolean;
  delete?: () => void;
  isDeleteLoading?: boolean;
  close: () => void;
  resetForm: (nextState?: Partial<FormikState<any>> | undefined) => void;
  submitForm: () => Promise<any>;
  onForward?: () => void;
  toggleEditable?: () => void;
  extraMenuButtons?: JSX.Element[];
};

const EditItemDialog = (props: EditItemDialogProps) => {
  const [displayAreYouSureModal, setDisplayAreYouSureModal] = useState(false);
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] = useState(false);

  const onClose = () => {
    if (props.dirty) {
      setIsUnsavedChangesModalOpen(true);
      return;
    }
    props.close();
  };

  const getMdGridValue = (index: number, contentArrayLength: number, node: ReactNode) => {
    //if the item is a divider, span 100% width
    if (node && (node as any).type === Divider) {
      return 12;
    }
    //if the last item is on a line by itself, span 100% width
    return index >= contentArrayLength - 1 && index % 2 === 0 ? 12 : 6;
  };

  const onToggleEditable = () => {
    if (!props.toggleEditable) return;
    if (props.dirty) {
      setIsUnsavedChangesModalOpen(true);
      return;
    }
    props.toggleEditable();
  };

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <Dialog maxWidth={'lg'} open={props.isOpen} onClose={onClose}>
        <Stack direction="row" justifyContent="space-between">
          <DialogTitle>{props.title}</DialogTitle>
          <DialogActions>
            {props.extraMenuButtons?.map((extraMenuBotton) => extraMenuBotton)}
            {props.onForward && (
              <IconButton color="primary" onClick={props.onForward}>
                <Icon type="forwardemail" />
              </IconButton>
            )}
            {props.toggleEditable && (
              <IconButton color={props.editable ? 'primary' : 'default'} onClick={onToggleEditable}>
                <Icon type="edit" />
              </IconButton>
            )}
          </DialogActions>
        </Stack>

        <DialogContent>
          {props.isLoading && <LinearProgress sx={{ marginBottom: '1rem' }} />}
          <Grid container spacing={3} sx={{ width: '70vw', maxWidth: '40rem' }}>
            {props.displayContent && (
              <>
                {props.displayContent?.map((item, index) => (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    md={getMdGridValue(index, props.displayContent?.length ?? 0, item)}
                  >
                    {item}
                  </Grid>
                ))}
              </>
            )}
            {props.editContent?.map((item, index) => (
              <Grid
                key={index}
                item
                xs={12}
                md={getMdGridValue(index, props.editContent.length, item)}
              >
                {item}
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <Stack direction="row" justifyContent="space-between">
          <DialogActions>
            {props.delete && (
              <CustomLoadingButton
                color="error"
                variant={'text'}
                onClick={() => setDisplayAreYouSureModal(true)}
                disabled={props.isLoading || props.isDeleteLoading}
                loading={props.isDeleteLoading}
              >
                Delete
              </CustomLoadingButton>
            )}
          </DialogActions>

          <DialogActions>
            {((props.toggleEditable || props.editable) && (
              <>
                <CustomButton variant={'text'} onClick={onClose}>
                  Cancel
                </CustomButton>
                <CustomLoadingButton
                  disabled={!props.dirty || props.isLoading || props.isDeleteLoading}
                  onClick={() => {
                    props.submitForm();
                  }}
                  type="submit"
                  variant="contained"
                  loading={props.isSubmitting || props.isLoading}
                >
                  Save
                </CustomLoadingButton>
              </>
            )) || (
              <CustomButton variant={'text'} onClick={onClose}>
                Close
              </CustomButton>
            )}
          </DialogActions>
        </Stack>
      </Dialog>

      <UnsavedChangesModal
        isOpen={isUnsavedChangesModalOpen}
        onCancel={() => {
          setIsUnsavedChangesModalOpen(false);
        }}
        onDisregard={() => {
          props.resetForm();
          setIsUnsavedChangesModalOpen(false);
          props.close();
        }}
        onSave={() => {
          setIsUnsavedChangesModalOpen(false);
          props.submitForm();
        }}
      />

      {props.delete && (
        <AreYouSureModal
          type="Delete"
          display={displayAreYouSureModal}
          hideModal={() => setDisplayAreYouSureModal(false)}
          title={`Delete ${props.itemType}`}
          description={`Do you want to delete the ${props.itemType}?`}
          onContinue={() => {
            props.delete?.();
            props.close();
          }}
        />
      )}
    </div>
  );
};

export default EditItemDialog;
