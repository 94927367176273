import { Backdrop } from '@mui/material';
import LoadingScreen from './LoadingScreen';

const AwaitLoading = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <div>
      <Backdrop open={isLoading} style={{ zIndex: 9999999, height: '100vh', width: '100vw' }}>
        <LoadingScreen />
      </Backdrop>
    </div>
  );
};

export default AwaitLoading;
