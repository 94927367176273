import { TabContext, TabPanel } from '@mui/lab';
import { Box, Card, Tab, Tabs } from '@mui/material';
import { capitalCase } from 'change-case';
import CustomButton from 'components/util/CustomButton';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/util/Page';
import { PATH_DASHBOARD } from '../../routes/paths';
import CampaignList from './components/CampaignList';
import { TemplateList } from 'pages/templates/components/TemplateList';
import LogosList from 'pages/logos/components/LogosList';
import AddLogoDialog from 'pages/logos/components/AddLogoDialog';
import ThrottlingGroupsList from 'pages/throttlingGroups/components/ThrottlingGroupsList';
import AddThrottlingGroupDialog from 'pages/throttlingGroups/components/AddThrottlingGroupDialog';
import Icon from 'components/icons/Icon';
import NpsNotifications from 'pages/npsNotifications/NpsNotifications';
import { deleteTemplates } from 'redux/slices/campaign';
import useCustomDispatch from 'redux/dispatch';
import {
  getLanguages,
  getLogos,
  getOrganizationProfile,
  getTemplates
} from 'redux/slices/organization';
import AreYouSureModal from 'components/util/AreYouSureModal';
import { useTranslator } from 'translation/useTranslator';

export enum CampaignsTab {
  ACTIVE_CAMPAIGNS = 'active_campaigns',
  CLOSED_CAMPAIGNS = 'closed_campaigns',
  TEMPLATES = 'templates',
  LOGOS = 'logos',
  THROTTLING_GROUPS = 'throttling_groups',
  NPS_NOTIFICATIONS = 'nps_notifications'
}

const activeCampaignsEncforcedFilterItem = {
  columnField: 'stopTime',
  operatorValue: 'isEmpty'
};

const closedCampaignsEncforcedFilterItem = {
  columnField: 'stopTime',
  operatorValue: 'isNotEmpty'
};

export default function Campaigns() {
  const translator = useTranslator();
  const { tab = CampaignsTab.ACTIVE_CAMPAIGNS } = useParams();
  const navigate = useNavigate();
  const customDispatch = useCustomDispatch();

  const [selectionModel, setSelectionModel] = useState<number[]>([]);
  const [displayAddLogoDialog, setDisplayAddLogoDialog] = useState(false);
  const [displayDeleteTemplatesDialog, setDisplayDeleteTemplatesDialog] = useState(false);
  const [selectedTemplateIds, setSelectedTemplateIds] = useState<number[]>([]);
  const [displayAddThrottlingGroupDialog, setDisplayAddThrottlingGroupDialog] = useState(false);
  const [templateHeaderContent, setTemplateHeaderContent] = useState([
    [
      <CustomButton
        key="addTemplate"
        variant={'contained'}
        onClick={() => navigate(PATH_DASHBOARD.templates.newTemplate)}
      >
        {translator.addTemplate()}
      </CustomButton>
    ]
  ]);

  const onTabChange = (newTab: CampaignsTab) => {
    navigate(`${PATH_DASHBOARD.campaigns.root}/tabs/${newTab}`);
  };

  const AddCampaignButton = useCallback(
    () => (
      <CustomButton
        variant={'contained'}
        onClick={() => navigate(PATH_DASHBOARD.campaigns.newCampaign)}
      >
        {translator.addCampaign()}
      </CustomButton>
    ),
    [navigate, translator]
  );

  const deleteSelectedTemplates = (ids: number[]) => {
    customDispatch({
      action: deleteTemplates,
      actionParameters: {ids},
      onSuccess: () => {
        customDispatch({ action: getTemplates, disableSuccessMessage: true });
        customDispatch({ action: getLogos, disableSuccessMessage: true });
        customDispatch({ action: getOrganizationProfile, disableSuccessMessage: true });
        customDispatch({ action: getLanguages, disableSuccessMessage: true });
      }
    });
  };

  const onTemplateSelectionChange = useCallback(
    (ids: number[]) => {
      setSelectedTemplateIds(ids);
      if (ids.length > 0) {
        setTemplateHeaderContent([
          [
            <CustomButton
              key="addTemplate"
              variant={'contained'}
              onClick={() => navigate(PATH_DASHBOARD.templates.newTemplate)}
            >
              {translator.addTemplate()}
            </CustomButton>,
            <CustomButton
              key="deleteTemplate"
              variant={'outlined'}
              color="error"
              onClick={() => setDisplayDeleteTemplatesDialog(true)}
            >
              {translator.deleteTemplates(`${ids.length}`)}
            </CustomButton>
          ]
        ]);
        return;
      }
      setTemplateHeaderContent([
        [
          <CustomButton
            key="addTemplate"
            variant={'contained'}
            onClick={() => navigate(PATH_DASHBOARD.templates.newTemplate)}
          >
            {translator.addTemplate()}
          </CustomButton>
        ]
      ]);
    },
    [navigate, translator]
  );

  const TABS = useMemo(() => {
    return [
      {
        value: CampaignsTab.ACTIVE_CAMPAIGNS,
        icon: <Icon type="campaignactive" />,
        component: (
          <CampaignList
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
            enforcedFilterItem={activeCampaignsEncforcedFilterItem}
          />
        ),
        headerContent: [[<AddCampaignButton key="addCampaign" />]]
      },
      {
        value: CampaignsTab.CLOSED_CAMPAIGNS,
        icon: <Icon type="campaignclosed" />,
        component: (
          <CampaignList
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
            enforcedFilterItem={closedCampaignsEncforcedFilterItem}
          />
        ),
        headerContent: [[<AddCampaignButton key="addCampaign" />]]
      },
      {
        value: CampaignsTab.TEMPLATES,
        icon: <Icon type="template" />,
        component: <TemplateList onSelectionChange={onTemplateSelectionChange} />,
        headerContent: templateHeaderContent
      },

      {
        value: CampaignsTab.LOGOS,
        icon: <Icon type="logo" />,
        component: <LogosList />,
        headerContent: [
          [
            <CustomButton
              key="uploadNewLogo"
              variant={'contained'}
              onClick={() => setDisplayAddLogoDialog(true)}
            >
              {translator.uploadNewLogo()}
            </CustomButton>
          ]
        ]
      },
      {
        value: CampaignsTab.THROTTLING_GROUPS,
        icon: <Icon type="throttlinggroup" />,
        component: <ThrottlingGroupsList />,
        headerContent: [
          [
            <CustomButton
              key="addThrottlingGroup"
              variant={'contained'}
              onClick={() => setDisplayAddThrottlingGroupDialog(true)}
            >
              {translator.addThrottlingGroup()}
            </CustomButton>
          ]
        ]
      },
      {
        value: CampaignsTab.NPS_NOTIFICATIONS,
        title: 'NPS Notifications',
        icon: <Icon type="npsnotification" />,
        component: <NpsNotifications />,
        headerContent: [
          [
            <CustomButton
              key="adNpsNotification"
              variant={'contained'}
              onClick={() => navigate(PATH_DASHBOARD.npsNotifications.new)}
            >
              {translator.addNpsNotification()}
            </CustomButton>
          ]
        ]
      }
    ];
  }, [
    selectionModel,
    AddCampaignButton,
    onTemplateSelectionChange,
    templateHeaderContent,
    translator,
    navigate
  ]);

  const selectedTab = TABS.find((aTab) => aTab.value === tab);

  return (
    <>
      <Page title={translator.campaigns()} stickyHeaderContent={selectedTab?.headerContent}>
        <Card sx={{ p: 3 }}>
          <TabContext value={tab}>
            <Tabs
              value={tab}
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
              onChange={(_, value) => onTabChange(value as CampaignsTab)}
            >
              {TABS.map((tab, index) => {
                return (
                  <Tab
                    disableRipple
                    key={index}
                    label={tab.title || capitalCase(tab.value)}
                    value={tab.value}
                    icon={tab.icon}
                  />
                );
              })}
            </Tabs>
            <Box sx={{ mb: 3 }} />
            {TABS.map((tab, index) => (
              <TabPanel key={index} value={tab.value}>
                {tab.component}
              </TabPanel>
            ))}
          </TabContext>
        </Card>
      </Page>
      <AddLogoDialog
        display={displayAddLogoDialog}
        hideModal={() => setDisplayAddLogoDialog(false)}
      />
      <AddThrottlingGroupDialog
        isOpen={displayAddThrottlingGroupDialog}
        close={() => setDisplayAddThrottlingGroupDialog(false)}
        fetchThrottlingGroupsOnSuccess
      />
      <AreYouSureModal
        description={translator.deleteTemplatesDescription()}
        display={displayDeleteTemplatesDialog}
        hideModal={() => setDisplayDeleteTemplatesDialog(false)}
        onContinue={() => deleteSelectedTemplates(selectedTemplateIds)}
        title={translator.deleteTemplates(`${selectedTemplateIds.length}`)}
        type="Delete"
      />
    </>
  );
}
