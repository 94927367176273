import { IconType } from '../../@types/icons';
import Icons from 'assets/icons';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { forwardRef } from 'react';

type IconProps = {
  type: IconType | keyof typeof IconType;
  isText?: boolean;
  fill?: string;
} & SvgIconProps;

const Icon = forwardRef<SVGSVGElement | null, IconProps>(
  ({ type, isText = false, fill, ...rest }, ref) => {
    const CurrentIcon = Icons[type];
    return (
      <SvgIcon
        {...rest}
        ref={ref}
        style={{ fontSize: isText ? 'inherit' : 'inital', ...rest.style }}
      >
        {fill && <CurrentIcon fill={fill} />}
        <CurrentIcon />
      </SvgIcon>
    );
  }
);

export default Icon;
