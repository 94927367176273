import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from 'redux/store';
import { ProfileState } from '../../@types/profile';

// ----------------------------------------------------------------------
const initialState: ProfileState = {
  isLoading: false,
  error: false,
  profiles: []
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    setProfiles(state, action) {
      state.profiles = action.payload;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
//export const {} = slice.actions;

// ----------------------------------------------------------------------

export const getProfiles = () => {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/profile/children');
      dispatch(slice.actions.setProfiles(response.data));
    } catch (error: any) {
      dispatch(slice.actions.stopLoading());
      throw new Error(error);
    }
  };
};
